import React, { CSSProperties, memo } from "react";

interface IIconPalette {
  style?: CSSProperties;
}

const IconPalette = ({ style }: IIconPalette) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M8.89007 15.4028C8.34187 15.4698 7.95181 15.9686 8.01885 16.5168C8.0859 17.065 8.58465 17.455 9.13285 17.388C9.68105 17.3209 10.0711 16.8222 10.0041 16.274C9.93702 15.7258 9.43827 15.3357 8.89007 15.4028Z"
        fill="white"
      />
      <path
        d="M5.54809 12.7891C5.48105 12.2409 5.8711 11.7422 6.4193 11.6751C6.9675 11.6081 7.46626 11.9982 7.5333 12.5464C7.60034 13.0946 7.21029 13.5933 6.66208 13.6603C6.11388 13.7274 5.61513 13.3373 5.54809 12.7891Z"
        fill="white"
      />
      <path
        d="M7.91894 7.46194C7.37073 7.52899 6.98068 8.02774 7.04772 8.57594C7.11476 9.12414 7.61352 9.51419 8.16172 9.44715C8.70992 9.38011 9.09997 8.88136 9.03293 8.33316C8.96589 7.78496 8.46714 7.3949 7.91894 7.46194Z"
        fill="white"
      />
      <path
        d="M15.6973 9.28117C15.6302 8.73297 16.0203 8.23422 16.5685 8.16717C17.1167 8.10013 17.6155 8.49019 17.6825 9.03839C17.7495 9.58659 17.3595 10.0853 16.8113 10.1524C16.2631 10.2194 15.7643 9.82937 15.6973 9.28117Z"
        fill="white"
      />
      <path
        d="M11.9926 10.8787C11.4444 10.9457 11.0543 11.4445 11.1214 11.9927C11.1884 12.5409 11.6872 12.9309 12.2354 12.8639C12.7836 12.7969 13.1736 12.2981 13.1066 11.7499C13.0396 11.2017 12.5408 10.8116 11.9926 10.8787Z"
        fill="white"
      />
      <path
        d="M11.3931 7.03706C11.326 6.48886 11.7161 5.99011 12.2643 5.92306C12.8125 5.85602 13.3112 6.24608 13.3783 6.79428C13.4453 7.34248 13.0553 7.84123 12.5071 7.90827C11.9589 7.97532 11.4601 7.58526 11.3931 7.03706Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25288 11.7638C2.38338 6.38058 6.85311 2.12243 12.2363 2.25292C17.6195 2.38342 21.8777 6.85315 21.7472 12.2363C21.7389 12.5773 21.6243 12.8887 21.4132 13.1452C21.2112 13.3905 20.9498 13.5513 20.6918 13.659C20.1898 13.8685 19.5606 13.9343 18.9379 13.9385C16.2492 13.9567 13.9139 16.2693 13.7612 18.9968C13.7256 19.6331 13.6171 20.2804 13.3433 20.7889C13.2015 21.0523 13.0023 21.3028 12.7233 21.4842C12.4377 21.6699 12.1102 21.7556 11.7637 21.7472C6.38054 21.6167 2.12239 17.147 2.25288 11.7638ZM12.2 3.75248C7.64494 3.64206 3.86286 7.24511 3.75244 11.8001C3.64202 16.3551 7.24508 20.1372 11.8001 20.2476C11.8682 20.2493 11.8934 20.2346 11.9057 20.2266C11.9247 20.2142 11.9688 20.1777 12.0226 20.0778C12.1399 19.8599 12.2321 19.4758 12.2636 18.913C12.458 15.4381 15.4045 12.4623 18.9277 12.4385C19.4898 12.4347 19.8843 12.3707 20.114 12.2747C20.1951 12.2409 20.2331 12.2122 20.2476 12.1992C20.3576 7.64455 16.7547 3.86289 12.2 3.75248Z"
        fill="white"
      />
    </svg>
  );
};

export default memo(IconPalette);
