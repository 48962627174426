import React, { memo } from "react";

interface IIconRecruit {
  fill?: string;
}

const IconRecruit = ({fill}: IIconRecruit) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 4.28363C7.04513 4.66255 3.75 8.19647 3.75 12.5C3.75 17.0563 7.44365 20.75 12 20.75C14.3214 20.75 16.4185 19.7919 17.9185 18.2476L11.5286 13.0833C11.3524 12.9409 11.25 12.7265 11.25 12.5V4.28363ZM12.75 4.28363V11.75H20.2164C19.8597 7.79215 16.7079 4.64029 12.75 4.28363ZM20.2164 13.25H14.1212L18.8621 17.0815C19.6082 15.9664 20.0895 14.6597 20.2164 13.25ZM2.25 12.5C2.25 7.11522 6.61522 2.75 12 2.75C17.3848 2.75 21.75 7.11522 21.75 12.5C21.75 14.8212 20.9379 16.9548 19.583 18.6291C17.7968 20.8365 15.0632 22.25 12 22.25C6.61522 22.25 2.25 17.8848 2.25 12.5Z"
        fill={fill}
      />
    </svg>
  );
};

export default memo(IconRecruit);
