import React, { memo, useCallback, useMemo, useState } from "react";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import moment from "moment";
import IconCalendarPrevious from "components/SVGs/IconCalendarPrevious";
import IconCalendarNext from "components/SVGs/IconCalendarNext";
import { PickerSelectionState } from "@mui/x-date-pickers/internals";
import { PickersDay } from "@mui/x-date-pickers";
import useHoliday from "hooks/useIsHoliday";

const CustomDay = memo((props: any) => {
  const holiday = useHoliday(props["data-timestamp"]);
  return (
    <div className="relative">
      <PickersDay {...props} />
      {!props.selected && !!holiday && (
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 24,
            width: 8,
            height: 8,
            borderRadius: 4,
            backgroundColor: "var(--color-accent)",
          }}
        />
      )}
    </div>
  );
});

interface IDashboardCalendar {
  onChange?: (date: Date) => void;
  disablePast?: boolean;
  initialDate?: Date;
}

const DashboardCalendar = ({
  onChange,
  disablePast,
  initialDate = new Date(),
}: IDashboardCalendar) => {
  const [date, setDate] = useState(initialDate);
  const [selectedDate, setSelectedDate] = useState(initialDate);
  const valueCalendar = useMemo<any>(
    () => moment(selectedDate),
    [selectedDate]
  );
  const onCalendarChangeDate = useCallback(
    (value: Date | null, selectionState?: PickerSelectionState | undefined) => {
      if (value) {
        setSelectedDate(value);
        onChange?.(value);
      }
    },
    [onChange]
  );
  const onMonthChange = useCallback(
    (value: Date | null, selectionState?: PickerSelectionState | undefined) => {
      if (value) {
        if (disablePast && moment().diff(value) > 0) {
          setDate(new Date());
        } else {
          setDate(value);
        }
        onChange?.(value);
      }
    },
    [disablePast, onChange]
  );
  const leftArrowIcon = useCallback(() => <IconCalendarPrevious />, []);
  const rightArrowIcon = useCallback(() => <IconCalendarNext />, []);
  const day = useCallback((props: any) => {
    return <CustomDay {...props} />;
  }, []);
  return (
    <>
      <span className="calendar-label">
        {moment(date).format("MMMM YYYY")},{" "}
        <span style={{ color: "#A7A7A7" }}>{moment(date).format("dddd")}</span>
      </span>
      <DateCalendar
        slots={{
          leftArrowIcon,
          rightArrowIcon,
          switchViewButton: () => null,
          day,
        }}
        onChange={onCalendarChangeDate}
        value={valueCalendar}
        showDaysOutsideCurrentMonth
        onMonthChange={onMonthChange}
        disablePast={disablePast}
      />
    </>
  );
};

export default memo(DashboardCalendar);
