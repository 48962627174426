import React, { memo, useCallback, useMemo, useState } from "react";
import styles from "./index.module.scss";
import { CircularProgress, Stack } from "@mui/material";
import IconCheck from "components/SVGs/IconCheck";
import useAppSelector from "hooks/useAppSelector";
import { INotification } from "models/User";
import NotificationItem from "./NotificationItem";
import IconChevronDown from "components/SVGs/IconChevronDown";
import useAppDispatch from "hooks/useAppDispatch";
import { getNotifications } from "reducers/UserActions";
import api from "api";
import { USER_ACTIONS } from "reducers/UserReducers";

interface IPopupNotification {
  onClose: () => void;
}

const PopupNotification = ({ onClose }: IPopupNotification) => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state.user.notification.data);
  const { canMore, currentPage, loadMore } = useAppSelector(
    (state) => state.user.notification
  );
  const [showAll, setShowAll] = useState(false);
  const data = useMemo(() => {
    if (showAll) return notifications;
    return notifications.slice(0, 3);
  }, [notifications, showAll]);
  const toggleViewAll = useCallback(
    () => setShowAll((current) => !current),
    []
  );
  const renderNotification = useCallback(
    (item: INotification) => (
      <NotificationItem item={item} key={item.id} onClose={onClose} />
    ),
    [onClose]
  );
  const onMore = useCallback(() => {
    if (loadMore || !canMore) return;
    dispatch(getNotifications({ page: currentPage + 1 }));
  }, [canMore, currentPage, dispatch, loadMore]);
  const onReadAllClick = useCallback(() => {
    api.user.readAllNotification();
    dispatch(USER_ACTIONS.readAllNotification());
  }, [dispatch]);
  const onScroll = useCallback(
    (e: any) => {
      const { scrollTop, scrollHeight, clientHeight } = e.target;
      if (scrollTop + clientHeight === scrollHeight) {
        onMore();
      }
    },
    [onMore]
  );
  return (
    <div className={styles.container}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        margin="20px"
      >
        <span className={styles.title}>Notifications</span>
        <Stack
          direction="row"
          alignItems="center"
          className={styles["btn-read-all"]}
          onClick={onReadAllClick}
        >
          <IconCheck style={{ marginRight: 5 }} />
          <span>Mark as read</span>
        </Stack>
      </Stack>
      <Stack
        className={styles["notification-content__container"]}
        onScroll={onScroll}
      >
        {data.map(renderNotification)}
        {canMore && showAll && loadMore && (
          <Stack className={styles["btn-load-more"]}>
            <CircularProgress color="inherit" size={14} />
          </Stack>
        )}
      </Stack>
      {notifications.length > 3 && (
        <Stack
          direction="row"
          alignItems="center"
          className={styles["btn-toggle"]}
          onClick={toggleViewAll}
        >
          <span>{showAll ? "View all less" : "View all notifications"}</span>
          <IconChevronDown
            fill="var(--color-accent)"
            style={{ rotate: showAll ? "180deg" : "0deg" }}
          />
        </Stack>
      )}
    </div>
  );
};

export default memo(PopupNotification);
