import { INotification } from "models/User";
import React, { memo, useCallback, useMemo } from "react";
import styles from "./index.module.scss";
import moment from "moment";
import images from "common/images";
import api from "api";
import useAppDispatch from "hooks/useAppDispatch";
import { USER_ACTIONS } from "reducers/UserReducers";
import useUser from "hooks/useUser";
import { useNavigate } from "react-router-dom";

interface INotificationItem {
  item: INotification;
  onClose: () => void;
}

const NotificationItem = ({ item, onClose }: INotificationItem) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useUser();
  const iconNotification = useMemo(() => {
    if (item.type === "Authentication" || item.type === "ChangePassword")
      return images.icNotificationAuthenticate;
    if (item.candidate?.avatar?.src) return item.candidate?.avatar?.src;
    return user.client?.logo?.src || images.sampleAvatar;
  }, [item.candidate?.avatar?.src, item.type, user.client?.logo?.src]);
  const getNotificationData = useCallback(() => {
    try {
      const data = JSON.parse(item.additionalInfo);
      return data;
    } catch (error) {
      return null;
    }
  }, [item.additionalInfo]);
  const onClick = useCallback(() => {
    api.user.readNotification({ id: item.id });
    dispatch(USER_ACTIONS.readNotification(item.id));
    const notificationData = getNotificationData();
    switch (item.type) {
      case "AccessBilling":
        const hashBilling = notificationData?.billingId
          ? `#${notificationData?.billingId}`
          : "";
        navigate(`/billing${hashBilling}`);
        break;
      case "Timesheet":
        const hashTimeSheet = notificationData?.timesheetId
          ? `#${notificationData?.timesheetId}`
          : "";
        navigate(`/time-sheets${hashTimeSheet}`);
        break;
      case "CreatedUser":
      case "ChangePassword":
        navigate("/user-management");
        break;
      case "ChangeLastStep":
        const hashCandidate = notificationData?.candidateId
          ? `#${notificationData?.candidateId}`
          : "";
        if (notificationData?.isAfterHired === true) {
          navigate(`/manage${hashCandidate}`);
        } else if (notificationData?.isAfterHired === false) {
          navigate(`/recruit${hashCandidate}`);
        }
        break;
      default:
        break;
    }
    onClose();
  }, [dispatch, getNotificationData, item.id, item.type, navigate, onClose]);
  return (
    <div className={styles["item__container"]} onClick={onClick}>
      <div className={`${styles.dot} ${item.read ? styles.read : ""}`} />
      <div className={styles["content__container"]}>
        <span className={styles.title}>{item.content}</span>
        <span className={styles.time}>
          {moment(item.createdAt).format("MMM D. YYYY [at] HH:mm A")}
        </span>
      </div>
      <img
        src={iconNotification}
        className={styles.icon}
        alt="icon-notification"
      />
    </div>
  );
};

export default memo(NotificationItem);
