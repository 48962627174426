import AppConfig from "common/AppConfig";
import Caller from "./Caller";
import { IBill } from "models/Bill";

const BillAPI = {
  list: async (req: { page: number }) => {
    return Caller.get<IBill[]>(
      `billings?${new URLSearchParams({
        page: `${req.page}`,
        perPage: `${AppConfig.perPage}`,
      })}`
    );
  },
  find: async (req: { billingId: string }) =>
    Caller.get(
      `billings/findBilling?${new URLSearchParams({
        billingId: req.billingId,
        perPage: `${AppConfig.perPage}`,
      })}`
    ),
};

export default BillAPI;
