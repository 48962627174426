import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "./UserActions";
import { IAction, ICandidate } from "models/Candidate";
import api from "api";
import {
  deleteCandidate,
  hireCandidate,
  updateCandidate,
} from "actions/CandidateActions";
import AppConfig from "common/AppConfig";

interface RecruitState {
  filters: IAction[];
  selectedFilter: string;
  candidates?: ICandidate[];
  loading?: boolean;
  totalPage: number;
}

const initialState: RecruitState = {
  filters: [],
  selectedFilter: "",
  totalPage: 1,
};

export const getRecruitStatus = createAsyncThunk("recruit/status", async () => {
  const res = await api.candidate.status(false);
  return res?.data;
});

export const getCandidates = createAsyncThunk(
  "recruit/candidate",
  async (req: {
    selectedFilter: string;
    selectedJobTitle: string;
    sortName?: string;
    sortOrder?: "ASC" | "DESC";
    page?: number;
  }) => {
    const res = await api.candidate.list(req);
    return res;
  }
);

const recruitSlice = createSlice({
  name: "recruit",
  initialState,
  reducers: {
    updateSelected: (state, action: PayloadAction<string>) => {
      state.selectedFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutAction, (state) => {
        return initialState;
      })
      .addCase(updateCandidate, (state, action) => {
        state.candidates = state.candidates?.map((el) => {
          if (el.id === action.payload.id) {
            return action.payload;
          }
          return el;
        });
        if (state.selectedFilter) {
          state.candidates = state.candidates?.filter(
            (el) => el.lastStep?.id === state.selectedFilter
          );
        }
      })
      .addCase(deleteCandidate, (state, action) => {
        state.candidates = state.candidates?.filter(
          (el) => el.id !== action.payload.id
        );
      })
      .addCase(hireCandidate, (state, action) => {
        state.candidates = state.candidates?.filter(
          (el) => el.id !== action.payload.id
        );
      })
      .addCase(getRecruitStatus.fulfilled, (state, action) => {
        state.filters = [
          {
            id: "",
            isAfterHired: false,
            name: "All",
            orderNumber: 0,
            status: "All",
          },
          ...(action.payload || []),
        ];
      })
      .addCase(getCandidates.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCandidates.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getCandidates.fulfilled, (state, action) => {
        state.candidates = action.payload?.data || [];
        state.totalPage = Math.ceil(
          (action.payload?.total || 0) / AppConfig.perPage
        );
        state.loading = false;
      });
  },
});

export const RECRUIT_ACTIONS = recruitSlice.actions;

export default recruitSlice.reducer;
