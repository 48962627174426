import { useMemo } from "react";
import useAppSelector from "./useAppSelector";

function useCommentData(candidateId?: string) {
  const data = useAppSelector((state) => state.comment.commentData);
  return useMemo(
    () => (candidateId ? data?.[candidateId] : null),
    [candidateId, data]
  );
}

export default useCommentData;
