import Dashboard from "pages/Dashboard";
import Login from "pages/Login";
import Requisition from "pages/Requisition";
import React, { memo } from "react";
import { Routes as AppRoutes, Navigate, Route } from "react-router-dom";
import Layout from "./Layout";
import Recruit from "pages/Recruit";
import Manage from "pages/Manage";
import Billing from "pages/Billing";
import TimeSheet from "pages/TimeSheet";
import Security from "pages/Security";
import ResetPassword from "pages/ResetPassword";
import VerifyPassword from "pages/VerifyPassword";
import UserManagement from "pages/UserManagement";
import ITAssets from "pages/ITAssets";

const Routers = () => {
  return (
    <AppRoutes>
      <Route element={<Layout />}>
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/requisition" element={<Requisition />} />
        <Route path="/recruit" element={<Recruit />} />
        <Route path="/manage" element={<Manage />} />
        <Route path="/billing" element={<Billing />} />
        <Route path="/time-sheets" element={<TimeSheet />} />
        <Route path="/security" element={<Security />} />
        <Route path="/user-management" element={<UserManagement />} />
        <Route path="/it-assets" element={<ITAssets />} />
      </Route>
      <Route path="/login" element={<Login />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/reset-password/:token" element={<VerifyPassword />} />
    </AppRoutes>
  );
};

export default memo(Routers);
