const Prefix = "sibyl_teams";

const AppConfig = {
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL,
  socketBaseUrl: process.env.REACT_APP_SOCKET_BASE_URL,
  maximumFileSize: 100000000,
  loginPath: "/login",
  authApis: ["auth/verifyresetpassword", "auth/resetpassword"],
  perPage: 10,
};

export const AsyncKey = {
  userIdKey: `${Prefix}_user_id`,
  accessTokenKey: `${Prefix}_access_token`,
  refreshTokenKey: `${Prefix}_refresh_token`,
  tokenExpire: `${Prefix}_token_expire_key`,
  refreshTokenExpire: `${Prefix}_refresh_token_expire_key`,
};

export default AppConfig;
