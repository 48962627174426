import { LoadingButton } from "@mui/lab";
import { Box, Container, TextField, Typography } from "@mui/material";
import api from "api";
import { useToast } from "providers/ToastProvider";
import React, { memo, useCallback, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const VerifyPassword = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const params = useParams();
  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const password = data.get("password")?.toString() || "";
      if (password && params.token) {
        setLoading(true);
        const res = await api.user.verifyPass({
          newPassword: password,
          resetToken: params.token,
        });
        if (res.statusCode === 200) {
          toast.success({
            message: res.message || "Success",
          });
          navigate("/login", { replace: true });
        } else {
          toast.error({ message: res.message || "Something wrong" });
        }
        setLoading(false);
      }
    },
    [navigate, params.token, toast]
  );
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Set new Password
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            placeholder="Enter new Password"
            type="password"
            id="password"
          />
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={loading}
          >
            Confirm
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
};

export default memo(VerifyPassword);
