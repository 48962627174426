import { memo, useCallback } from "react";
import styles from "./index.module.scss";
import { useAuth } from "providers/AuthProvider";
import { useNavigate } from "react-router-dom";
import IconKey from "components/SVGs/IconKey";
import IconAddUser from "components/SVGs/IconAddUser";
import IconLogout from "components/SVGs/IconLogout";

interface IMenuUser {
  onClose: () => void;
}

const MenuUser = ({ onClose }: IMenuUser) => {
  const auth = useAuth();
  const navigate = useNavigate();
  const onLogout = useCallback(() => {
    auth.logout();
    onClose();
  }, [auth, onClose]);
  const onSecureClick = useCallback(() => {
    navigate("/security");
    onClose();
  }, [navigate, onClose]);
  const onAddUserClick = useCallback(() => {
    navigate("/user-management");
    onClose();
  }, [navigate, onClose]);
  return (
    <div className={styles["menu-container"]}>
      <div className={styles["menu-item"]} onClick={onSecureClick}>
        <IconKey style={{ marginRight: 10 }} />
        <span>Security</span>
      </div>
      <div className={styles["menu-item"]} onClick={onAddUserClick}>
        <IconAddUser style={{ marginRight: 10 }} />
        <span>Roles & Permissions</span>
      </div>
      <div className={styles["menu-item"]} onClick={onLogout}>
        <IconLogout style={{ marginRight: 10 }} />
        <span>Logout</span>
      </div>
    </div>
  );
};

export default memo(MenuUser);
