import React, { memo } from "react";
import styles from "./index.module.scss";
import { Stack, TableCell, TableRow } from "@mui/material";
import moment from "moment";
import { IKeyDate } from "models/Dashboard";

interface IKeyDateHolidayItem {
  keyDate: IKeyDate;
}

const KeyDateHolidayItem = ({ keyDate }: IKeyDateHolidayItem) => {
  return (
    <TableRow>
      <TableCell style={{ padding: "15px 0" }}>
        <Stack
          direction="row"
          alignItems="center"
          className={styles["name__wrapper"]}
        >
          <div className={styles.dot} />
          <span className={`${styles.name} truncate`}>{keyDate.name}</span>
        </Stack>
      </TableCell>
      <TableCell className={styles.date} style={{ padding: "15px 0" }}>
        {moment(keyDate.date).format("MMM DD")}
      </TableCell>
      <TableCell style={{ padding: "15px 0" }} width={191}>
        {keyDate.event && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            height={38}
            style={{
              borderRadius: 14,
              border: "1px solid var(--color-accent)",
              maxWidth: 250,
              width: "fit-content",
              padding: "0 10px",
              minWidth: 191,
            }}
          >
            <span className={`${styles.action} truncate`}>{keyDate.event}</span>
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
};

export default memo(KeyDateHolidayItem);
