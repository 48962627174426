import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { INotification, IUser, tempUser } from "models/User";
import { getNotifications, getUserAction, logoutAction } from "./UserActions";

interface UserState {
  data: IUser;
  notification: {
    data: INotification[];
    currentPage: number;
    canMore: boolean;
    loadMore: boolean;
    loading: boolean;
  };
}

const initialState: UserState = {
  data: tempUser,
  notification: {
    data: [],
    currentPage: 1,
    canMore: false,
    loading: false,
    loadMore: false,
  },
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    onReceiveNotification: (state, action: PayloadAction<INotification>) => {
      state.notification.data?.unshift(action.payload);
    },
    readAllNotification: (state) => {
      state.notification.data = state.notification.data.map((el) => {
        if (!el.read) {
          return {
            ...el,
            read: true,
          };
        }
        return el;
      });
    },
    readNotification: (state, action: PayloadAction<string>) => {
      state.notification.data = state.notification.data.map((el) => {
        if (el.id === action.payload) {
          return {
            ...el,
            read: true,
          };
        }
        return el;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutAction, (state: UserState) => {
        return initialState;
      })
      // .addCase(loginAction.fulfilled, (state: UserState, action) => {
      //   if (action.payload.data?.user) {
      //     state.data = action.payload.data?.user;
      //   }
      // })
      .addCase(getUserAction.fulfilled, (state: UserState, action) => {
        const user = action.payload.data;
        if (user) {
          state.data = user;
        }
      })
      .addCase(getNotifications.pending, (state, action) => {
        if (action.meta.arg.page > 1) {
          state.notification.loadMore = true;
        } else {
          state.notification.loading = true;
        }
      })
      .addCase(getNotifications.rejected, (state, action) => {
        state.notification.loadMore = false;
        state.notification.loading = false;
      })
      .addCase(getNotifications.fulfilled, (state, action) => {
        const notificationData = action.payload?.data || [];
        const currentPage = action.meta.arg.page;
        const data =
          currentPage === 1
            ? notificationData
            : [...(state.notification.data || []), ...notificationData];
        state.notification = {
          data,
          currentPage,
          canMore: notificationData.length === 10,
          loading: false,
          loadMore: false,
        };
      });
  },
});

export const USER_ACTIONS = userSlice.actions;

export default userSlice.reducer;
