import React, { memo, CSSProperties } from "react";

interface IIconChevronDown {
  style?: CSSProperties;
  fill?: string;
}

const IconChevronDown = ({ style, fill = "black" }: IIconChevronDown) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M12.5 14.975C12.3667 14.975 12.2377 14.95 12.113 14.9C11.9877 14.85 11.8833 14.7833 11.8 14.7L7.19999 10.1C7.01665 9.91665 6.92499 9.68332 6.92499 9.39999C6.92499 9.11665 7.01665 8.88332 7.19999 8.69999C7.38332 8.51665 7.61665 8.42499 7.89999 8.42499C8.18332 8.42499 8.41665 8.51665 8.59999 8.69999L12.5 12.6L16.4 8.69999C16.5833 8.51665 16.8167 8.42499 17.1 8.42499C17.3833 8.42499 17.6167 8.51665 17.8 8.69999C17.9833 8.88332 18.075 9.11665 18.075 9.39999C18.075 9.68332 17.9833 9.91665 17.8 10.1L13.2 14.7C13.1 14.8 12.9917 14.8707 12.875 14.912C12.7583 14.954 12.6333 14.975 12.5 14.975Z"
        fill={fill}
      />
    </svg>
  );
};

export default memo(IconChevronDown);
