import { IconButton, Stack } from "@mui/material";
import React, { CSSProperties, memo, useCallback } from "react";
import styles from "./index.module.scss";
import IconDocument from "components/SVGs/IconDocument";
import IconRemove from "components/SVGs/IconRemove";
import IconDownload from "components/SVGs/IconDownload";

interface IFileItem {
  onRemoveFile?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  style?: CSSProperties;
  suffixIcon?: React.ReactNode;
  name?: string;
  description?: string;
  src?: string;
}

const FileItem = ({
  name,
  description,
  src,
  suffixIcon,
  style,
  onRemoveFile,
}: IFileItem) => {
  const onClick = useCallback(() => {
    if (!src) return;
    window.open(src, "_blank");
  }, [src]);
  if (!src) return null;
  return (
    <Stack
      direction="row"
      alignItems="center"
      className={`${styles.container} ${!!src ? styles.clickable : ""}`}
      style={style}
      onClick={onClick}
    >
      {suffixIcon ? suffixIcon : <IconDocument />}
      <Stack marginLeft="10px" flex={1} className="truncate-flex">
        <span className={`${styles["file-name"]} truncate`}>{name}</span>
        {description && (
          <span className={styles["file-size"]}>{description}</span>
        )}
      </Stack>
      {onRemoveFile ? (
        <IconButton onClick={onRemoveFile}>
          <IconRemove />
        </IconButton>
      ) : (
        <IconButton>
          <IconDownload />
        </IconButton>
      )}
    </Stack>
  );
};

export default memo(FileItem);
