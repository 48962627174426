import { Stack } from "@mui/material";
import { DashboardChartData } from "models";
import React, { memo } from "react";
import styles from "./index.module.scss";
import numeral from "numeral";

interface ITeamDistributionItem {
  distribution: DashboardChartData;
  color: string;
}

const TeamDistributionItem = ({
  distribution,
  color,
}: ITeamDistributionItem) => {
  return (
    <Stack
      width={`${distribution.percentage * 100}%`}
      className={styles.container}
    >
      <Stack>
        <Stack direction="row">
          <div
            className={styles.dot}
            style={{
              backgroundColor: color,
              marginTop: 8,
            }}
          />
          <Stack marginLeft="10px">
            <span className={styles.value}>{distribution.count}</span>
            <span className={styles.name}>{distribution.name}</span>
          </Stack>
        </Stack>
        <div className={styles["progress__wrapper"]}>
          <div className={styles["white-space"]} />
          <div
            className={styles.progress}
            style={{
              backgroundColor: color,
            }}
          />
        </div>
        <span className={styles.percent}>
          {numeral(distribution.percentage * 100).format("0")}%
        </span>
      </Stack>
    </Stack>
  );
};

export default memo(TeamDistributionItem);
