import {
  CircularProgress,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import { ICandidate } from "models/Candidate";
import React, { memo, useCallback, useMemo, useState } from "react";
import styles from "./index.module.scss";
import images from "common/images";
import moment from "moment";
import IconHelp from "components/SVGs/IconHelp";
import api from "api";
import useUser from "hooks/useUser";
import { useLocation } from "react-router-dom";
import IconNextStage from "components/SVGs/IconNextStage";
import IconCancelStage from "components/SVGs/IconCancelStage";

interface ICandidateItem {
  candidate: ICandidate;
  onCandidateClick: (candidate: ICandidate) => void;
  canSeeRate: boolean;
}

const CandidateItem = ({
  candidate,
  onCandidateClick,
  canSeeRate,
}: ICandidateItem) => {
  const location = useLocation();
  const jobTitle = useMemo(() => {
    return `${candidate.jobLevel?.abbr} ${candidate.jobFamily?.name}`;
  }, [candidate.jobFamily?.name, candidate.jobLevel?.abbr]);
  const [nextLoading, setNextLoading] = useState(false);
  const [notGoLoading, setNotGoLoading] = useState(false);
  const user = useUser();
  const onGoToNextClick = useCallback(async () => {
    if (nextLoading) return;
    setNextLoading(true);
    await api.candidate.goToNextStep({
      candidateId: candidate.id,
      clientId: user.client.id,
    });
    setNextLoading(false);
  }, [candidate.id, nextLoading, user.client.id]);
  const onNotGoClick = useCallback(async () => {
    if (notGoLoading) return;
    setNotGoLoading(true);
    await api.candidate.failedStep({
      candidateId: candidate.id,
      clientId: user.client.id,
    });
    setNotGoLoading(false);
  }, [candidate.id, notGoLoading, user.client.id]);
  const backgroundLastStep = useMemo(
    () => (candidate.lastStepResult ? "#0B9607" : "#FFA600"),
    [candidate.lastStepResult]
  );
  const renderSource = useCallback(() => {
    return (
      <Tooltip title={candidate.source?.onboardRate} arrow placement="top">
        <Stack className={styles.name} flexDirection="row" alignItems="center">
          <span>{candidate.source?.name}</span>
          {candidate.source?.onboardRate && (
            <IconHelp style={{ marginLeft: 6 }} />
          )}
        </Stack>
      </Tooltip>
    );
  }, [candidate.source?.name, candidate.source?.onboardRate]);
  const renderLastStep = useCallback(() => {
    if (!candidate.lastStep) return null;
    if (candidate.lastStep.description) {
      return (
        <Tooltip title={candidate.lastStep.description} arrow placement="top">
          <Stack
            className={`${styles["last-step"]} cursor-pointer`}
            style={{ backgroundColor: backgroundLastStep }}
          >
            <span>{candidate.lastStep.name}</span>
            <IconHelp fill="white" style={{ marginLeft: 6 }} />
          </Stack>
        </Tooltip>
      );
    }
    return (
      <Stack
        className={`${styles["last-step"]} cursor-pointer`}
        style={{ backgroundColor: backgroundLastStep }}
      >
        <span>{candidate.lastStep.name}</span>
      </Stack>
    );
  }, [backgroundLastStep, candidate.lastStep]);
  const nextStepDisplay = useMemo(
    () => candidate.nextStep?.actionButton || candidate.nextStep?.name,
    [candidate.nextStep?.actionButton, candidate.nextStep?.name]
  );
  const renderProceedCheck = useCallback(() => {
    if (!candidate.nextStep) return null;
    if (!candidate.lastStep?.isAfterHired && candidate.nextStep.isAfterHired)
      return null;
    if (candidate.nextStep.isClientAction) {
      return (
        <Stack
          direction="row"
          gap="12px"
          padding="0 10px"
          justifyContent="center"
        >
          <Tooltip title="Next Stage" arrow placement="top">
            <Stack
              className="cursor-pointer"
              onClick={onGoToNextClick}
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {nextLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <IconNextStage />
              )}
            </Stack>
          </Tooltip>
          <Tooltip title="No-Go" arrow placement="top">
            <Stack
              className="cursor-pointer"
              style={{
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={onNotGoClick}
            >
              {notGoLoading ? (
                <CircularProgress size={20} color="inherit" />
              ) : (
                <IconCancelStage />
              )}
            </Stack>
          </Tooltip>
        </Stack>
      );
    }
    return null;
  }, [
    candidate.lastStep?.isAfterHired,
    candidate.nextStep,
    nextLoading,
    notGoLoading,
    onGoToNextClick,
    onNotGoClick,
  ]);
  const renderNextStep = useCallback(() => {
    if (!candidate.nextStep) return null;
    if (!candidate.lastStep?.isAfterHired && candidate.nextStep.isAfterHired) {
      if (candidate.nextStep.description) {
        return (
          <Tooltip title={candidate.nextStep.description} arrow placement="top">
            <Stack className={`${styles["next-step-final"]}`}>
              <span>
                Join Date:{" "}
                {moment(candidate.nextStepDate).format("DD MMM YYYY")}
              </span>
              <IconHelp fill="white" style={{ marginLeft: 6 }} />
            </Stack>
          </Tooltip>
        );
      }
      return (
        <Stack className={`${styles["next-step-final"]}`}>
          <span>
            Join Date: {moment(candidate.nextStepDate).format("DD MMM YYYY")}
          </span>
        </Stack>
      );
    }
    return (
      <Stack className={`${styles["next-step"]}`}>
        <span>{nextStepDisplay}</span>
      </Stack>
    );
  }, [
    candidate.lastStep?.isAfterHired,
    candidate.nextStep,
    candidate.nextStepDate,
    nextStepDisplay,
  ]);
  const handleCandidateClick = useCallback(() => {
    onCandidateClick(candidate);
  }, [candidate, onCandidateClick]);
  return (
    <TableRow
      className={`${location.hash === `#${candidate.id}` ? styles.active : ""}`}
    >
      <TableCell style={{ padding: "15px 0" }}>
        <Stack
          direction="row"
          alignItems="center"
          className="cursor-pointer"
          onClick={handleCandidateClick}
        >
          <img
            alt="avatar"
            src={
              candidate.avatar?.src ||
              user.client?.logo?.src ||
              images.sampleAvatar
            }
            className={styles.avatar}
          />
          <Stack marginLeft="10px">
            <span className={styles.name}>{candidate.name}</span>
            <span
              className={styles.description}
              style={{ fontSize: 13, lineHeight: "18px" }}
            >
              {jobTitle}
            </span>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell className={styles.name} style={{ padding: "15px 0" }}>
        {moment(candidate.availableFrom).format("MMM DD")}
      </TableCell>
      <TableCell style={{ padding: "15px 0" }} width={191}>
        <Stack direction="row" alignItems="center" className={styles.english}>
          <div className={styles.dot} />
          <span className={styles["english-text"]}>
            {candidate.english?.name}
          </span>
        </Stack>
      </TableCell>
      {canSeeRate && (
        <TableCell
          className={styles.name}
          style={{ padding: "15px 10px 15px 10px" }}
          align="center"
        >
          {candidate.isAdhocRate ? (
            <Stack className={styles["ad-hoc-rate"]} margin="auto">
              {candidate.currency?.symbol} {candidate.rate}
            </Stack>
          ) : (
            `${candidate.currency?.symbol} ${candidate.rate}`
          )}
        </TableCell>
      )}
      <TableCell style={{ padding: "15px 10px 15px 10px" }}>
        {renderSource()}
      </TableCell>
      <TableCell style={{ padding: "15px 0" }}>{renderLastStep()}</TableCell>
      <TableCell style={{ padding: "15px 0" }}>
        {renderProceedCheck()}
      </TableCell>
      <TableCell style={{ padding: "15px 0" }}>{renderNextStep()}</TableCell>
    </TableRow>
  );
};

export default memo(CandidateItem);
