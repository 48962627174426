import React, { memo } from "react";
import styles from "./index.module.scss";
import CreateNewUser from "./CreateNewUser";
import UpdateUserRoles from "./UpdateUserRoles";

const UserManagement = () => {
  return (
    <div className={`${styles.container} container`}>
      <h1 className="page-title">Roles & Permissions</h1>
      <CreateNewUser />
      <UpdateUserRoles />
    </div>
  );
};

export default memo(UserManagement);
