import { IconButton, Stack } from "@mui/material";
import React, { memo, useCallback, useRef } from "react";
import { Outlet } from "react-router-dom";
import SideBar from "./SideBar";
import IconNotification from "components/SVGs/IconNotification";
import PopoverButton from "components/PopoverButton";
import PopupNotification from "components/PopupNotification";

const Layout = () => {
  const popupNotificationRef = useRef<any>();
  const onClosePopup = useCallback(() => {
    popupNotificationRef.current.hide();
  }, []);
  return (
    <Stack direction="row" overflow="hidden">
      <SideBar />
      <Stack flex={1}>
        <Outlet />
      </Stack>
      <div style={{ position: "fixed", right: 32, top: 27 }}>
        <PopoverButton
          ref={popupNotificationRef}
          componentPopup={<PopupNotification onClose={onClosePopup} />}
          componentButton={
            <IconButton>
              <IconNotification
                style={{ background: "white", borderRadius: 20 }}
              />
            </IconButton>
          }
        />
      </div>
    </Stack>
  );
};

export default memo(Layout);
