import {
  IEnglishLevel,
  IJobFamily,
  IJobLevel,
  ISkillTesting,
} from "models/Metadata";
import Caller from "./Caller";
import { IUser } from "models/User";

const MetadataAPI = {
  getEnglishLevel: () =>
    Caller.get<IEnglishLevel[]>("english?page=1&perPage=100"),
  getJobLevel: (clientId: string) =>
    Caller.get<IJobLevel[]>(
      `jobLevels?page=1&perPage=100&clientId=${clientId}`
    ),
  getJobFamily: (clientId: string) =>
    Caller.get<IJobFamily[]>(
      `jobFamilies?page=1&perPage=100&clientId=${clientId}`
    ),
  getSkillTesting: (clientId: string) =>
    Caller.get<ISkillTesting[]>(
      `skillTestings?page=1&perPage=100&clientId=${clientId}`
    ),
  getUsers: (req?: {
    page?: number;
    perPage?: number;
    sortName?: string;
    sortOrder?: string;
  }) =>
    Caller.get<IUser[]>(
      `users?${new URLSearchParams({
        page: `${req?.page || "1"}`,
        perPage: `${req?.perPage || "100"}`,
        sortName: req?.sortName || "",
        sortOrder: req?.sortOrder || "",
      })}`
    ),
};

export default MetadataAPI;
