import { Grid, MenuItem, Stack, TextField } from "@mui/material";
import React, { memo, useCallback, useMemo } from "react";
import styles from "./index.module.scss";
import useEnglishLevels from "hooks/useEnglishLevels";
import useJobLevels from "hooks/useJobLevels";
import useJobFamilies from "hooks/useJobFamilies";
import { RequisitionRequest } from "models/Requisition";
import IconArrowSelect from "components/SVGs/IconArrowSelect";
import { FileRejection, useDropzone } from "react-dropzone";
import IconUpload from "components/SVGs/IconUpload";
import IconChevronRight from "components/SVGs/IconChevronRight";
import { useToast } from "providers/ToastProvider";
import FileItem from "components/FileItem";
import numeral from "numeral";

interface IRequisitionKeyDates {
  requisitionData: RequisitionRequest;
  setRequisitionData: React.Dispatch<React.SetStateAction<RequisitionRequest>>;
}

const RequisitionKeyDates = ({
  requisitionData,
  setRequisitionData,
}: IRequisitionKeyDates) => {
  const toast = useToast();
  const englishLevels = useEnglishLevels();
  const jobLevels = useJobLevels();
  const jobFamilies = useJobFamilies();
  const docTypes = useMemo(
    () => ({
      "application/msword": [], // .doc
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
        [], // .docx
      "application/pdf": [],
    }),
    []
  );
  const onDrop = useCallback(
    (acceptedFiles: File[], fileRejections: FileRejection[]) => {
      if (fileRejections.length > 0) {
        toast.error({
          message: fileRejections?.[0]?.errors?.[0]?.message || "",
        });
      }
      if (acceptedFiles.length > 0) {
        setRequisitionData((current) => ({
          ...current,
          file: acceptedFiles?.[0],
        }));
      }
    },
    [setRequisitionData, toast]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: { ...docTypes },
    // maxSize: 5000,
    multiple: false,
  });
  const onChangeTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRequisitionData((current) => ({
        ...current,
        jobTitle: e.target.value,
      }));
    },
    [setRequisitionData]
  );
  const onChangeJobFamily = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value: any = e.target.value;
      setRequisitionData((current) => ({
        ...current,
        jobFamily:
          typeof value === "string"
            ? value.split(",")
            : value.filter((el: any) => !!el),
      }));
    },
    [setRequisitionData]
  );
  const onChangeJobLevel = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value: any = e.target.value;
      setRequisitionData((current) => ({
        ...current,
        jobLevel:
          typeof value === "string"
            ? value.split(",")
            : value.filter((el: any) => !!el),
      }));
    },
    [setRequisitionData]
  );
  const onChangeEnglishLevel = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value: any = e.target.value;
      setRequisitionData((current) => ({
        ...current,
        english:
          typeof value === "string"
            ? value.split(",")
            : value.filter((el: any) => !!el),
      }));
    },
    [setRequisitionData]
  );
  const onRemoveFile = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      setRequisitionData((current) => ({ ...current, file: null }));
    },
    [setRequisitionData]
  );
  const displayEnglish = useMemo(() => {
    if (requisitionData.english.length === 0) return [""];
    return requisitionData.english;
  }, [requisitionData.english]);
  const displayJobFamily = useMemo(() => {
    if (requisitionData.jobFamily.length === 0) return [""];
    return requisitionData.jobFamily;
  }, [requisitionData.jobFamily]);
  const displayJobLevel = useMemo(() => {
    if (requisitionData.jobLevel.length === 0) return [""];
    return requisitionData.jobLevel;
  }, [requisitionData.jobLevel]);
  return (
    <Stack>
      <span className={"session-label"}>Key Dates</span>
      <Grid container columnSpacing={2.5} rowSpacing={1.625} marginTop="12px">
        <Grid item md={12}>
          <Stack>
            <span
              className={styles["text-field-label"]}
              style={{ marginBottom: 15 }}
            >
              Job Title
            </span>
            <TextField
              placeholder="Job Title"
              value={requisitionData.jobTitle}
              onChange={onChangeTitle}
            />
          </Stack>
        </Grid>
        <Grid item md={12} lg={4}>
          <Stack>
            <span
              className={styles["text-field-label"]}
              style={{ marginBottom: 15 }}
            >
              Job Family
            </span>
            <TextField
              select
              value={displayJobFamily}
              onChange={onChangeJobFamily}
              SelectProps={{
                IconComponent: (props) => (
                  <IconArrowSelect {...props} style={{ right: 13 }} />
                ),
                displayEmpty: true,
                multiple: true,
              }}
            >
              <MenuItem value="" style={{ display: "none" }}>
                <span className={styles["placeholder"]}>Select Job Family</span>
              </MenuItem>
              {jobFamilies?.map((el) => (
                <MenuItem key={el.id} value={el.id}>
                  {el.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Grid>
        <Grid item md={12} lg={4}>
          <Stack>
            <span
              className={styles["text-field-label"]}
              style={{ marginBottom: 15 }}
            >
              Job Level
            </span>
            <TextField
              select
              value={displayJobLevel}
              onChange={onChangeJobLevel}
              SelectProps={{
                IconComponent: (props) => (
                  <IconArrowSelect {...props} style={{ right: 13 }} />
                ),
                displayEmpty: true,
                multiple: true,
              }}
            >
              <MenuItem value="" style={{ display: "none" }}>
                <span className={styles["placeholder"]}>Select Job Level</span>
              </MenuItem>
              {jobLevels?.map((el) => (
                <MenuItem key={el.id} value={el.id}>
                  {el.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Grid>
        <Grid item md={12} lg={4}>
          <Stack>
            <span
              className={styles["text-field-label"]}
              style={{ marginBottom: 15 }}
            >
              English Level
            </span>
            <TextField
              select
              value={displayEnglish}
              onChange={onChangeEnglishLevel}
              SelectProps={{
                IconComponent: (props) => (
                  <IconArrowSelect {...props} style={{ right: 13 }} />
                ),
                displayEmpty: true,
                multiple: true,
              }}
            >
              <MenuItem value="" style={{ display: "none" }}>
                <span className={styles["placeholder"]}>
                  Select English Level
                </span>
              </MenuItem>
              {englishLevels?.map((el) => (
                <MenuItem key={el.id} value={el.id}>
                  {el.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Grid>
        <Grid item md={12}>
          <Stack>
            <span
              className={styles["text-field-label"]}
              style={{ marginBottom: 15 }}
            >
              Job Description File
            </span>
            <div {...getRootProps()}>
              <input {...getInputProps()} accept="application/pdf" />
              <Stack className={styles["upload-field"]} alignItems="center">
                {!requisitionData.file ? (
                  <>
                    <IconUpload style={{ marginTop: 30 }} />
                    <p className={styles["upload-description"]}>
                      Drag your file here (PDF, DOCX), size should be
                      <br />
                      less than 5mb, or
                    </p>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      className={styles["btn-browse"]}
                    >
                      <span>Browse file</span>
                      <IconChevronRight style={{ marginLeft: 5 }} />
                    </Stack>
                  </>
                ) : (
                  <FileItem
                    name={requisitionData.file?.name}
                    description={
                      requisitionData.file
                        ? `${numeral(
                            requisitionData.file.size / 1000000
                          ).format("0[.]0")}mb`
                        : undefined
                    }
                    onRemoveFile={onRemoveFile}
                    src="local"
                  />
                )}
              </Stack>
            </div>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default memo(RequisitionKeyDates);
