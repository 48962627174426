const EventName = {
  ON_UPDATE_CANDIDATE: "UpdateCandidate",
  ON_DELETE_CANDIDATE: "DeleteCandidate",
  ON_HIRE_CANDIDATE: "HireCandidate",
  ON_ADD_COMMENT: "AddComment",
  ON_UPDATE_COMMENT: "EditComment",
  ON_DELETE_COMMENT: "DeleteComment",
  ON_RECEIVE_NOTIFICATION: "NewPushNotification",
};

export default EventName;
