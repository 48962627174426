import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { memo, useCallback } from "react";
import styles from "./index.module.scss";
import TimeSheetItem from "./TimeSheetItem";
import { ITimeSheet } from "models/TimeSheet";

interface ITimeSheetTable {
  timeSheets: ITimeSheet[];
}

const TimeSheetTable = ({ timeSheets }: ITimeSheetTable) => {
  const renderRow = useCallback(
    (bill: ITimeSheet) => <TimeSheetItem key={bill.id} item={bill} />,
    []
  );
  if (!timeSheets || timeSheets.length === 0) return null;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={styles.label}>Document</TableCell>
          <TableCell className={styles.label}>Date</TableCell>
          <TableCell className={styles.label}>Size</TableCell>
          <TableCell className={styles.label}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{timeSheets.map(renderRow)}</TableBody>
    </Table>
  );
};

export default memo(TimeSheetTable);
