import React, { CSSProperties, memo } from "react";

interface IIconUpload {
  fill?: string;
  style?: CSSProperties
}

const IconUpload = ({ fill = "var(--color-accent)", style }: IIconUpload) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2379 16.6274C10.4643 16.6274 9.81895 16.0362 9.7514 15.2655C9.61015 13.6542 9.57441 12.0358 9.64427 10.4205C9.55994 10.4148 9.47563 10.4089 9.39133 10.4028L7.90178 10.2942C6.94973 10.2248 6.41752 9.16324 6.93151 8.35886C8.0277 6.64335 10.0369 4.7233 11.6904 3.53556C12.1742 3.18802 12.8258 3.18802 13.3097 3.53556C14.9631 4.7233 16.9723 6.64335 18.0685 8.35886C18.5825 9.16324 18.0503 10.2248 17.0983 10.2942L15.6087 10.4028C15.5244 10.4089 15.4401 10.4148 15.3558 10.4205C15.4256 12.0358 15.3899 13.6542 15.2486 15.2655C15.1811 16.0362 14.5358 16.6274 13.7622 16.6274H11.2379ZM11.1815 9.76268C11.0678 11.5499 11.089 13.3432 11.245 15.1274H13.755C13.911 13.3432 13.9323 11.5499 13.8186 9.76268C13.8058 9.56232 13.8739 9.3652 14.0077 9.21546C14.1414 9.06572 14.3296 8.97585 14.5301 8.96597C14.8535 8.95004 15.1767 8.93031 15.4997 8.90676L16.5815 8.8279C15.6219 7.41461 14.4204 6.18036 13.0313 5.1825L12.5 4.80086L11.9687 5.1825C10.5796 6.18036 9.37813 7.41461 8.41858 8.82791L9.50038 8.90676C9.82337 8.93031 10.1466 8.95004 10.4699 8.96597C10.6704 8.97585 10.8586 9.06572 10.9924 9.21546C11.1261 9.3652 11.1942 9.56232 11.1815 9.76268Z"
        fill={fill}
      />
      <path
        d="M6.25 17.0002C6.25 16.5859 5.91421 16.2502 5.5 16.2502C5.08579 16.2502 4.75 16.5859 4.75 17.0002V19.0002C4.75 19.9667 5.5335 20.7502 6.5 20.7502H18.5C19.4665 20.7502 20.25 19.9667 20.25 19.0002V17.0002C20.25 16.5859 19.9142 16.2502 19.5 16.2502C19.0858 16.2502 18.75 16.5859 18.75 17.0002V19.0002C18.75 19.1382 18.6381 19.2502 18.5 19.2502H6.5C6.36193 19.2502 6.25 19.1382 6.25 19.0002V17.0002Z"
        fill={fill}
      />
    </svg>
  );
};

export default memo(IconUpload);
