import React, { memo } from "react";

interface IIconCancelStage {
  fill?: string;
}

const IconCancelStage = ({ fill = "#DF2020" }: IIconCancelStage) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill={fill} />
      <path
        d="M8.46447 15.5355L15.5355 8.46447"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M8.46447 8.46447L15.5355 15.5355"
        stroke="white"
        stroke-width="1.5"
        stroke-linecap="round"
      />
    </svg>
  );
};

export default memo(IconCancelStage);
