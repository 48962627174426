import { CircularProgress, Stack } from "@mui/material";
import React, { CSSProperties, memo } from "react";

interface ISpinner {
  size?: number;
  color?: string;
  style?: CSSProperties;
}

const Spinner = ({
  size,
  color = "var(--color-accent)",
  style = {},
}: ISpinner) => {
  return (
    <Stack
      className="inset-x-0 inset-y-0 absolute"
      style={{ color, ...style }}
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress color="inherit" size={size} />
    </Stack>
  );
};

export default memo(Spinner);
