import React, { memo, useCallback, useState } from "react";
import styles from "./index.module.scss";
import { Stack, TextField } from "@mui/material";
import { useToast } from "providers/ToastProvider";
import api from "api";
import { useNavigate } from "react-router-dom";

const Security = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [reNewPass, setReNewPass] = useState("");
  const onChangeOldPass = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOldPass(e.target.value);
    },
    []
  );
  const onChangeNewPass = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setNewPass(e.target.value);
    },
    []
  );
  const onChangeReNewPass = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setReNewPass(e.target.value);
    },
    []
  );
  const onChangePass = useCallback(async () => {
    if (!oldPass) {
      toast.error({ message: "Old Password cannot be empty" });
      return;
    }
    if (!newPass) {
      toast.error({ message: "New Password cannot be empty" });
      return;
    }
    if (!reNewPass) {
      toast.error({ message: "Re-new Password cannot be empty" });
      return;
    }
    if (newPass !== reNewPass) {
      toast.error({ message: "Incorrect new password" });
      return;
    }
    const res = await api.user.changePass({
      oldPassword: oldPass,
      newPassword: newPass,
    });
    if (res.statusCode === 200) {
      toast.success({ message: res.message || "" });
      setOldPass("");
      setNewPass("");
      setReNewPass("");
    } else {
      toast.error({ message: res.message || "Incorrect password" });
    }
  }, [newPass, oldPass, reNewPass, toast]);
  const onForgotClick = useCallback(async () => {
    navigate("/reset-password");
  }, [navigate]);
  return (
    <div className={`${styles.container} container`}>
      <h1 className="page-title">Security</h1>
      <h3 className={styles.title}>Change Password</h3>
      <Stack
        direction="row"
        alignItems="center"
        className={styles["field-item"]}
        width={{ md: "60%", sm: "100%" }}
      >
        <span className={styles.label}>Old Password</span>
        <TextField
          placeholder="Enter your old password"
          style={{ width: 340 }}
          value={oldPass}
          onChange={onChangeOldPass}
          type="password"
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        className={styles["field-item"]}
        width={{ md: "60%", sm: "100%" }}
      >
        <span className={styles.label}>New Password</span>
        <TextField
          placeholder="Enter your new password"
          style={{ width: 340 }}
          value={newPass}
          onChange={onChangeNewPass}
          type="password"
        />
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        className={styles["field-item"]}
        width={{ md: "60%", sm: "100%" }}
        style={{ borderBottom: "none" }}
      >
        <span className={styles.label}>Re-enter New Password</span>
        <TextField
          placeholder="Re-enter your new password"
          style={{ width: 340 }}
          value={reNewPass}
          onChange={onChangeReNewPass}
          type="password"
        />
      </Stack>
      <Stack
        width={{ md: "60%", sm: "100%" }}
        marginTop="10px"
        alignItems="flex-end"
      >
        <Stack width={340}>
          <Stack
            width={340}
            height={38}
            className={styles.cta}
            onClick={onChangePass}
          >
            <span>Change Password</span>
          </Stack>
          <Stack className={styles["forgot-pass"]} onClick={onForgotClick}>
            <span>Forgot Password ?</span>
          </Stack>
        </Stack>
      </Stack>
    </div>
  );
};

export default memo(Security);
