import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import api from "api";
import { AsyncKey } from "common/AppConfig";
import { setCookie } from "common/Cookie";
import GlobalVariable from "services/GlobalVariable";

export const logoutAction = createAction("user/logout");

export const loginAction = createAsyncThunk(
  "user/login",
  async (payload: { email: string; password: string }) => {
    const res = await api.user.login(payload);
    if (res.statusCode === 200) {
      GlobalVariable.sessionExpired = false;
      await setCookie(AsyncKey.accessTokenKey, res.data?.accessToken);
      await setCookie(AsyncKey.userIdKey, res.data?.user?.id);
    }
    return res;
  }
);

export const getUserAction = createAsyncThunk("user/me", async () => {
  const res = await api.user.me();
  return res;
});

export const getNotifications = createAsyncThunk(
  "user/get-notifications",
  async (payload: {page: number}) => {
    const res = await api.user.notificationList(payload);
    return res;
  }
);
