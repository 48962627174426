import imgEyeBall from "assets/images/img_eye_ball.png";
import sampleAvatar from "assets/images/sample_avatar.png";
import icNotificationAuthenticate from "assets/images/ic_notification_authenticate.svg";
import logoAuthenticate from "assets/images/logo_authen.png";

const images = {
  imgEyeBall,
  sampleAvatar,
  icNotificationAuthenticate,
  logoAuthenticate,
};

export default images;
