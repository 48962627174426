import AppConfig from "common/AppConfig";
import useAppDispatch from "hooks/useAppDispatch";
import useUser from "hooks/useUser";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { Socket, io } from "socket.io-client";
import EventName from "./EventName";
import { ICandidate } from "models/Candidate";
import { useToast } from "providers/ToastProvider";
import {
  deleteCandidate,
  hireCandidate,
  updateCandidate,
} from "actions/CandidateActions";
import { INotification, IUser } from "models/User";
import { IComment } from "models/Comment";
import { COMMENT_ACTIONS } from "reducers/CommentReducers";
import { USER_ACTIONS } from "reducers/UserReducers";

type SocketState = "connecting" | "connected" | "disconnected";

export interface ISocketContext {
  disconnect: () => void;
  initSocket: (user?: IUser, onConnected?: () => void) => void;
  socketState: SocketState;
}

export const SocketContext = createContext<ISocketContext>({
  disconnect: () => {},
  initSocket: () => {},
  socketState: "disconnected",
});

export function useSocket(): ISocketContext {
  return useContext(SocketContext);
}

interface ISocketProps {
  children?: ReactNode;
}

const SocketProvider = ({ children }: ISocketProps) => {
  const socket = useRef<Socket | null>(null);
  const toast = useToast();
  const user = useUser();
  const dispatch = useAppDispatch();
  const [socketState, setSocketState] = useState<SocketState>("disconnected");
  const removeListener = useCallback(() => {
    socket.current?.off(EventName.ON_DELETE_CANDIDATE);
    socket.current?.off(EventName.ON_UPDATE_CANDIDATE);
    socket.current?.off(EventName.ON_HIRE_CANDIDATE);
    socket.current?.off(EventName.ON_ADD_COMMENT);
    socket.current?.off(EventName.ON_UPDATE_COMMENT);
    socket.current?.off(EventName.ON_DELETE_CANDIDATE);
    socket.current?.off(EventName.ON_RECEIVE_NOTIFICATION);
    socket.current?.off("disconnect");
  }, []);
  const onUpdateCandidate = useCallback(
    (data: ICandidate) => {
      dispatch(updateCandidate(data));
    },
    [dispatch]
  );
  const onHireCandidate = useCallback(
    (data: ICandidate) => {
      dispatch(hireCandidate(data));
    },
    [dispatch]
  );
  const onDeleteCandidate = useCallback(
    (data: { id: string }) => {
      dispatch(deleteCandidate(data));
    },
    [dispatch]
  );
  const onAddComment = useCallback(
    (data: IComment) => {
      dispatch(COMMENT_ACTIONS.addComment(data));
    },
    [dispatch]
  );
  const onUpdateComment = useCallback(
    (data: IComment) => {
      dispatch(COMMENT_ACTIONS.updateComment(data));
    },
    [dispatch]
  );
  const onDeleteComment = useCallback(
    (data: any) => {
      dispatch(
        COMMENT_ACTIONS.deleteComment({
          candidateId: data.candidate,
          commentId: data.id,
        })
      );
    },
    [dispatch]
  );
  const onReceiveNotification = useCallback(
    (data: INotification) => {
      dispatch(USER_ACTIONS.onReceiveNotification(data));
    },
    [dispatch]
  );
  const listener = useCallback(() => {
    socket.current?.on(EventName.ON_UPDATE_CANDIDATE, onUpdateCandidate);
    socket.current?.on(EventName.ON_DELETE_CANDIDATE, onDeleteCandidate);
    socket.current?.on(EventName.ON_HIRE_CANDIDATE, onHireCandidate);
    socket.current?.on(EventName.ON_ADD_COMMENT, onAddComment);
    socket.current?.on(EventName.ON_UPDATE_COMMENT, onUpdateComment);
    socket.current?.on(EventName.ON_DELETE_COMMENT, onDeleteComment);
    socket.current?.on(
      EventName.ON_RECEIVE_NOTIFICATION,
      onReceiveNotification
    );
  }, [
    onAddComment,
    onDeleteCandidate,
    onDeleteComment,
    onHireCandidate,
    onReceiveNotification,
    onUpdateCandidate,
    onUpdateComment,
  ]);
  const initSocket = useCallback(
    async (user?: IUser, onConnected?: () => void) => {
      if (socket.current?.connected) return;
      setSocketState("connecting");
      socket.current = io(`${AppConfig.socketBaseUrl}`, {
        query: {
          clientId: user?.client?.id,
          userId: user?.id,
        },
        transports: ["websocket"],
        upgrade: false,
      });
      socket.current?.on("connect_error", (err) => {
        toast.error({ message: err.message });
        setSocketState("disconnected");
      });
      socket.current?.on("connect", () => {
        console.log("socket connected");
        onConnected?.();
        setSocketState("connected");
      });
      socket.current?.on("disconnect", (reason: string) => {
        setSocketState("disconnected");
        if (reason === "io server disconnect") {
          socket.current?.connect();
        }
      });
    },
    [toast]
  );
  useEffect(() => {
    if (socketState === "connected" && user.id) {
      removeListener();
      listener();
    }
  }, [listener, removeListener, socketState, user.id]);
  const disconnect = useCallback(() => {
    socket.current?.disconnect();
  }, []);
  return (
    <SocketContext.Provider
      value={{
        disconnect,
        initSocket,
        socketState,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export default SocketProvider;
