import React, { CSSProperties, memo } from "react";

interface IIconLogout {
  style?: CSSProperties;
}

const IconLogout = ({ style }: IIconLogout) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M9 13.6875C8.68934 13.6875 8.4375 13.9393 8.4375 14.25C8.4375 14.5607 8.68934 14.8125 9 14.8125H13.5C14.2249 14.8125 14.8125 14.2249 14.8125 13.5V4.5C14.8125 3.77513 14.2249 3.1875 13.5 3.1875H9C8.68934 3.1875 8.4375 3.43934 8.4375 3.75C8.4375 4.06066 8.68934 4.3125 9 4.3125L13.5 4.3125C13.6036 4.3125 13.6875 4.39645 13.6875 4.5L13.6875 13.5C13.6875 13.6036 13.6036 13.6875 13.5 13.6875H9Z"
        fill="white"
        fillOpacity="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8773 10.7739C11.3951 10.7739 11.8148 10.3542 11.8148 9.83641V8.15603C11.8148 7.63826 11.3951 7.21853 10.8773 7.21853H7.41786C7.41297 7.16346 7.40785 7.1084 7.4025 7.05336L7.36198 6.63645C7.30015 6.00037 6.6247 5.61943 6.04839 5.89563C4.82271 6.48303 3.71314 7.28684 2.77302 8.2684L2.69862 8.34608C2.35046 8.70959 2.35046 9.28284 2.69862 9.64635L2.77302 9.72403C3.71314 10.7056 4.82272 11.5094 6.04838 12.0968C6.6247 12.373 7.30015 11.9921 7.36198 11.356L7.4025 10.9391C7.40785 10.884 7.41297 10.829 7.41786 10.7739H10.8773ZM6.89633 9.64891C6.69656 9.64891 6.51994 9.75338 6.42006 9.91198C6.37142 9.98922 6.34098 10.0793 6.33492 10.1764C6.32132 10.3946 6.30393 10.6125 6.28277 10.8302L6.27101 10.9512C5.49398 10.5508 4.77237 10.0521 4.12378 9.46793C3.95552 9.31637 3.79216 9.15906 3.63403 8.99622C4.40169 8.20568 5.29239 7.54552 6.27101 7.04118L6.28277 7.16219C6.30393 7.3799 6.32132 7.59788 6.33492 7.81604C6.35341 8.11253 6.59927 8.34353 6.89633 8.34353H10.6898V9.64891H6.89633Z"
        fill="white"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default memo(IconLogout);
