import moment from "moment";

export const dateFromNow = (date: any) => {
  return moment(date).calendar(null, {
    sameDay: `[${moment(date).fromNow()}]`,
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    lastDay: "[Yesterday]",
    lastWeek: "[Last] dddd",
    sameElse: "DD/MM/YYYY",
  });
};
