import React, { CSSProperties, memo } from "react";

interface IIconAlias {
  style?: CSSProperties;
}

const IconAlias = ({ style }: IIconAlias) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 7.5C8.25 5.15279 10.1528 3.25 12.5 3.25C14.8472 3.25 16.75 5.15279 16.75 7.5C16.75 9.84721 14.8472 11.75 12.5 11.75C10.1528 11.75 8.25 9.84721 8.25 7.5ZM12.5 4.75C10.9812 4.75 9.75 5.98122 9.75 7.5C9.75 9.01878 10.9812 10.25 12.5 10.25C14.0188 10.25 15.25 9.01878 15.25 7.5C15.25 5.98122 14.0188 4.75 12.5 4.75Z"
        fill="#6C3399"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 14.75C7.25736 14.75 6.25 15.7574 6.25 17V18.1883C6.25 18.2064 6.26311 18.2218 6.28097 18.2247C10.3997 18.8972 14.6003 18.8972 18.719 18.2247C18.7369 18.2218 18.75 18.2064 18.75 18.1883V17C18.75 15.7574 17.7426 14.75 16.5 14.75H16.1591C16.1328 14.75 16.1066 14.7542 16.0815 14.7623L15.216 15.045C13.4512 15.6212 11.5488 15.6212 9.78398 15.045L8.91847 14.7623C8.89342 14.7542 8.86722 14.75 8.84087 14.75H8.5ZM4.75 17C4.75 14.9289 6.42893 13.25 8.5 13.25H8.84087C9.02536 13.25 9.20869 13.2792 9.38407 13.3364L10.2496 13.6191C11.7119 14.0965 13.2881 14.0965 14.7504 13.6191L15.6159 13.3364C15.7913 13.2792 15.9746 13.25 16.1591 13.25H16.5C18.5711 13.25 20.25 14.9289 20.25 17V18.1883C20.25 18.9415 19.7041 19.5837 18.9607 19.7051C14.6819 20.4037 10.3181 20.4037 6.03927 19.7051C5.29588 19.5837 4.75 18.9415 4.75 18.1883V17Z"
        fill="#6C3399"
      />
    </svg>
  );
};

export default memo(IconAlias);
