import { MenuItem, Stack, TextField } from "@mui/material";
import React, { memo, useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss";
import IconArrowSelect from "components/SVGs/IconArrowSelect";
import useAppSelector from "hooks/useAppSelector";
import RecruitFilterItem from "components/RecruitFilterItem";
import useCandidates from "hooks/useCandidates";
import useAppDispatch from "hooks/useAppDispatch";
import { getCandidates } from "reducers/RecruitReducers";
import CandidateTable from "components/CandidateTable";
import Spinner from "components/Spinner";
import { ICandidate } from "models/Candidate";
import ModalCandidate from "components/ModalCandidate";
import { ISortData, UserPermission } from "models/User";
import useUser from "hooks/useUser";
import { useLocation, useNavigate } from "react-router-dom";
import { getRequisitions } from "reducers/RequisitionReducers";
import api from "api";

const Recruit = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useUser();
  const [selectedCandidate, setSelectedCandidate] = useState<ICandidate | null>(
    null
  );
  const [selectedJobTitle, setSelectedJobTitle] = useState("");
  const filterList = useAppSelector((state) => state.recruit.filters);
  const loading = useAppSelector((state) => state.recruit.loading);
  const [page, setPage] = useState<null | number>(null);
  const [sortData, setSortData] = useState<ISortData | null>(null);
  const selectedFilter = useAppSelector(
    (state) => state.recruit.selectedFilter
  );
  const candidates = useCandidates();
  const totalPage = useAppSelector((state) => state.recruit.totalPage);
  const requisitions = useAppSelector((state) => state.requisition.data);
  useEffect(() => {
    if (!user.permissions.includes(UserPermission.Recruit)) {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.permissions]);
  useEffect(() => {
    if (location.hash) {
      const candidateId = location.hash.replace("#", "");
      if (!candidates?.find((el) => el.id === candidateId)) {
        api.candidate
          .find({ candidateId })
          .then((res) => {
            setPage(res?.foundPage || 1);
          })
          .catch((err) => {
            setPage(1);
          });
      }
    } else {
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);
  useEffect(() => {
    if (page) {
      dispatch(
        getCandidates({
          selectedFilter,
          selectedJobTitle,
          sortName: sortData?.title,
          sortOrder: sortData?.type,
          page,
        })
      );
    }
  }, [
    dispatch,
    page,
    selectedFilter,
    selectedJobTitle,
    sortData?.title,
    sortData?.type,
  ]);
  const onCandidateClick = useCallback((candidate: ICandidate) => {
    setSelectedCandidate(candidate);
  }, []);
  const onSortClick = useCallback((title: string) => {
    setSortData((current) => {
      return {
        title,
        type:
          current?.title !== title
            ? "ASC"
            : current.type === "ASC"
            ? "DESC"
            : "ASC",
      };
    });
  }, []);
  const handleCloseModalCandidate = useCallback(
    () => setSelectedCandidate(null),
    []
  );
  const onChangeJobTitle = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedJobTitle(e.target.value);
    },
    []
  );
  const onOpenSelect = useCallback(() => {
    dispatch(getRequisitions());
  }, [dispatch]);
  const onPageChange = useCallback((e: any, page: number) => {
    setPage(page);
  }, []);
  useEffect(() => {
    if (selectedJobTitle || selectedFilter) {
      setPage(1);
    }
  }, [selectedFilter, selectedJobTitle]);
  return (
    <div className={`${styles.container} container relative`}>
      <h1 className="page-title">Recruit</h1>
      <Stack>
        <span
          className={styles["text-field-label"]}
          style={{ marginBottom: 15 }}
        >
          Sort
        </span>
        <TextField
          select
          value={selectedJobTitle}
          onChange={onChangeJobTitle}
          SelectProps={{
            IconComponent: (props) => (
              <IconArrowSelect {...props} style={{ right: 13 }} />
            ),
            displayEmpty: true,
            onOpen: onOpenSelect,
          }}
          style={{ width: 340 }}
        >
          <MenuItem value="" style={{ display: "none" }}>
            <span className={styles["placeholder"]}>Job Title</span>
          </MenuItem>
          <MenuItem value="All">All</MenuItem>
          {requisitions.map((el) => (
            <MenuItem key={el.id} value={el.id}>
              {el.jobTitle}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
      <Stack direction="row" marginTop="27px">
        {filterList?.map((el) => (
          <RecruitFilterItem
            key={el.name}
            label={el.name}
            active={el.id === selectedFilter}
            id={el.id}
          />
        ))}
      </Stack>
      {loading && (
        <Spinner style={{ backgroundColor: "white", marginTop: 316 }} />
      )}
      <CandidateTable
        candidates={candidates}
        onCandidateClick={onCandidateClick}
        onSortClick={onSortClick}
        sortData={sortData}
        onPageChange={onPageChange}
        totalPage={totalPage}
        page={page || 1}
      />
      <ModalCandidate
        open={!!selectedCandidate}
        onClose={handleCloseModalCandidate}
        candidate={selectedCandidate}
      />
    </div>
  );
};

export default memo(Recruit);
