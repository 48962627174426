import {
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { ICandidate } from "models/Candidate";
import React, { memo, useCallback } from "react";
import styles from "./index.module.scss";
import IconArrowSelect from "components/SVGs/IconArrowSelect";
import ManageItem from "components/ManageItem";
import { ISortData } from "models/User";

interface IManageTable {
  candidates?: ICandidate[];
  onCandidateClick: (candidate: ICandidate) => void;
  onSortClick: (title: string) => void;
  sortData?: ISortData | null;
  onPageChange: (e: any, page: number) => void;
  totalPage: number;
}

const ManageTable = ({
  candidates,
  onCandidateClick,
  onSortClick,
  sortData,
  onPageChange,
  totalPage,
}: IManageTable) => {
  const renderRow = useCallback(
    (candidate: ICandidate) => (
      <ManageItem
        key={candidate.id}
        candidate={candidate}
        onCandidateClick={onCandidateClick}
      />
    ),
    [onCandidateClick]
  );
  const handleSortStaff = useCallback(() => {
    onSortClick("staff");
  }, [onSortClick]);
  const handleSortJobFamily = useCallback(() => {
    onSortClick("jobFamily");
  }, [onSortClick]);
  const handleSortJoinDate = useCallback(() => {
    onSortClick("joinDate");
  }, [onSortClick]);
  const getRotateValue = useCallback(
    (title: string) => {
      return sortData?.title === title && sortData?.type === "ASC"
        ? "180deg"
        : "0deg";
    },
    [sortData?.title, sortData?.type]
  );
  if (!candidates || candidates.length === 0) return null;
  return (
    <Stack flex={1}>
      <Stack style={{ marginTop: 25, flex: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={styles.label}>
                <Stack
                  direction="row"
                  alignItems="center"
                  className="cursor-pointer"
                  onClick={handleSortStaff}
                >
                  <span>Staff</span>
                  <IconArrowSelect
                    style={{ marginLeft: 10, rotate: getRotateValue("staff") }}
                  />
                </Stack>
              </TableCell>
              <TableCell className={styles.label}>Manager</TableCell>
              <TableCell className={styles.label}>
                <Stack
                  direction="row"
                  alignItems="center"
                  className="cursor-pointer"
                  onClick={handleSortJobFamily}
                >
                  <span>Job Family</span>
                  <IconArrowSelect
                    style={{
                      marginLeft: 10,
                      rotate: getRotateValue("jobFamily"),
                    }}
                  />
                </Stack>
              </TableCell>
              <TableCell className={styles.label}>
                <Stack
                  direction="row"
                  alignItems="center"
                  className="cursor-pointer"
                  onClick={handleSortJoinDate}
                >
                  <span>Join Date</span>
                  <IconArrowSelect
                    style={{
                      marginLeft: 10,
                      rotate: getRotateValue("joinDate"),
                    }}
                  />
                </Stack>
              </TableCell>
              <TableCell className={styles.label}>Next Key Date</TableCell>
              <TableCell className={styles.label}>Actions Needed</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{candidates.map(renderRow)}</TableBody>
        </Table>
      </Stack>
      {totalPage > 1 && (
        <Pagination
          count={totalPage}
          shape="rounded"
          style={{ margin: "25px 0", alignSelf: "flex-end" }}
          onChange={onPageChange}
        />
      )}
    </Stack>
  );
};

export default memo(ManageTable);
