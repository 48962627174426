import { LoadingButton } from "@mui/lab";
import { Box, Stack, TextField } from "@mui/material";
import api from "api";
import { useToast } from "providers/ToastProvider";
import React, { memo, useCallback, useState } from "react";
import styles from "./index.module.scss";
import images from "common/images";

const ResetPassword = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const email = data.get("email")?.toString() || "";
      if (email) {
        setLoading(true);
        const res = await api.user.resetPass({ email });
        if (res.statusCode === 200) {
          toast.success({
            message: res.message || "Reset password email sent!",
          });
        } else {
          toast.error({ message: res.message || "Something wrong" });
        }
        setLoading(false);
      }
    },
    [toast]
  );
  return (
    <main className={styles.container}>
      <Stack className={styles.body}>
        <img src={images.logoAuthenticate} className={styles.logo} alt="logo" />
        <h1 className={styles.title}>Forgot Password</h1>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: "30px" }}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <span className={styles.label}>Email</span>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              placeholder="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
          </Stack>
          <Stack alignSelf="flex-end">
            <LoadingButton
              type="submit"
              variant="contained"
              loading={loading}
              className={styles.button}
            >
              Reset password
            </LoadingButton>
          </Stack>
        </Box>
      </Stack>
    </main>
  );
};

export default memo(ResetPassword);
