import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { memo, useCallback } from "react";
import styles from "./index.module.scss";
import { IBill } from "models/Bill";
import BillingItem from "./BillingItem";

interface IBillingTable {
  billings: IBill[];
}

const BillingTable = ({ billings }: IBillingTable) => {
  const renderRow = useCallback(
    (bill: IBill) => <BillingItem key={bill.id} item={bill} />,
    []
  );
  if (!billings || billings.length === 0) return null;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={styles.label}>Document</TableCell>
          <TableCell className={styles.label}>Document ID</TableCell>
          <TableCell className={styles.label}>Document type</TableCell>
          <TableCell className={styles.label}>Billed amount</TableCell>
          <TableCell className={styles.label}></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{billings.map(renderRow)}</TableBody>
    </Table>
  );
};

export default memo(BillingTable);
