import React, { memo } from "react";

interface IIconNextStage {
  fill?: string;
}

const IconNextStage = ({ fill = "#0B9607" }: IIconNextStage) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill={fill} />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.6956 7.28158C18.0924 7.66576 18.1026 8.29884 17.7184 8.69561L10.0041 16.6956C9.8111 16.895 9.54388 17.0052 9.26642 16.9998C8.98895 16.9945 8.72619 16.874 8.54099 16.6674L6.25527 14.0007C5.8867 13.5894 5.92134 12.9572 6.33265 12.5886C6.74396 12.22 7.37617 12.2547 7.74474 12.666L9.31405 14.5332L16.2816 7.30439C16.6658 6.90762 17.2988 6.89741 17.6956 7.28158Z"
        fill="white"
      />
    </svg>
  );
};

export default memo(IconNextStage);
