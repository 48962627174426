import api from "api";
import AppConfig, { AsyncKey } from "common/AppConfig";
import { clearData, getCookie, setCookie } from "common/Cookie";
import ModalConfirm from "components/ModalConfirm";
import useAppDispatch from "hooks/useAppDispatch";
import { useSocket } from "providers/SocketProvider";
import { useToast } from "providers/ToastProvider";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getDashboardData, getHolidays } from "reducers/DashboardReducers";
import { getManageStatus } from "reducers/ManageReducers";
import { getMetadata } from "reducers/MetadataReducers";
import { getRecruitStatus } from "reducers/RecruitReducers";
import { getRequisitions } from "reducers/RequisitionReducers";
import {
  getNotifications,
  getUserAction,
  loginAction,
  logoutAction,
} from "reducers/UserActions";
import { useMixpanel } from "react-mixpanel-browser";
import useUser from "hooks/useUser";

export interface IAuthContext {
  login: (req: { email: string; password: string }) => Promise<void>;
  logout: () => void;
  loadingLogin: boolean;
}

export const AuthContext = createContext<IAuthContext>({
  login: async () => {},
  logout: () => {},
  loadingLogin: false,
});

export function useAuth(): IAuthContext {
  return useContext(AuthContext);
}

interface IAuthProps {
  children?: ReactNode;
}

const AuthProvider = ({ children }: IAuthProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const mixpanel = useMixpanel();
  const location = useLocation();
  const user = useUser();
  const socket = useSocket();
  const [loading, setLoading] = useState(true);
  const [loadingLogin, setLoadingLogin] = useState(false);
  const [openConfirmLogout, setOpenConfirmLogout] = useState(false);
  const loginPath = useMemo(() => AppConfig.loginPath, []);
  const toggleConfirmLogout = useCallback(() => {
    setOpenConfirmLogout((current) => !current);
  }, []);
  const initialUserData = useCallback(async () => {
    const res = await dispatch(getUserAction()).unwrap();
    api.user.updateDeviceToken();
    dispatch(getMetadata());
    dispatch(getRecruitStatus());
    dispatch(getManageStatus());
    dispatch(getRequisitions());
    dispatch(getHolidays());
    dispatch(getDashboardData({}));
    dispatch(getNotifications({ page: 1 }));
    socket.initSocket(res.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);
  const handleRefreshToken = useCallback(async () => {
    const res = await api.user.refreshToken();
    if (res.data?.accessToken) {
      await setCookie(AsyncKey.accessTokenKey, res.data.accessToken);
    }
  }, []);
  const isPublicRoute = useMemo(
    () => window.location.pathname.includes("/reset-password"),
    []
  );
  const checkingAuth = useCallback(async () => {
    setLoading(true);
    await handleRefreshToken();
    const accessToken = await getCookie(AsyncKey.accessTokenKey);
    if (!accessToken) {
      if (window.location.pathname !== AppConfig.loginPath && !isPublicRoute) {
        dispatch(logoutAction());
        navigate(loginPath, { replace: true, state: { from: location } });
      }
    } else {
      await initialUserData();
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialUserData]);
  useEffect(() => {
    checkingAuth();
  }, [checkingAuth]);
  useEffect(() => {
    if (user.id) {
      mixpanel.identify(user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.id]);
  const login = useCallback(
    async (req: { email: string; password: string }) => {
      setLoadingLogin(true);
      const res = await dispatch(loginAction(req)).unwrap();
      if (res.statusCode === 200) {
        if (res.data?.user?.role === "Super Admin") {
          toast.error({ message: "Invalid user" });
          setLoadingLogin(false);
          return;
        }
        await initialUserData();
        let path = "/";
        if (
          location.state?.from?.pathname &&
          !location.state?.from?.pathname?.includes("/reset-password")
        ) {
          path =
            location.state?.from?.pathname +
            (location.state?.from?.search || "");
        }
        navigate(path, { replace: true });
      } else {
        toast.error({ message: res.message || "" });
      }
      setLoadingLogin(false);
    },
    [
      dispatch,
      initialUserData,
      location.state?.from?.pathname,
      location.state?.from?.search,
      navigate,
      toast,
    ]
  );
  const handleLogout = useCallback(() => {
    api.user.logout();
    clearData(() => {
      toggleConfirmLogout();
      dispatch(logoutAction);
      navigate(loginPath, { replace: true });
    });
  }, [dispatch, loginPath, navigate, toggleConfirmLogout]);
  return (
    <AuthContext.Provider
      value={{
        login,
        logout: toggleConfirmLogout,
        loadingLogin,
      }}
    >
      {loading && <div />}
      {!loading && children}
      <ModalConfirm
        open={openConfirmLogout}
        onClose={toggleConfirmLogout}
        title="Alert"
        description="Are you sure you want to logout?"
        onConfirm={handleLogout}
      />
    </AuthContext.Provider>
  );
};

export default AuthProvider;
