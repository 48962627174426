import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { memo, useCallback } from "react";
import styles from "./index.module.scss";
import IconArrowSelect from "components/SVGs/IconArrowSelect";
import { ISortData, IUser } from "models/User";
import UserItem from "./UserItem";

interface IUpdateUserTable {
  users?: IUser[];
  onSortClick: (title: string) => void;
  sortData?: ISortData | null;
  updateData: { id: string; email: string; role: string }[];
  onUpdate: React.Dispatch<
    React.SetStateAction<
      {
        id: string;
        email: string;
        role: string;
      }[]
    >
  >;
}

const UpdateUserTable = ({
  users,
  onSortClick,
  sortData,
  updateData,
  onUpdate,
}: IUpdateUserTable) => {
  const getRotateValue = useCallback(
    (title: string) => {
      return sortData?.title === title && sortData?.type === "ASC"
        ? "180deg"
        : "0deg";
    },
    [sortData?.title, sortData?.type]
  );
  const handleSortRole = useCallback(() => {
    onSortClick("role");
  }, [onSortClick]);
  const onUpdateRole = useCallback(
    (user: IUser, role: string) => {
      onUpdate((current) => {
        const isExisted = !!current.find((el) => el.id === user.id);
        if (isExisted) {
          return current.map((el) => {
            if (el.id === user.id) {
              return {
                ...el,
                role,
              };
            }
            return el;
          });
        }
        return [...(current || []), { id: user.id, role, email: user.email }];
      });
    },
    [onUpdate]
  );
  const onUpdateEmail = useCallback(
    (user: IUser, email: string) => {
      onUpdate((current) => {
        const isExisted = !!current.find((el) => el.id === user.id);
        if (isExisted) {
          return current.map((el) => {
            if (el.id === user.id) {
              return {
                ...el,
                email,
              };
            }
            return el;
          });
        }
        return [...(current || []), { id: user.id, role: user.role, email }];
      });
    },
    [onUpdate]
  );
  const renderRow = useCallback(
    (item: IUser) => (
      <UserItem
        key={item.id}
        user={item}
        updatedData={updateData.find((el) => el.id === item.id)}
        onUpdateRole={onUpdateRole}
        onUpdateEmail={onUpdateEmail}
      />
    ),
    [onUpdateEmail, onUpdateRole, updateData]
  );
  if (!users || users.length === 0) return null;
  return (
    <>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={styles.label}>User</TableCell>
            <TableCell className={styles.label}>
              <Stack
                direction="row"
                alignItems="center"
                className="cursor-pointer"
                onClick={handleSortRole}
              >
                <span>Role</span>
                <IconArrowSelect
                  style={{
                    marginLeft: 10,
                    rotate: getRotateValue("role"),
                  }}
                />
              </Stack>
            </TableCell>
            <TableCell className={styles.label}>Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{users.map(renderRow)}</TableBody>
      </Table>
    </>
  );
};

export default memo(UpdateUserTable);
