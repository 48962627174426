import { Alert, AlertColor, Snackbar } from "@mui/material";
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { useLocation } from "react-router-dom";

interface SnackBarOption {
  type?: AlertColor;
  open?: boolean;
  message: string;
}

export interface IToastContext {
  success: (option: SnackBarOption) => void;
  error: (option: SnackBarOption) => void;
}

export const ToastContext = createContext<IToastContext>({
  success: () => {},
  error: () => {},
});

export function useToast(): IToastContext {
  return useContext(ToastContext);
}

interface IToastProps {
  children?: ReactNode;
}

const ToastProvider = ({ children }: IToastProps) => {
  const location = useLocation();
  const [state, setState] = useState<SnackBarOption>({
    open: false,
    message: "",
  });
  const success = useCallback((option: SnackBarOption) => {
    setState({
      message: option.message,
      type: "success",
      open: true,
    });
  }, []);
  const error = useCallback((option: SnackBarOption) => {
    setState({
      message: option.message,
      type: "error",
      open: true,
    });
  }, []);
  const close = useCallback(() => {
    setState({
      open: false,
      message: "",
    });
  }, []);
  const isPublicRoute = useMemo(
    () =>
      location.pathname.includes("/reset-password") ||
      location.pathname.includes("/login"),
    [location.pathname]
  );
  return (
    <ToastContext.Provider
      value={{
        success,
        error,
      }}
    >
      {children}
      <Snackbar
        open={state.open}
        autoHideDuration={4000}
        onClose={close}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        style={{ marginLeft: isPublicRoute ? 0 : 125 }}
      >
        <Alert severity={state.type}>{state.message}</Alert>
      </Snackbar>
    </ToastContext.Provider>
  );
};

export default ToastProvider;
