import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "./UserActions";
import api from "api";
import { ITimeSheet } from "models/TimeSheet";
import AppConfig from "common/AppConfig";

interface TimeSheetState {
  timeSheets: ITimeSheet[];
  loading?: boolean;
  totalPage: number;
}

const initialState: TimeSheetState = {
  timeSheets: [],
  loading: false,
  totalPage: 1,
};

export const getTimeSheets = createAsyncThunk(
  "time-sheet/list",
  async (payload: { page?: number }) => {
    const res = await api.timeSheet.list(payload);
    return res;
  }
);

const timeSheetSlice = createSlice({
  name: "time-sheet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logoutAction, (state) => {
        return initialState;
      })
      .addCase(getTimeSheets.fulfilled, (state, action) => {
        state.timeSheets = action.payload.data || [];
        state.loading = false;
        state.totalPage = Math.ceil(
          (action.payload.total || 0) / AppConfig.perPage
        );
      })
      .addCase(getTimeSheets.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getTimeSheets.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const TIME_SHEET_ACTIONS = timeSheetSlice.actions;

export default timeSheetSlice.reducer;
