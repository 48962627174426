import React, { memo, CSSProperties } from "react";

interface IIconCheck {
  style?: CSSProperties;
}

const IconCheck = ({ style }: IIconCheck) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0253 6.6414C15.2694 6.88548 15.2694 7.28121 15.0253 7.52529L9.19196 13.3586C8.94788 13.6027 8.55216 13.6027 8.30808 13.3586L4.97475 10.0253C4.73067 9.78121 4.73067 9.38548 4.97475 9.1414C5.21882 8.89732 5.61455 8.89732 5.85863 9.1414L8.75002 12.0328L14.1414 6.6414C14.3855 6.39732 14.7812 6.39732 15.0253 6.6414Z"
        fill="#6C3399"
      />
    </svg>
  );
};

export default memo(IconCheck);
