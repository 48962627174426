import { CircularProgress, Stack, TableCell, TableRow } from "@mui/material";
import React, { memo, useCallback, useMemo, useState } from "react";
import moment from "moment";
import images from "common/images";
import styles from "./index.module.scss";
import IconChevronRight from "components/SVGs/IconChevronRight";
import { IKeyDate } from "models/Dashboard";
import api from "api";
import useAppDispatch from "hooks/useAppDispatch";
import { getDashboardData } from "reducers/DashboardReducers";
import useUser from "hooks/useUser";

interface IKeyDatePersonItem {
  keyDate: IKeyDate;
  onUserClick: (keyDate: IKeyDate) => void;
}

const KeyDatePersonItem = ({ keyDate, onUserClick }: IKeyDatePersonItem) => {
  const dispatch = useAppDispatch();
  const [nextLoading, setNextLoading] = useState(false);
  const user = useUser();
  const jobTitle = useMemo(() => {
    return `${keyDate.jobLevel?.abbr} ${keyDate.jobFamily?.name}`;
  }, [keyDate.jobFamily, keyDate.jobLevel?.abbr]);
  const handleUserClick = useCallback(() => {
    onUserClick(keyDate);
  }, [keyDate, onUserClick]);
  const onGoToNextClick = useCallback(async () => {
    if (nextLoading || !keyDate.client || !keyDate._id) return;
    setNextLoading(true);
    await api.candidate.goToNextStep({
      candidateId: keyDate._id,
      clientId: keyDate.client,
    });
    setNextLoading(false);
    dispatch(getDashboardData({}));
  }, [dispatch, keyDate._id, keyDate.client, nextLoading]);
  return (
    <TableRow>
      <TableCell style={{ padding: "15px 0" }}>
        <Stack
          direction="row"
          alignItems="center"
          className="cursor-pointer"
          onClick={handleUserClick}
        >
          <img
            alt="avatar"
            src={
              keyDate?.avatar?.src ||
              user.client?.logo?.src ||
              images.sampleAvatar
            }
            className={styles.avatar}
          />
          <Stack marginLeft="10px">
            <span className={styles.name}>{keyDate.name}</span>
            <span
              className={styles.description}
              style={{ fontSize: 13, lineHeight: "18px" }}
            >
              {jobTitle}
            </span>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell
        className={styles.date}
        style={{ color: "var(--color-accent)", padding: "15px 0" }}
      >
        {moment(keyDate.nextStepDate).format("MMM DD")}
      </TableCell>
      <TableCell style={{ padding: "15px 0" }} width={191}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          height={38}
          style={{
            backgroundColor: "var(--color-accent)",
            borderRadius: 14,
            color: "white",
            minWidth: 191,
            maxWidth: 250,
            width: "fit-content",
            padding: "0 10px",
          }}
          className="cursor-pointer"
          onClick={onGoToNextClick}
        >
          <span className={`${styles.action} truncate`}>
            {keyDate.nextStep?.name}
          </span>
          {nextLoading ? (
            <CircularProgress
              size={20}
              color="inherit"
              style={{ marginLeft: 5 }}
            />
          ) : (
            <IconChevronRight style={{ marginLeft: 5 }} />
          )}
        </Stack>
      </TableCell>
    </TableRow>
  );
};

export default memo(KeyDatePersonItem);
