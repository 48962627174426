import { Dialog, IconButton, Stack } from "@mui/material";
import React, { memo, useMemo } from "react";
import styles from "./index.module.scss";
import IconClose from "components/SVGs/IconClose";
import { IKeyDate } from "models/Dashboard";
import images from "common/images";
import IconAlias from "components/SVGs/IconAlias";
import IconJob from "components/SVGs/IconJob";
import FileItem from "components/FileItem";
import IconPalette from "components/SVGs/IconPalette";
import moment from "moment";
import IconHelp from "components/SVGs/IconHelp";
import IconChevronRight from "components/SVGs/IconChevronRight";
import useUser from "hooks/useUser";

interface IInformationItem {
  label: string;
  value?: string;
}

const InformationItem = memo(({ label, value }: IInformationItem) => {
  return (
    <Stack direction="row" alignItems="center" marginBottom="20px">
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>{value}</span>
    </Stack>
  );
});

interface IModalDashboardPersonKeyDate {
  open: boolean;
  onClose: () => void;
  keyDate: IKeyDate | null;
}

const ModalDashboardPersonKeyDate = ({
  open,
  onClose,
  keyDate,
}: IModalDashboardPersonKeyDate) => {
  const user = useUser();
  const gender = useMemo(() => {
    if (keyDate?.gender?.name === "M") return "Male";
    if (keyDate?.gender?.name === "F") return "Female";
    return keyDate?.gender?.name;
  }, [keyDate?.gender?.name]);
  if (!keyDate) return null;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "20px" } }}
    >
      <Stack className={styles.container} direction="row">
        <IconButton className={styles["btn-close"]} onClick={onClose}>
          <IconClose />
        </IconButton>
        <Stack flex={1} alignItems="center">
          <img
            src={
              keyDate.avatar?.src ||
              user.client?.logo?.src ||
              images.sampleAvatar
            }
            alt="avatar"
            className={styles.avatar}
          />
          <span className={styles.name}>{keyDate.name}</span>
          <Stack marginTop="10px" minWidth={170}>
            <Stack direction="row" alignItems="center">
              <IconAlias style={{ marginRight: 5 }} />
              <span className={styles["sub-text"]}>
                Goes by :{" "}
                <span style={{ color: "var(--color-main)" }}>
                  {keyDate.alias}
                </span>
              </span>
            </Stack>
            <Stack direction="row" alignItems="center" marginTop="10px">
              <IconJob style={{ marginRight: 5 }} />
              <span className={styles["sub-text"]}>
                {keyDate.jobFamily?.name}
              </span>
            </Stack>
          </Stack>
          <Stack width={220} className={styles.file}>
            <FileItem
              style={{ width: "100%", height: 50, marginTop: 0, marginLeft: 0 }}
            />
            <FileItem
              style={{
                width: "100%",
                height: 50,
                marginTop: 15,
                marginLeft: 0,
              }}
              suffixIcon={<IconPalette />}
            />
          </Stack>
        </Stack>
        <Stack flex={1} paddingTop="50px">
          <InformationItem label="Date of Birth: " value="" />
          <InformationItem label="Gender: " value={gender} />
          <InformationItem label="Seniority: " value="Intermediate" />
          <InformationItem label="English: " value={keyDate.english?.name} />
          {keyDate?.rate && (
            <InformationItem
              label="Rate: "
              value={`${keyDate.currency?.symbol || ""}${keyDate?.rate}`}
            />
          )}
          <InformationItem
            label="Available From: "
            value={moment(keyDate.availableFrom).format("DD/MM/YYYY")}
          />
          <Stack direction="row" alignItems="center" marginBottom="20px">
            <span className={styles.label}>Status: </span>
            <Stack
              className={styles.status}
              direction="row"
              alignItems="center"
            >
              <span>{keyDate.nextStep?.actionButton}</span>
              <IconHelp style={{ marginLeft: 5 }} fill="white" />
            </Stack>
          </Stack>
          <Stack direction="row" alignItems="center" marginBottom="20px">
            <span className={styles.label}>Skill Test: </span>
            <Stack
              direction="row"
              alignItems="center"
              flex={1}
              justifyContent="flex-end"
            >
              <span className={styles.progress}>79%</span>
              <Stack
                className={styles.detail}
                direction="row"
                alignItems="center"
              >
                <span>Details</span>
                <IconChevronRight style={{ marginLeft: 5 }} />
              </Stack>
            </Stack>
          </Stack>
          <span className={styles.note}>Notes:</span>
          <span className={styles.note} style={{ marginTop: 10 }}>
            Has 4 Years GoLang experience. Friendly personality
          </span>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default memo(ModalDashboardPersonKeyDate);
