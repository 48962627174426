import React, { memo, useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { IITAsset } from "models/User";
import api from "api";
import Spinner from "components/Spinner";
import ITAssetTable from "components/ITAssetTable";
import { Pagination, Stack } from "@mui/material";
import AppConfig from "common/AppConfig";

const ITAssets = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<IITAsset[]>([]);
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  useEffect(() => {
    setLoading(true);
    api.user
      .getITAssets({ page })
      .then((res) => {
        setData(res?.data || []);
        setTotalPage(Math.ceil((res?.total || 0) / AppConfig.perPage));
      })
      .finally(() => setLoading(false));
  }, [page]);
  const onPageChange = useCallback((e: any, page: number) => {
    setPage(page);
  }, []);
  return (
    <div className={`${styles.container} container`}>
      <h1 className="page-title">IT Assets</h1>
      <Stack style={{ flex: 1, marginTop: 25 }}>
        {loading ? <Spinner /> : <ITAssetTable data={data} />}
      </Stack>
      {totalPage > 1 && (
        <Pagination
          count={totalPage}
          shape="rounded"
          style={{ margin: "25px 0", alignSelf: "flex-end" }}
          onChange={onPageChange}
        />
      )}
    </div>
  );
};

export default memo(ITAssets);
