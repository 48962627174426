import { useMemo } from "react";
import useUser from "./useUser";

function useUserRoles() {
  const user = useUser();
  return useMemo(() => {
    const roles = [
      { label: "Admin", value: "Admin" },
      { label: "Hiring Director", value: "Hiring Director" },
      { label: "Hiring Manager", value: "Hiring Manager" },
      { label: "Finance", value: "Finance" },
    ];
    if (user.role === "Owner") {
      roles.unshift({ label: "Owner", value: "Owner" });
    }
    return roles;
  }, [user.role]);
}

export default useUserRoles;
