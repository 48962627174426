import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import { StyledEngineProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import React, { useEffect, useMemo } from "react";
import { Provider } from "react-redux";
import store from "store";
import AuthProvider from "./AuthProvider";
import moment from "moment";
import ToastProvider from "./ToastProvider";
import SocketProvider from "./SocketProvider";
import { MixpanelProvider } from "react-mixpanel-browser";

type ProvidersProps = {
  children: React.ReactNode;
};

const Providers = ({ children }: ProvidersProps) => {
  useEffect(() => {
    moment.updateLocale("en", {
      week: {
        dow: 1,
      },
    });
  }, []);
  const materialTheme = useMemo(
    () =>
      createTheme({
        typography: {},
      }),
    []
  );
  return (
    <Provider store={store}>
      <MixpanelProvider config={{track_pageview: true}} token={process.env.REACT_APP_MIXPANEL_TOKEN}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={materialTheme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <ToastProvider>
                <SocketProvider>
                  <AuthProvider>{children}</AuthProvider>
                </SocketProvider>
              </ToastProvider>
            </LocalizationProvider>
            <CssBaseline />
          </ThemeProvider>
        </StyledEngineProvider>
      </MixpanelProvider>
    </Provider>
  );
};

export default Providers;
