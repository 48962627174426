import { Grid, Stack } from "@mui/material";
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import styles from "./index.module.scss";
import TeamDistribution from "components/Charts/TeamDistribution";
import TeamSize from "components/Charts/TeamSize";
import DashboardKeyDates from "components/Charts/DashboardKeyDates";
import DashboardCalendar from "components/Charts/DashboardCalendar";
import RecruitmentFunnel from "components/Charts/RecruitmentFunnel";
import useAppSelector from "hooks/useAppSelector";
import Spinner from "components/Spinner";
import useDashboardRecruitment from "hooks/useDashboardRecruitment";
import useDashboardDistribution from "hooks/useDashboardDistribution";
import useDashboardKeyDates from "hooks/useDashboardKeyDates";
import { IKeyDate } from "models/Dashboard";
import ModalDashboardPersonKeyDate from "components/ModalDashboardPersonKeyDate";
import PopoverButton, { PopoverItem } from "components/PopoverButton";
import useJobFamilies from "hooks/useJobFamilies";
import useAppDispatch from "hooks/useAppDispatch";
import { getDashboardData } from "reducers/DashboardReducers";
import { useNavigate, useSearchParams } from "react-router-dom";
import ModalCandidate from "components/ModalCandidate";
import { ICandidate } from "models/Candidate";
import api from "api";
import useUser from "hooks/useUser";
import { UserPermission } from "models/User";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useUser();
  const loading = useAppSelector((state) => state.dashboard.loading);
  const [searchParams, setSearchParams] = useSearchParams();
  const recruitment = useDashboardRecruitment();
  const distribution = useDashboardDistribution();
  const keyDates = useDashboardKeyDates();
  const popupFilterJobFamilyRef = useRef<any>();
  const jobFamilies = useJobFamilies();
  const [selectedRole, setSelectedRole] = useState("All roles");
  const [selectedCandidate, setSelectedCandidate] = useState<ICandidate | null>(
    null
  );
  const [selectedPersonKeyDate, setSelectedPersonKeyDate] =
    useState<IKeyDate | null>(null);
  const handleCloseModalCandidate = useCallback(
    () => setSelectedCandidate(null),
    []
  );
  const handleSelectedMenu = useCallback(
    (data: PopoverItem) => {
      popupFilterJobFamilyRef.current.hide();
      setSelectedRole(data.label);
      dispatch(getDashboardData({ jobFamily: data.value }));
    },
    [dispatch]
  );
  const onUserClick = useCallback((keyDate: IKeyDate) => {
    setSelectedPersonKeyDate(keyDate);
  }, []);
  const onCloseModalPersonKeyDate = useCallback(() => {
    setSelectedPersonKeyDate(null);
  }, []);
  const onRoleClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      popupFilterJobFamilyRef.current.show(e.currentTarget);
    },
    []
  );
  const isOpenModalPersonKeyDate = useMemo(
    () => !!selectedPersonKeyDate,
    [selectedPersonKeyDate]
  );
  const handleOpenModalCandidate = useCallback(async (candidateId: string) => {
    const res = await api.candidate.byId(candidateId);
    if (res.data) {
      setSelectedCandidate(res.data);
    }
  }, []);
  const pageToNavigate = useCallback(() => {
    let path = "";
    if (user.permissions.includes(UserPermission.Requisition)) {
      path = "/requisition";
    } else if (user.permissions.includes(UserPermission.Recruit)) {
      path = "/recruit";
    } else if (user.permissions.includes(UserPermission.ManageTeam)) {
      path = "/manage";
    } else if (user.permissions.includes(UserPermission.AccessBilling)) {
      path = "/billing";
    } else if (user.permissions.includes(UserPermission.Timesheets)) {
      path = "/time-sheets";
    }
    return path;
  }, [user.permissions]);
  useEffect(() => {
    if (!user.permissions.includes(UserPermission.SeeDashboard)) {
      const path = pageToNavigate();
      if (path) {
        navigate(path, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageToNavigate, user.permissions]);
  useEffect(() => {
    const candidateId = searchParams.get("candidate_id");
    if (candidateId) {
      searchParams.delete("candidate_id");
      handleOpenModalCandidate(candidateId);
      setSearchParams(searchParams);
    }
  }, [handleOpenModalCandidate, searchParams, setSearchParams]);
  return (
    <div className={`${styles.container} container`}>
      <h1 className="page-title">Dashboard</h1>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <RecruitmentFunnel
            recruitment={recruitment}
            selectedRole={selectedRole}
            onRoleClick={onRoleClick}
          />
          <Grid container marginTop="35px">
            <Grid item md={12} lg={6}>
              <TeamDistribution distribution={distribution} />
            </Grid>
            <Grid item md={12} lg={6}>
              <TeamSize />
            </Grid>
          </Grid>
          <Grid container marginTop="35px" marginBottom="40px">
            <Grid item md={12} lg={6}>
              <DashboardKeyDates
                keyDates={keyDates}
                onUserClick={onUserClick}
              />
            </Grid>
            <Grid item md={12} lg={6}>
              <Stack className={styles["calendar"]}>
                <DashboardCalendar />
              </Stack>
            </Grid>
          </Grid>
        </>
      )}
      <ModalDashboardPersonKeyDate
        open={isOpenModalPersonKeyDate}
        onClose={onCloseModalPersonKeyDate}
        keyDate={selectedPersonKeyDate}
      />
      <PopoverButton
        ref={popupFilterJobFamilyRef}
        data={[
          { value: "", label: "All roles" },
          ...(jobFamilies?.map((el) => ({ value: el.id, label: el.name })) ||
            []),
        ]}
        onSelected={handleSelectedMenu}
        popupOnly
      />
      <ModalCandidate
        open={!!selectedCandidate}
        onClose={handleCloseModalCandidate}
        candidate={selectedCandidate}
      />
    </div>
  );
};

export default memo(Dashboard);
