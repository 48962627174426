import React, { memo } from "react";

interface IIconRequisition {
  fill?: string;
}

const IconRequisition = ({ fill = "white" }: IIconRequisition) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.49991 15.25C9.08569 15.25 8.74991 15.5858 8.74991 16C8.74991 16.4142 9.08569 16.75 9.49991 16.75H11.2499V18.5C11.2499 18.9142 11.5857 19.25 11.9999 19.25C12.4141 19.25 12.7499 18.9142 12.7499 18.5V16.75H14.4999C14.9141 16.75 15.2499 16.4142 15.2499 16C15.2499 15.5858 14.9141 15.25 14.4999 15.25H12.7499V13.5C12.7499 13.0858 12.4141 12.75 11.9999 12.75C11.5857 12.75 11.2499 13.0858 11.2499 13.5V15.25H9.49991Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.24991 5.4612V6.69898C6.88062 6.72683 6.51151 6.75794 6.14263 6.79229C4.47354 6.94775 3.19727 8.34842 3.19727 10.0247V17.9753C3.19727 19.6516 4.47354 21.0523 6.14263 21.2077C10.039 21.5706 13.9608 21.5706 17.8572 21.2077C19.5262 21.0523 20.8025 19.6516 20.8025 17.9753V10.0247C20.8025 8.34842 19.5262 6.94775 17.8572 6.79229C17.4883 6.75794 17.1192 6.72684 16.7499 6.69898V5.4612C16.7499 4.59495 16.1162 3.85906 15.2595 3.73056L14.0396 3.54757C12.6874 3.34474 11.3124 3.34474 9.96023 3.54757L8.74031 3.73056C7.88365 3.85906 7.24991 4.59495 7.24991 5.4612ZM13.8171 5.03098C12.6124 4.85027 11.3874 4.85027 10.1827 5.03098L8.96282 5.21396C8.84044 5.23232 8.74991 5.33745 8.74991 5.4612V6.60379C10.9151 6.49223 13.0847 6.49223 15.2499 6.60379V5.4612C15.2499 5.33745 15.1594 5.23232 15.037 5.21396L13.8171 5.03098ZM17.7181 8.28583C13.9142 7.93155 10.0856 7.93155 6.28174 8.28583C5.38384 8.36946 4.69727 9.12295 4.69727 10.0247V10.25H19.3025V10.0247C19.3025 9.12295 18.6159 8.36946 17.7181 8.28583ZM19.3025 11.75H4.69727V17.9753C4.69727 18.8771 5.38384 19.6306 6.28174 19.7142C10.0856 20.0685 13.9142 20.0685 17.7181 19.7142C18.6159 19.6306 19.3025 18.8771 19.3025 17.9753V11.75Z"
        fill={fill}
      />
    </svg>
  );
};

export default memo(IconRequisition);
