import { IconButton, Stack, TextField } from "@mui/material";
import { IComment } from "models/Comment";
import React, { memo, useCallback, useMemo, useRef, useState } from "react";
import styles from "./index.module.scss";
import images from "common/images";
import { dateFromNow } from "utils/DateUtils";
import PopoverButton, { PopoverItem } from "components/PopoverButton";
import IconArrowSelect from "components/SVGs/IconArrowSelect";
import useUser from "hooks/useUser";
import IconChevronRight from "components/SVGs/IconChevronRight";
import api from "api";

interface ICommentItem {
  comment: IComment;
  onDelete?: (comment: IComment) => void;
}

const CommentItem = ({ comment, onDelete }: ICommentItem) => {
  const user = useUser();
  const [isEdit, setEdit] = useState(false);
  const [contentEdit, setContentEdit] = useState("");
  const canUpdateComment = useMemo(
    () => user.id === comment.user?.id,
    [comment.user?.id, user.id]
  );
  const popupMenuRef = useRef<any>();
  const commentMenu = useMemo<PopoverItem[]>(
    () => [
      { label: "Edit", value: "Edit" },
      { label: "Delete", value: "Delete" },
    ],
    []
  );
  const toggleEdit = useCallback(() => setEdit((current) => !current), []);
  const handleSelectedMenu = useCallback(
    (menu: PopoverItem) => {
      switch (menu.value) {
        case "Edit":
          setContentEdit(comment.content);
          toggleEdit();
          break;
        case "Delete":
          onDelete?.(comment);
          break;
        default:
          break;
      }
      popupMenuRef.current.hide();
    },
    [comment, onDelete, toggleEdit]
  );
  const onChangeContent = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setContentEdit(e.target.value);
    },
    []
  );
  const onSend = useCallback(() => {
    if (contentEdit) {
      api.comment.update({ id: comment.id, content: contentEdit });
      toggleEdit();
    }
  }, [comment.id, contentEdit, toggleEdit]);
  const edited = useMemo(() => {
    if (comment.createdAt === comment.updatedAt) return "";
    return " - Edited";
  }, [comment.createdAt, comment.updatedAt]);
  return (
    <Stack className={styles.container} direction="row" alignItems="flex-start">
      <img
        src={
          comment.user?.avatar?.src ||
          user.client?.logo?.src ||
          images.sampleAvatar
        }
        alt="avatar"
        className={styles.avatar}
      />
      <Stack className={styles["content-container"]}>
        <span className={styles.date}>
          {dateFromNow(comment.createdAt)}
          {edited}
        </span>
        {isEdit ? (
          <Stack direction="row">
            <TextField
              multiline
              value={contentEdit}
              style={{ minHeight: 100 }}
              onChange={onChangeContent}
            />
            <Stack marginLeft="6px">
              <Stack
                className={styles["btn-send"]}
                direction="row"
                alignItems="center"
                onClick={onSend}
              >
                <span>Send</span>
                <IconChevronRight style={{ marginLeft: 5 }} />
              </Stack>
              <Stack
                className={styles["btn-cancel"]}
                direction="row"
                alignItems="center"
                onClick={toggleEdit}
              >
                <span>Cancel</span>
              </Stack>
            </Stack>
          </Stack>
        ) : (
          <span className={styles.content}>{comment.content}</span>
        )}
      </Stack>
      {canUpdateComment && !isEdit && (
        <PopoverButton
          ref={popupMenuRef}
          data={commentMenu}
          onSelected={handleSelectedMenu}
          itemWidth={100}
          componentButton={
            <IconButton>
              <IconArrowSelect />
            </IconButton>
          }
        />
      )}
    </Stack>
  );
};

export default memo(CommentItem);
