import React, { memo } from "react";

interface IIconTimeSheet {
  fill?: string;
}

const IconTimeSheet = ({ fill = "white" }: IIconTimeSheet) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.0418 3.55242L13.7196 3.63683C15.2841 3.83167 16.4584 5.16133 16.4584 6.73787V15.4791C16.4584 17.0098 15.3182 18.3008 13.7992 18.49C11.2762 18.8042 8.72397 18.8042 6.20098 18.49C4.68196 18.3008 3.54175 17.0098 3.54175 15.4791V6.73787C3.54175 5.16133 4.71608 3.83167 6.28054 3.63683L6.95833 3.55242C7.22682 2.58505 8.11386 1.875 9.16675 1.875H10.8334C11.8863 1.875 12.7733 2.58505 13.0418 3.55242ZM6.87508 4.82244L6.43502 4.87725C5.49634 4.99415 4.79175 5.79195 4.79175 6.73787V15.4791C4.79175 16.3792 5.46223 17.1384 6.35546 17.2496C8.77586 17.551 11.2243 17.551 13.6447 17.2496C14.5379 17.1384 15.2084 16.3792 15.2084 15.4791V6.73787C15.2084 5.79195 14.5038 4.99415 13.5651 4.87725L13.1251 4.82244V5.83333C13.1251 6.17851 12.8453 6.45833 12.5001 6.45833H7.50008C7.1549 6.45833 6.87508 6.17851 6.87508 5.83333V4.82244ZM8.12508 4.16667C8.12508 3.59137 8.59145 3.125 9.16675 3.125H10.8334C11.4087 3.125 11.8751 3.59137 11.8751 4.16667V5.20833H8.12508V4.16667Z"
        fill={fill}
      />
      <path
        d="M13.1251 9.79167C13.1251 9.44649 12.8453 9.16667 12.5001 9.16667H7.50008C7.1549 9.16667 6.87508 9.44649 6.87508 9.79167C6.87508 10.1368 7.1549 10.4167 7.50008 10.4167H12.5001C12.8453 10.4167 13.1251 10.1368 13.1251 9.79167Z"
        fill={fill}
      />
      <path
        d="M12.2917 12.2917C12.2917 11.9465 12.0119 11.6667 11.6667 11.6667H7.50008C7.1549 11.6667 6.87508 11.9465 6.87508 12.2917C6.87508 12.6368 7.1549 12.9167 7.50008 12.9167H11.6667C12.0119 12.9167 12.2917 12.6368 12.2917 12.2917Z"
        fill={fill}
      />
    </svg>
  );
};

export default memo(IconTimeSheet);
