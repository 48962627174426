import React, { memo, useCallback } from "react";
import styles from "./index.module.scss";
import useAppDispatch from "hooks/useAppDispatch";
import { RECRUIT_ACTIONS } from "reducers/RecruitReducers";

interface IRecruitFilterItem {
  label: string;
  active: boolean;
  id: string;
}

const RecruitFilterItem = ({ label, active, id }: IRecruitFilterItem) => {
  const dispatch = useAppDispatch();
  const onClick = useCallback(() => {
    dispatch(RECRUIT_ACTIONS.updateSelected(id));
  }, [dispatch, id]);
  return (
    <div
      className={`${styles.container} ${active ? styles.active : ""}`}
      onClick={onClick}
    >
      <span>{label}</span>
    </div>
  );
};

export default memo(RecruitFilterItem);
