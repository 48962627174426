import BillReducers from "./BillReducers";
import CommentReducers from "./CommentReducers";
import DashboardReducers from "./DashboardReducers";
import ManageReducers from "./ManageReducers";
import MetadataReducers from "./MetadataReducers";
import RecruitReducers from "./RecruitReducers";
import RequisitionReducers from "./RequisitionReducers";
import TimeSheetReducers from "./TimeSheetReducers";
import UserReducers from "./UserReducers";
import { combineReducers } from "redux";

const reducers = combineReducers({
  user: UserReducers,
  dashboard: DashboardReducers,
  metadata: MetadataReducers,
  recruit: RecruitReducers,
  manage: ManageReducers,
  comment: CommentReducers,
  requisition: RequisitionReducers,
  bill: BillReducers,
  timeSheet: TimeSheetReducers,
});

export default reducers;
