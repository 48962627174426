import React, { CSSProperties, memo } from "react";

interface IIconChevronRight {
  style?: CSSProperties
}

const IconChevronRight = ({style}: IIconChevronRight) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M14.975 12C14.975 12.1333 14.95 12.2623 14.9 12.387C14.85 12.5123 14.7834 12.6167 14.7 12.7L10.1 17.3C9.91672 17.4833 9.68338 17.575 9.40005 17.575C9.11672 17.575 8.88338 17.4833 8.70005 17.3C8.51672 17.1167 8.42505 16.8833 8.42505 16.6C8.42505 16.3167 8.51672 16.0833 8.70005 15.9L12.6 12L8.70005 8.10001C8.51671 7.91668 8.42505 7.68335 8.42505 7.40001C8.42505 7.11668 8.51671 6.88335 8.70005 6.70001C8.88338 6.51668 9.11671 6.42501 9.40005 6.42501C9.68338 6.42501 9.91672 6.51668 10.1 6.70001L14.7 11.3C14.8 11.4 14.8707 11.5083 14.912 11.625C14.954 11.7417 14.975 11.8667 14.975 12Z"
        fill="white"
      />
    </svg>
  );
};

export default memo(IconChevronRight);
