import { Stack, TableCell, TableRow } from "@mui/material";
import React, { memo, useCallback, useMemo } from "react";
import styles from "./index.module.scss";
import moment from "moment";
import { IBill } from "models/Bill";
import IconDownload from "components/SVGs/IconDownload";
import { useLocation } from "react-router-dom";

interface IBillingItem {
  item: IBill;
}

const BillingItem = ({ item }: IBillingItem) => {
  const location = useLocation();
  const billAmount = useMemo(
    () => `${item.currency.symbol}${item.billAmount}`,
    [item.billAmount, item.currency.symbol]
  );
  const onDownload = useCallback(() => {
    if (item.downloadFile?.src) {
      window.open(item.downloadFile.src, "_blank");
    }
  }, [item.downloadFile?.src]);
  return (
    <TableRow
      className={`${location.hash === `#${item.id}` ? styles.active : ""}`}
    >
      <TableCell className={styles.name} style={{ padding: "15px 0" }}>
        {moment(item.document).format("MM/DD/YYYY")}
      </TableCell>
      <TableCell className={styles.name} style={{ padding: "15px 0" }}>
        {item.documentId}
      </TableCell>
      <TableCell className={styles.name} style={{ padding: "15px 0" }}>
        {item.documentType}
      </TableCell>
      <TableCell className={styles.name} style={{ padding: "15px 0" }}>
        {billAmount}
      </TableCell>
      <TableCell style={{ padding: "15px 0" }}>
        {item.downloadFile?.src && (
          <Stack className={styles["download-btn"]} onClick={onDownload}>
            <span>Download</span>
            <IconDownload style={{ marginLeft: 5 }} />
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
};

export default memo(BillingItem);
