import React, {
  useState,
  forwardRef,
  useImperativeHandle,
  useCallback,
  useMemo,
  CSSProperties,
  useRef,
} from "react";
import styles from "./index.module.scss";
import { Popover, PopoverOrigin } from "@mui/material";

export type PopoverItem = {
  label: string;
  value: string;
  shortcut?: string;
  type?: "default" | "destructive" | "secondary";
  icon?: any;
  renderIcon?: any;
};

type PopoverButtonProps = {
  title?: string;
  componentButton?: any;
  componentPopup?: any;
  icon?: any;
  data?: Array<PopoverItem>;
  onSelected?: (
    data: PopoverItem,
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onClose?: (event?: any, reason?: "backdropClick" | "escapeKeyDown") => void;
  popupOnly?: boolean;
  onOpen?: () => void;
  transformOrigin?: PopoverOrigin;
  anchorOrigin?: PopoverOrigin;
  itemWidth?: number;
  style?: CSSProperties;
  removeBackground?: boolean;
};

type PopupItemProps = {
  item: any;
  onSelected?: (
    item: PopoverItem,
    e?: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => void;
  onClose: () => void;
  itemWidth?: number;
};

const PopupItem = ({
  item,
  onSelected,
  onClose,
  itemWidth,
}: PopupItemProps) => {
  const handleItemClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      onSelected?.(item, e);
      onClose();
    },
    [item, onClose, onSelected]
  );
  return (
    <div
      className={`${styles["popup__item"]}`}
      onClick={handleItemClick}
      style={itemWidth ? { width: itemWidth, minWidth: itemWidth } : {}}
    >
      <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
        {item.icon && (
          <img className={styles["item__icon"]} src={item.icon} alt="" />
        )}
        {item.renderIcon && item.renderIcon()}
        <span
          className={`${styles["item__name"]} ${
            styles[`${item.type || "default"}`]
          }`}
        >
          {item.label}
        </span>
      </div>
      {item.shortcut && (
        <span
          className={`${styles["item__name"]} ${
            styles[`${item.type || "default"}`]
          }`}
        >
          {item.shortcut}
        </span>
      )}
    </div>
  );
};

const PopoverButton = forwardRef(
  (
    {
      title,
      componentButton,
      icon,
      data,
      onSelected,
      onClose,
      componentPopup,
      popupOnly,
      onOpen,
      transformOrigin = {
        vertical: "top",
        horizontal: "center",
      },
      anchorOrigin = {
        vertical: "bottom",
        horizontal: "left",
      },
      itemWidth,
      style,
      removeBackground,
    }: PopoverButtonProps,
    ref
  ) => {
    const buttonRef = useRef<any>();
    const [anchorReference, setAnchorReference] = useState<any>("anchorEl");
    const [anchorPosition, setAnchorPosition] = useState({ left: 0, top: 0 });
    const [anchorPopup, setPopup] = useState<any>(null);
    const idPopup = useMemo(
      () => (!!anchorPopup ? "cpn-button-popover" : undefined),
      [anchorPopup]
    );
    const handleOpenPopup = useCallback(
      (e: any) => {
        e.stopPropagation();
        setPopup(e.currentTarget);
        onOpen?.();
      },
      [onOpen]
    );
    const handleClick = useCallback((e: any) => e.stopPropagation(), []);
    const handleClose = useCallback(
      (event?: any, reason?: "backdropClick" | "escapeKeyDown") => {
        setAnchorReference("anchorEl");
        setPopup(null);
        onClose?.(event, reason);
      },
      [onClose]
    );
    useImperativeHandle(ref, () => {
      return {
        show(target?: any, pos?: any) {
          if (!pos) {
            setAnchorReference("anchorEl");
          } else {
            setAnchorReference("anchorPosition");
            setAnchorPosition({ left: pos.x, top: pos.y });
          }
          if (target) {
            setPopup(target);
          } else if (buttonRef.current) {
            setTimeout(() => {
              setPopup(buttonRef.current);
            }, 200);
          }
        },
        isOpen: !!anchorPopup,
        hide: handleClose,
      };
    });
    const renderButton = useCallback(() => {
      if (popupOnly) return null;
      if (componentButton != null)
        return (
          <div onClick={handleOpenPopup} ref={buttonRef}>
            {componentButton}
          </div>
        );
      return (
        <div
          className={`${styles["main-button"]}`}
          onClick={handleOpenPopup}
          ref={buttonRef}
        >
          <span className={styles["title-button"]}>{title}</span>
          {icon && <div style={{ width: 8 }} />}
          {icon && <img src={icon} alt="" />}
        </div>
      );
    }, [componentButton, icon, popupOnly, title, handleOpenPopup]);
    const renderPopupItem = useCallback(
      (dt: any) => (
        <PopupItem
          item={dt}
          onSelected={onSelected}
          onClose={handleClose}
          key={dt.value}
          itemWidth={itemWidth}
        />
      ),
      [handleClose, onSelected, itemWidth]
    );
    return (
      <>
        {renderButton()}
        <Popover
          style={style}
          onClick={handleClick}
          id={idPopup}
          transitionDuration={200}
          open={!!anchorPopup}
          anchorEl={anchorPopup}
          anchorReference={anchorReference}
          anchorPosition={anchorPosition}
          onClose={handleClose}
          onContextMenu={(e) => e.stopPropagation()}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          classes={
            removeBackground
              ? { paper: styles["popover-transparent-background"] }
              : undefined
          }
        >
          {componentPopup != null ? (
            componentPopup
          ) : (
            <div className={styles["popup__container"]}>
              {data?.map?.(renderPopupItem)}
            </div>
          )}
        </Popover>
      </>
    );
  }
);

export default PopoverButton;
