import React, { CSSProperties, memo } from "react";

interface IIconRemove {
  fill?: string;
  style?: CSSProperties;
}

const IconRemove = ({ fill = "white", style }: IIconRemove) => {
  return (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M10.5806 19.4194L19.4194 10.5806"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M10.5806 10.5806L19.4194 19.4194"
        stroke={fill}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default memo(IconRemove);
