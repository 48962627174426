import { Dialog, IconButton, Stack } from "@mui/material";
import React, { memo } from "react";
import styles from "./index.module.scss";
import IconClose from "components/SVGs/IconClose";

interface IModalConfirm {
  open: boolean;
  onClose: () => void;
  title: string;
  description: string;
  onConfirm: () => void;
}

const ModalConfirm = ({
  open,
  onClose,
  title,
  description,
  onConfirm,
}: IModalConfirm) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "20px" } }}
    >
      <Stack className={styles["container"]} alignItems="center">
        <IconButton className={styles["btn-close"]} onClick={onClose}>
          <IconClose />
        </IconButton>
        <span className={styles.title}>{title}</span>
        <span className={styles.description}>{description}</span>
        <Stack
          direction="row"
          justifyContent="center"
          width="100%"
          marginTop="35px"
          marginBottom="36px"
        >
          <div className={styles["btn-cancel"]} onClick={onClose}>
            Cancel
          </div>
          <div className={styles["btn-confirm"]} onClick={onConfirm}>
            Confirm
          </div>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default memo(ModalConfirm);
