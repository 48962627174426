import { ICreateUserData, IITAsset, INotification, IUser } from "models/User";
import Caller from "./Caller";
import { getDeviceToken } from "services/Firebase";
import AppConfig from "common/AppConfig";

const UserAPI = {
  me: async () => {
    return Caller.get<IUser>(`users/currentUser`);
  },
  login: (req: { email: string; password: string }) =>
    Caller.post<{ accessToken: string; user: IUser }>("auth/login", req),
  logout: () => Caller.post("auth/logout"),
  notificationList: (req: { page: number }) =>
    Caller.get<INotification[]>(`notifications?page=${req.page}&perPage=10`),
  changePass: (body: { oldPassword: string; newPassword: string }) =>
    Caller.post("auth/changepassword", body),
  resetPass: (body: { email: string }) =>
    Caller.post("auth/resetpassword", body),
  verifyPass: (body: { resetToken: string; newPassword: string }) =>
    Caller.post("auth/verifyresetpassword", body),
  create: (body: ICreateUserData) =>
    Caller.post("users/createUserFromWeb", body),
  updateUsers: (body: {
    users: { id: string; email: string; role: string }[];
  }) => Caller.post("users/updateUserFromWeb", body),
  readNotification: (req: { id: string }) =>
    Caller.post("notifications/markAsRead", req),
  readAllNotification: () => Caller.post("notifications/markAsReadAll"),
  updateDeviceToken: async () => {
    const token = await getDeviceToken();
    if (token) {
      Caller.post("users/setFcmToken", { fcmToken: token });
    }
  },
  getITAssets: (req: { page: number }) =>
    Caller.get<IITAsset[]>(
      `assets?${new URLSearchParams({
        page: `${req.page}`,
        perPage: `${AppConfig.perPage}`,
      })}`
    ),
  refreshToken: () => Caller.post<{ accessToken: string }>("auth/refresh"),
};

export default UserAPI;
