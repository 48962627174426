import React, { CSSProperties, memo } from "react";

interface IIconAddUser {
  style?: CSSProperties;
}

const IconAddUser = ({ style }: IIconAddUser) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.49996 4.99996C4.49996 3.43515 5.76849 2.16663 7.33329 2.16663C8.8981 2.16663 10.1666 3.43515 10.1666 4.99996C10.1666 6.56477 8.8981 7.83329 7.33329 7.83329C5.76849 7.83329 4.49996 6.56477 4.49996 4.99996ZM7.33329 3.16663C6.32077 3.16663 5.49996 3.98744 5.49996 4.99996C5.49996 6.01248 6.32077 6.83329 7.33329 6.83329C8.34582 6.83329 9.16663 6.01248 9.16663 4.99996C9.16663 3.98744 8.34582 3.16663 7.33329 3.16663Z"
        fill="white"
        fillOpacity="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.16663 11.3333C2.16663 9.95258 3.28591 8.83329 4.66663 8.83329H4.89387C5.01687 8.83329 5.13909 8.85274 5.25601 8.89092L5.83302 9.07933C6.80787 9.39765 7.85871 9.39765 8.83357 9.07933L9.41058 8.89092C9.5275 8.85274 9.64972 8.83329 9.77271 8.83329H9.99996C11.3807 8.83329 12.5 9.95258 12.5 11.3333V12.1255C12.5 12.6276 12.136 13.0558 11.6404 13.1367C8.78789 13.6024 5.87869 13.6024 3.02614 13.1367C2.53055 13.0558 2.16663 12.6276 2.16663 12.1255V11.3333ZM4.66663 9.83329C3.8382 9.83329 3.16663 10.5049 3.16663 11.3333V12.1255C3.16663 12.1375 3.17537 12.1478 3.18727 12.1498C5.93311 12.5981 8.73348 12.5981 11.4793 12.1498C11.4912 12.1478 11.5 12.1375 11.5 12.1255V11.3333C11.5 10.5049 10.8284 9.83329 9.99996 9.83329H9.77271C9.75514 9.83329 9.73768 9.83607 9.72098 9.84153L9.14397 10.0299C7.96742 10.4141 6.69917 10.4141 5.52262 10.0299L4.94561 9.84153C4.9289 9.83607 4.91144 9.83329 4.89387 9.83329H4.66663Z"
        fill="white"
        fillOpacity="0.7"
      />
      <path
        d="M13 4.16663C13.2761 4.16663 13.5 4.39048 13.5 4.66663V5.83329H14.6666C14.9428 5.83329 15.1666 6.05715 15.1666 6.33329C15.1666 6.60944 14.9428 6.83329 14.6666 6.83329H13.5V7.99996C13.5 8.2761 13.2761 8.49996 13 8.49996C12.7238 8.49996 12.5 8.2761 12.5 7.99996V6.83329H11.3333C11.0572 6.83329 10.8333 6.60944 10.8333 6.33329C10.8333 6.05715 11.0572 5.83329 11.3333 5.83329H12.5V4.66663C12.5 4.39048 12.7238 4.16663 13 4.16663Z"
        fill="white"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default memo(IconAddUser);
