import {
  Button,
  Dialog,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import React, { memo, useCallback, useMemo, useState } from "react";
import styles from "./index.module.scss";
import { RequisitionRequest } from "models/Requisition";
import IconArrowSelect from "components/SVGs/IconArrowSelect";
import useHiringManager from "hooks/useHiringManager";
import useSkillsTesting from "hooks/useSkillsTesting";
import IconArrowRight from "components/SVGs/IconArrowRight";
import DashboardCalendar from "components/Charts/DashboardCalendar";
import IconClose from "components/SVGs/IconClose";
import moment from "moment";

interface IRequisitionHiringProcess {
  requisitionData: RequisitionRequest;
  setRequisitionData: React.Dispatch<React.SetStateAction<RequisitionRequest>>;
}

const RequisitionHiringProcess = ({
  requisitionData,
  setRequisitionData,
}: IRequisitionHiringProcess) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [openSelectDate, setOpenSelectDate] = useState(false);
  const hiringManager = useHiringManager();
  const skillsTesting = useSkillsTesting();
  const toggleOpenDate = useCallback(
    () => setOpenSelectDate((current) => !current),
    []
  );
  const handleSelectDate = useCallback(() => {
    setRequisitionData((current) => ({
      ...current,
      openDate: moment(selectedDate).format("YYYY-MM-DD"),
    }));
    toggleOpenDate();
  }, [selectedDate, setRequisitionData, toggleOpenDate]);
  const onChangeHiringManager = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRequisitionData((current) => ({
        ...current,
        hiringManager: e.target.value,
      }));
    },
    [setRequisitionData]
  );
  const onChangeSkillsTesting = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const value: any = e.target.value;
      setRequisitionData((current) => ({
        ...current,
        skillTesting:
          typeof value === "string"
            ? value.split(",")
            : value.filter((el: any) => !!el),
      }));
    },
    [setRequisitionData]
  );
  const displaySkillTesting = useMemo(() => {
    if (requisitionData.skillTesting.length === 0) return [""];
    return requisitionData.skillTesting;
  }, [requisitionData.skillTesting]);
  return (
    <Stack marginTop="20px">
      <span className={"session-label"}>Hiring Process</span>
      <Grid container columnSpacing={2.5} rowSpacing={1.625} marginTop="12px">
        <Grid item md={12} lg={4}>
          <Stack>
            <span
              className={styles["text-field-label"]}
              style={{ marginBottom: 15 }}
            >
              Hiring Manager
            </span>
            <TextField
              select
              value={requisitionData.hiringManager}
              onChange={onChangeHiringManager}
              SelectProps={{
                IconComponent: (props) => (
                  <IconArrowSelect {...props} style={{ right: 13 }} />
                ),
                displayEmpty: true,
              }}
            >
              <MenuItem value="" style={{ display: "none" }}>
                <span className={styles["placeholder"]}>Select Manager</span>
              </MenuItem>
              {hiringManager?.map((el) => (
                <MenuItem key={el.id} value={el.id}>
                  {el.username}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Grid>
        <Grid item md={12} lg={4}>
          <Stack>
            <span
              className={styles["text-field-label"]}
              style={{ marginBottom: 15 }}
            >
              Type of Skill Testing
            </span>
            <TextField
              select
              value={displaySkillTesting}
              onChange={onChangeSkillsTesting}
              SelectProps={{
                IconComponent: (props) => (
                  <IconArrowSelect {...props} style={{ right: 13 }} />
                ),
                displayEmpty: true,
                multiple: true,
              }}
            >
              <MenuItem value="" style={{ display: "none" }}>
                <span className={styles["placeholder"]}>
                  Select Skill Testing
                </span>
              </MenuItem>
              {skillsTesting?.map((el) => (
                <MenuItem key={el.id} value={el.id}>
                  {el.name}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Grid>
        <Grid item md={12} lg={4}>
          <Stack>
            <span
              className={styles["text-field-label"]}
              style={{ marginBottom: 15 }}
            >
              Position Open Date
            </span>
            <Button
              variant="outlined"
              endIcon={<IconArrowRight />}
              className={`${styles["btn-select-date"]} ${
                !requisitionData.openDate ? styles["empty"] : ""
              }`}
              disableRipple
              onClick={toggleOpenDate}
            >
              {requisitionData.openDate || "Select Date"}
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <Dialog
        open={openSelectDate}
        onClose={toggleOpenDate}
        PaperProps={{ sx: { borderRadius: "20px" } }}
      >
        <Stack className={styles["calendar"]} alignItems="flex-start">
          <IconButton className={styles["btn-close"]} onClick={toggleOpenDate}>
            <IconClose />
          </IconButton>
          <DashboardCalendar
            onChange={setSelectedDate}
            disablePast
            initialDate={selectedDate}
          />
          <Stack
            direction="row"
            justifyContent="center"
            width="100%"
            marginTop="15px"
          >
            <div className={styles["btn-cancel"]} onClick={toggleOpenDate}>
              Cancel
            </div>
            <div className={styles["btn-set-date"]} onClick={handleSelectDate}>
              Set Date
            </div>
          </Stack>
        </Stack>
      </Dialog>
    </Stack>
  );
};

export default memo(RequisitionHiringProcess);
