import { useMemo } from "react";
import useAppSelector from "./useAppSelector";
import moment from "moment";

function useHoliday(date: any) {
  const holidays = useAppSelector((state) => state.dashboard.holidays);
  return useMemo(
    () =>
      holidays?.find((el) => {
        const momentDate = moment(
          date - new Date().getTimezoneOffset() * 60000
        );
        return (
          momentDate.diff(el.startDate) >= 0 && momentDate.diff(el.endDate) <= 0
        );
      }),
    [date, holidays]
  );
}

export default useHoliday;
