import { Checkbox, Stack } from "@mui/material";
import React, { memo, useCallback, useMemo } from "react";
import { RequisitionRequest } from "models/Requisition";
import styles from "./index.module.scss";

interface IRequisitionRateRange {
  requisitionData: RequisitionRequest;
  setRequisitionData: React.Dispatch<React.SetStateAction<RequisitionRequest>>;
}

const RequisitionRateRange = ({
  requisitionData,
  setRequisitionData,
}: IRequisitionRateRange) => {
  const onChangeAdhocRateOffers = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setRequisitionData((current) => ({
        ...current,
        adhocRateOffers: e.target.checked,
      }));
    },
    [setRequisitionData]
  );
  const displayMinRate = useMemo(() => {
    if (requisitionData.minRate > 0) return requisitionData.minRate.toString();
    return "";
  }, [requisitionData.minRate]);
  const displayMaxRate = useMemo(() => {
    if (requisitionData.maxRate > 0) return requisitionData.maxRate.toString();
    return "";
  }, [requisitionData.maxRate]);
  const displayCurrencySymbol = useMemo(() => {
    return requisitionData.currency?.symbol || "";
  }, [requisitionData.currency?.symbol]);
  return (
    <Stack marginTop="20px">
      <span className={"session-label"}>Rate Range</span>
      <Stack direction="row" marginTop="20px">
        <Stack>
          <span className={styles["text-field-label"]}>Min</span>
          <Stack
            direction="row"
            alignItems="center"
            className={styles["input-rate__wrapper"]}
          >
            <div className={styles.dot} />
            <span className={styles["input-text"]}>
              {displayCurrencySymbol}
            </span>
            <input
              style={{ flex: 1, width: 100 }}
              className={styles["input-text"]}
              type="number"
              value={displayMinRate}
              disabled
            />
          </Stack>
        </Stack>
        <Stack marginLeft="15px">
          <span className={styles["text-field-label"]}>Max</span>
          <Stack
            direction="row"
            alignItems="center"
            className={styles["input-rate__wrapper"]}
          >
            <div className={styles.dot} />
            <span className={styles["input-text"]}>
              {displayCurrencySymbol}
            </span>
            <input
              style={{ flex: 1, width: 100 }}
              className={styles["input-text"]}
              type="number"
              value={displayMaxRate}
              disabled
            />
          </Stack>
        </Stack>
        <Stack marginLeft="20px" justifyContent="flex-end">
          <Stack direction="row" alignItems="center" height={38}>
            <span className={styles["ad-hoc-text"]}>
              Open to AD Hoc Rate Offers
            </span>
            <Checkbox
              style={{ color: "var(--color-accent)" }}
              onChange={onChangeAdhocRateOffers}
              checked={requisitionData.adhocRateOffers}
            />
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(RequisitionRateRange);
