import {
  CircularProgress,
  Dialog,
  IconButton,
  Stack,
  TextField,
} from "@mui/material";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import styles from "./index.module.scss";
import IconClose from "components/SVGs/IconClose";
import images from "common/images";
import IconAlias from "components/SVGs/IconAlias";
import IconJob from "components/SVGs/IconJob";
import FileItem from "components/FileItem";
import IconPalette from "components/SVGs/IconPalette";
import moment from "moment";
import IconChevronRight from "components/SVGs/IconChevronRight";
import { ICandidate } from "models/Candidate";
import api from "api";
import useCommentData from "hooks/useCommentData";
import Spinner from "components/Spinner";
import { IComment } from "models/Comment";
import CommentItem from "components/CommentItem";
import useAppDispatch from "hooks/useAppDispatch";
import { getListComment } from "reducers/CommentReducers";
import useUser from "hooks/useUser";

interface IInformationItem {
  label: string;
  value?: string;
}

const InformationItem = memo(({ label, value }: IInformationItem) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      className={styles["information-container"]}
    >
      <span className={styles.label}>{label}</span>
      <span className={styles.value}>{value}</span>
    </Stack>
  );
});

interface IModalCandidate {
  open: boolean;
  onClose: () => void;
  candidate?: ICandidate | null;
}

const ModalCandidate = ({ open, onClose, candidate }: IModalCandidate) => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const [submitting, setSubmitting] = useState(false);
  const [contentComment, setContentComment] = useState("");
  const commentData = useCommentData(candidate?.id);
  const onChangeContentComment = useCallback(
    (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setContentComment(e.target.value);
    },
    []
  );
  useEffect(() => {
    if (open && candidate?.id) {
      dispatch(getListComment({ candidateId: candidate?.id }));
    }
  }, [candidate?.id, dispatch, open]);
  useEffect(() => {
    if (open) {
      setContentComment("");
    }
  }, [open]);
  const onComment = useCallback(() => {
    if (!contentComment || !candidate || submitting) return;
    setSubmitting(true);
    api.comment
      .create({ candidateId: candidate.id, content: contentComment })
      .finally(() => {
        setSubmitting(false);
      });
    setContentComment("");
  }, [candidate, contentComment, submitting]);
  const onDeleteComment = useCallback((comment: IComment) => {
    api.comment.delete({ id: comment.id });
  }, []);
  const renderCommentItem = useCallback(
    (comment: IComment) => (
      <CommentItem
        comment={comment}
        key={comment.id}
        onDelete={onDeleteComment}
      />
    ),
    [onDeleteComment]
  );
  const renderCommentList = useCallback(() => {
    if (commentData?.loading) return <Spinner />;
    return <>{commentData?.data?.map(renderCommentItem)}</>;
  }, [commentData?.data, commentData?.loading, renderCommentItem]);
  const gender = useMemo(() => {
    if (candidate?.gender?.name === "M") return "Male";
    if (candidate?.gender?.name === "F") return "Female";
    return candidate?.gender?.name;
  }, [candidate?.gender?.name]);
  if (!candidate) return null;
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { borderRadius: "20px" } }}
    >
      <Stack className={styles.container}>
        <Stack direction="row">
          <IconButton className={styles["btn-close"]} onClick={onClose}>
            <IconClose />
          </IconButton>
          <Stack flex={1} alignItems="center">
            <img
              src={
                candidate.avatar?.src ||
                user.client?.logo?.src ||
                images.sampleAvatar
              }
              alt="avatar"
              className={styles.avatar}
            />
            <span className={styles.name}>{candidate.name}</span>
            <Stack marginTop="10px" minWidth={170}>
              <Stack direction="row" alignItems="center">
                <IconAlias style={{ marginRight: 5 }} />
                <span className={styles["sub-text"]}>
                  Goes by :{" "}
                  <span style={{ color: "var(--color-main)" }}>
                    {candidate.alias}
                  </span>
                </span>
              </Stack>
              <Stack direction="row" alignItems="center" marginTop="10px">
                <IconJob style={{ marginRight: 5 }} />
                <span className={styles["sub-text"]}>
                  {candidate.jobFamily?.name}
                </span>
              </Stack>
            </Stack>
            <Stack width={220} className={styles.file}>
              <FileItem
                name={`${candidate.alias}'s CV`}
                style={{
                  width: "100%",
                  height: 50,
                  marginTop: 0,
                  marginLeft: 0,
                }}
                src={
                  candidate.isCvDirect ? candidate.cvUrl : candidate?.cv?.src
                }
              />
              <FileItem
                name={`${candidate.alias}'s Portfolio`}
                style={{
                  width: "100%",
                  height: 50,
                  marginTop: 15,
                  marginLeft: 0,
                }}
                suffixIcon={<IconPalette />}
                src={
                  candidate.isPortfolioDirect
                    ? candidate.portfolioUrl
                    : candidate?.portfolio?.src
                }
              />
            </Stack>
          </Stack>
          <Stack flex={1} paddingTop="50px">
            <InformationItem
              label="Date of Birth: "
              value={
                candidate.birthday
                  ? moment(candidate.birthday).format("DD/MM/YYYY")
                  : ""
              }
            />
            <InformationItem label="Gender: " value={gender} />
            <InformationItem label="Seniority: " value="Intermediate" />
            <InformationItem
              label="English: "
              value={candidate.english?.name}
            />
            {candidate?.rate && (
              <InformationItem
                label="Rate: "
                value={`${candidate.currency?.symbol || ""}${candidate?.rate}`}
              />
            )}
            <InformationItem
              label="Available From: "
              value={moment(candidate.availableFrom).format("DD/MM/YYYY")}
            />
          </Stack>
        </Stack>
        <Stack className={styles["comment-container"]}>
          <span className={styles["comment-label"]}>Comments</span>
          <Stack direction="row" alignItems="center" marginTop="6px">
            <TextField
              placeholder="Add Comment"
              value={contentComment}
              onChange={onChangeContentComment}
            />
            <Stack
              className={styles["btn-send"]}
              direction="row"
              alignItems="center"
              onClick={onComment}
            >
              <span>Send</span>
              {submitting ? (
                <CircularProgress
                  size={20}
                  color="inherit"
                  style={{ marginLeft: 5 }}
                />
              ) : (
                <IconChevronRight style={{ marginLeft: 5 }} />
              )}
            </Stack>
          </Stack>
          <Stack marginTop="20px">{renderCommentList()}</Stack>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default memo(ModalCandidate);
