import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "./UserActions";
import { IRequisition } from "models/Requisition";
import api from "api";

interface RequisitionState {
  data: IRequisition[];
}

export const getRequisitions = createAsyncThunk("requisition/get", async () => {
  const res = await api.requisition.list();
  return res;
});

const initialState: RequisitionState = {
  data: [],
};

const requisitionSlice = createSlice({
  name: "requisition",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logoutAction, (state: RequisitionState) => {
        return initialState;
      })
      .addCase(getRequisitions.fulfilled, (state, action) => {
        state.data = action.payload.data || [];
      });
  },
});

export const REQUISITION_ACTIONS = requisitionSlice.actions;

export default requisitionSlice.reducer;
