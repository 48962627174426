import React, { memo, CSSProperties } from "react";

interface IIconChevronDown {
  style?: CSSProperties;
  fill?: string;
}

const IconChevronDown = ({ style }: IIconChevronDown) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <rect width="24" height="24" rx="12" fill="#F8F2FC" />
      <path
        d="M8.46458 15.5354L15.5356 8.46436"
        stroke="#6C3399"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M8.46458 8.46458L15.5356 15.5356"
        stroke="#6C3399"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default memo(IconChevronDown);
