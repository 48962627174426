import React, { memo, CSSProperties } from "react";

interface IIconCalendarNext {
  style?: CSSProperties;
}

const IconCalendarNext = ({ style }: IIconCalendarNext) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <rect width="34" height="34" rx="17" fill="#EAF0FC" />
      <path
        d="M21 17C21 17.1674 20.9695 17.3294 20.9084 17.4859C20.8473 17.6433 20.7659 17.7743 20.6641 17.8789L15.0458 23.6547C14.8219 23.8849 14.5369 24 14.1908 24C13.8448 24 13.5598 23.8849 13.3359 23.6547C13.112 23.4245 13 23.1315 13 22.7758C13 22.42 13.112 22.1271 13.3359 21.8969L18.0992 17L13.3359 12.1031C13.112 11.8729 13 11.58 13 11.2242C13 10.8685 13.112 10.5755 13.3359 10.3453C13.5598 10.1151 13.8448 10 14.1908 10C14.5369 10 14.8219 10.1151 15.0458 10.3453L20.6641 16.1211C20.7863 16.2466 20.8726 16.3827 20.9231 16.5291C20.9744 16.6756 21 16.8326 21 17Z"
        fill="#6C3399"
      />
    </svg>
  );
};

export default memo(IconCalendarNext);
