import React, { memo } from "react";
import { Stack } from "@mui/material";
import TeamDistributionItem from "components/TeamDistributionItem";
import { DashboardChartData } from "models";

interface ITeamDistribution {
  distribution?: DashboardChartData[];
}

const TeamDistribution = ({ distribution }: ITeamDistribution) => {
  return (
    <Stack className="team-distribution">
      <span className="session-label">Team Distribution</span>
      <Stack className="mt-5" direction="row">
        {distribution?.map((el, index) => (
          <TeamDistributionItem
            key={el.name}
            distribution={el}
            color={`var(--color-chart-distribution-${
              index >= 3 ? 4 : index + 1
            })`}
          />
        ))}
      </Stack>
    </Stack>
  );
};

export default memo(TeamDistribution);
