import { IDashboard, IHoliday } from "models/Dashboard";
import Caller from "./Caller";

const DashboardAPI = {
  get: async (req: { jobFamily?: string }) => {
    return Caller.get<IDashboard>(`dashboard?${new URLSearchParams(req)}`);
  },
  getHolidays: async () => {
    return Caller.get<IHoliday[]>(`holidays`);
  },
};

export default DashboardAPI;
