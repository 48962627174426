import BillAPI from "./Bill";
import CandidateAPI from "./Candidates";
import CommentAPI from "./Comment";
import DashboardAPI from "./Dashboard";
import MetadataAPI from "./Metadata";
import RequisitionAPI from "./Requisition";
import TimeSheetAPI from "./TimeSheet";
import UserAPI from "./UserAPI";

const api = {
  user: UserAPI,
  metadata: MetadataAPI,
  requisition: RequisitionAPI,
  candidate: CandidateAPI,
  dashboard: DashboardAPI,
  comment: CommentAPI,
  bill: BillAPI,
  timeSheet: TimeSheetAPI,
};

export type ApiType = typeof api;

export default api;
