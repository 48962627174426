import React, { CSSProperties, memo } from "react";

interface IIconKey {
  style?: CSSProperties;
}

const IconKey = ({ style }: IIconKey) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M5.25 7.5C4.42157 7.5 3.75 8.17157 3.75 9C3.75 9.82843 4.42157 10.5 5.25 10.5C6.07843 10.5 6.75 9.82843 6.75 9C6.75 8.17157 6.07843 7.5 5.25 7.5Z"
        fill="white"
        fillOpacity="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.25 4.6875C2.86827 4.6875 0.9375 6.61827 0.9375 9C0.9375 11.3817 2.86827 13.3125 5.25 13.3125C6.88543 13.3125 8.30718 12.4022 9.0381 11.0625H11.4375V12.75C11.4375 13.0607 11.6893 13.3125 12 13.3125H14.625C14.9357 13.3125 15.1875 13.0607 15.1875 12.75V11.0625H16.5C16.8107 11.0625 17.0625 10.8107 17.0625 10.5V8.25C17.0625 7.52513 16.4749 6.9375 15.75 6.9375H9.0381C8.30718 5.59778 6.88543 4.6875 5.25 4.6875ZM2.0625 9C2.0625 7.23959 3.48959 5.8125 5.25 5.8125C6.5562 5.8125 7.68014 6.59827 8.17252 7.72521C8.26203 7.93009 8.46439 8.0625 8.68797 8.0625H15.75C15.8536 8.0625 15.9375 8.14645 15.9375 8.25V9.9375H14.625C14.3143 9.9375 14.0625 10.1893 14.0625 10.5V12.1875H12.5625V10.5C12.5625 10.1893 12.3107 9.9375 12 9.9375H8.68797C8.46439 9.9375 8.26203 10.0699 8.17252 10.2748C7.68014 11.4017 6.5562 12.1875 5.25 12.1875C3.48959 12.1875 2.0625 10.7604 2.0625 9Z"
        fill="white"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default memo(IconKey);
