import React, { memo } from "react";

interface IIconBilling {
  fill?: string;
}

const IconBilling = ({ fill = "white" }: IIconBilling) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.25 8.12516C11.25 7.77999 10.9702 7.50016 10.625 7.50016H5.625C5.27982 7.50016 5 7.77999 5 8.12516C5 8.47034 5.27982 8.75016 5.625 8.75016H10.625C10.9702 8.75016 11.25 8.47034 11.25 8.12516Z"
        fill={fill}
      />
      <path
        d="M10.4167 10.6252C10.4167 10.28 10.1368 10.0002 9.79167 10.0002H5.625C5.27982 10.0002 5 10.28 5 10.6252C5 10.9703 5.27982 11.2502 5.625 11.2502H9.79167C10.1368 11.2502 10.4167 10.9703 10.4167 10.6252Z"
        fill={fill}
      />
      <path
        d="M10.625 12.5002C10.9702 12.5002 11.25 12.78 11.25 13.1252C11.25 13.4703 10.9702 13.7502 10.625 13.7502H5.625C5.27982 13.7502 5 13.4703 5 13.1252C5 12.78 5.27982 12.5002 5.625 12.5002H10.625Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 18.1252H15.8333C17.099 18.1252 18.125 17.0992 18.125 15.8335V11.2502C18.125 10.905 17.8452 10.6252 17.5 10.6252H14.7917V4.1195C14.7917 2.93333 13.4509 2.24335 12.4857 2.9328L12.3398 3.037C11.6894 3.50157 10.8084 3.50001 10.1549 3.03325C9.06839 2.25714 7.59828 2.25714 6.51173 3.03325C5.85826 3.50001 4.97725 3.50157 4.32685 3.037L4.18097 2.9328C3.21575 2.24335 1.875 2.93333 1.875 4.1195V15.0002C1.875 16.7261 3.27411 18.1252 5 18.1252ZM7.23827 4.05042C7.89021 3.58476 8.77646 3.58476 9.42839 4.05042C10.5134 4.82544 11.9783 4.83136 13.0664 4.05416L13.2122 3.94997C13.3501 3.85148 13.5417 3.95004 13.5417 4.1195V15.8335C13.5417 16.2086 13.6318 16.5626 13.7915 16.8752H5C3.96447 16.8752 3.125 16.0357 3.125 15.0002V4.1195C3.125 3.95004 3.31654 3.85148 3.45442 3.94997L3.6003 4.05416C4.68838 4.83136 6.15325 4.82544 7.23827 4.05042ZM14.7917 15.8335V11.8752H16.875V15.8335C16.875 16.4088 16.4086 16.8752 15.8333 16.8752C15.258 16.8752 14.7917 16.4088 14.7917 15.8335Z"
        fill={fill}
      />
    </svg>
  );
};

export default memo(IconBilling);
