import React, { CSSProperties, memo } from "react";

interface IIconDownload {
  style?: CSSProperties;
}

const IconDownload = ({ style }: IIconDownload) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.738 3.25C9.96436 3.25 9.31904 3.84123 9.25148 4.61189C9.11024 6.22317 9.0745 7.84164 9.14436 9.45693C9.06003 9.46258 8.97571 9.46847 8.8914 9.47462L7.4019 9.5832C6.44985 9.6526 5.91764 10.7142 6.43163 11.5185C7.5278 13.2341 8.94026 14.7254 10.5937 15.9132L11.1904 16.3418C11.6743 16.6894 12.3259 16.6894 12.8097 16.3418L13.4065 15.9132C15.0599 14.7254 16.4724 13.2341 17.5685 11.5185C18.0825 10.7142 17.5503 9.6526 16.5983 9.5832L15.1088 9.47462C15.0245 9.46847 14.9401 9.46258 14.8558 9.45693C14.9257 7.84164 14.8899 6.22317 14.7487 4.61188C14.6811 3.84123 14.0358 3.25 13.2622 3.25H10.738ZM10.6816 10.1147C10.5679 8.3275 10.5891 6.53417 10.7451 4.75H13.255C13.4111 6.53417 13.4323 8.3275 13.3186 10.1147C13.3058 10.3151 13.374 10.5122 13.5077 10.662C13.6414 10.8117 13.8296 10.9016 14.0302 10.9114C14.3535 10.9274 14.6767 10.9471 14.9997 10.9707L16.0815 11.0495C15.1219 12.4628 13.9204 13.6971 12.5313 14.6949L12.0001 15.0765L11.4688 14.6949C10.0797 13.6971 8.87824 12.4628 7.9187 11.0495L9.00046 10.9707C9.32345 10.9471 9.64664 10.9274 9.96999 10.9114C10.1705 10.9016 10.3587 10.8117 10.4925 10.662C10.6262 10.5122 10.6943 10.3151 10.6816 10.1147Z"
        fill="white"
      />
      <path
        d="M5.75 17C5.75 16.5858 5.41421 16.25 5 16.25C4.58579 16.25 4.25 16.5858 4.25 17V19C4.25 19.9665 5.0335 20.75 6 20.75H18C18.9665 20.75 19.75 19.9665 19.75 19V17C19.75 16.5858 19.4142 16.25 19 16.25C18.5858 16.25 18.25 16.5858 18.25 17V19C18.25 19.1381 18.1381 19.25 18 19.25H6C5.86193 19.25 5.75 19.1381 5.75 19V17Z"
        fill="white"
      />
    </svg>
  );
};

export default memo(IconDownload);
