import React, { memo } from "react";

interface IIconManage {
  fill?: string;
}

const IconManage = ({ fill = "white" }: IIconManage) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1547 3.36032C12.4836 3.01005 11.5742 3.00465 10.8977 3.35402C8.30478 4.69316 5.95291 6.22859 3.89194 7.9316C3.57323 8.19495 3.34705 8.57764 3.35091 9.02808C3.35475 9.47668 3.58554 9.85425 3.90231 10.1133C5.94616 11.7844 8.29997 13.3213 10.8455 14.6498C11.5166 15.0001 12.426 15.0055 13.1024 14.6561C15.6954 13.317 18.0473 11.7815 20.1083 10.0785C20.427 9.81517 20.6531 9.43248 20.6493 8.98203C20.6454 8.53344 20.4147 8.15586 20.0979 7.89685C18.054 6.22568 15.7002 4.68886 13.1547 3.36032ZM11.5861 4.68677C11.8257 4.563 12.2194 4.56417 12.4607 4.6901C14.89 5.95802 17.1284 7.41672 19.0677 8.99229C17.1169 10.5932 14.8851 12.0472 12.4141 13.3233C12.1745 13.4471 11.7808 13.4459 11.5395 13.32C9.11016 12.0521 6.87175 10.5934 4.93253 9.01783C6.88333 7.4169 9.11508 5.96291 11.5861 4.68677Z"
        fill={fill}
      />
      <path
        d="M21.1971 12.6981C21.4165 13.0494 21.3095 13.5121 20.9581 13.7315L14.8509 17.5444C14.0221 18.0618 12.9939 18.301 12 18.301C11.0061 18.3011 9.97782 18.062 9.14896 17.5447L3.10863 13.775C2.75724 13.5557 2.65015 13.093 2.86946 12.7416C3.08876 12.3902 3.55141 12.2831 3.9028 12.5024L9.94313 16.2722C10.4873 16.6118 11.2265 16.8011 11.9999 16.801C12.7734 16.801 13.5125 16.6116 14.0565 16.272L20.1637 12.4591C20.5151 12.2397 20.9778 12.3467 21.1971 12.6981Z"
        fill={fill}
      />
      <path
        d="M21.1971 16.4527C21.4165 16.8041 21.3095 17.2667 20.9582 17.4861L15.6693 20.789C14.6117 21.4495 13.2887 21.7602 12 21.7603C10.7113 21.7604 9.3882 21.4498 8.33042 20.7895L3.10855 17.5296C2.75719 17.3103 2.65016 16.8476 2.86951 16.4963C3.08885 16.1449 3.55151 16.0379 3.90288 16.2572L9.12474 19.517C9.89776 19.9996 10.9317 20.2604 11.9999 20.2603C13.0681 20.2602 14.1019 19.9994 14.8748 19.5167L20.1637 16.2139C20.515 15.9945 20.9777 16.1014 21.1971 16.4527Z"
        fill={fill}
      />
    </svg>
  );
};

export default memo(IconManage);
