import { AsyncKey } from "./AppConfig";
import Cookies from "js-cookie";

export const clearData = (callback = () => {}) => {
  Cookies.remove(AsyncKey.userIdKey);
  Cookies.remove(AsyncKey.accessTokenKey);
  Cookies.remove(AsyncKey.refreshTokenExpire);
  Cookies.remove(AsyncKey.refreshTokenKey);
  Cookies.remove(AsyncKey.tokenExpire);
  callback();
};

export const setCookie = (key: string, val: any) => {
  return new Promise<void>((resolve) => {
    Cookies.set(key, val);
    return resolve();
  });
};

export const getCookie = async (key: string) => {
  return new Promise<any>((resolve) => {
    const data = Cookies.get(key);
    return resolve(data);
  });
};

export const removeCookie = (key: string) => {
  Cookies.remove(key);
};
