import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import React, { memo, useCallback, useEffect, useState } from "react";
import { getTimeSheets } from "reducers/TimeSheetReducers";
import styles from "./index.module.scss";
import Spinner from "components/Spinner";
import TimeSheetTable from "components/TimeSheetTable";
import { Pagination, Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import api from "api";

const TimeSheet = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.timeSheet.loading);
  const timeSheets = useAppSelector((state) => state.timeSheet.timeSheets);
  const totalPage = useAppSelector((state) => state.timeSheet.totalPage);
  const [page, setPage] = useState<number | null>(null);
  const onPageChange = useCallback((e: any, page: number) => {
    setPage(page);
  }, []);
  useEffect(() => {
    if (location.hash) {
      const timesheetId = location.hash.replace("#", "");
      if (!timeSheets?.find((el) => el.id === timesheetId)) {
        api.timeSheet
          .find({ timesheetId })
          .then((res) => {
            setPage(res?.foundPage || 1);
          })
          .catch((err) => {
            setPage(1);
          });
      }
    } else {
      setPage(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);
  useEffect(() => {
    if (page) {
      dispatch(getTimeSheets({ page }));
    }
  }, [dispatch, page]);
  return (
    <div className={`${styles.container} container`}>
      <h1 className="page-title">Time Sheets</h1>
      <Stack style={{ flex: 1, marginTop: 25 }}>
        {loading ? <Spinner /> : <TimeSheetTable timeSheets={timeSheets} />}
      </Stack>
      {totalPage > 1 && (
        <Pagination
          count={totalPage}
          shape="rounded"
          style={{ margin: "25px 0", alignSelf: "flex-end" }}
          onChange={onPageChange}
        />
      )}
    </div>
  );
};

export default memo(TimeSheet);
