import { MenuItem, TableCell, TableRow, TextField } from "@mui/material";
import { IUser } from "models/User";
import React, { memo, useCallback } from "react";
import styles from "./index.module.scss";
import IconArrowSelect from "components/SVGs/IconArrowSelect";
import useUserRoles from "hooks/useUserRoles";

interface IUserItem {
  user: IUser;
  updatedData?: {
    id: string;
    role: string;
    email: string;
  };
  onUpdateRole: (user: IUser, role: string) => void;
  onUpdateEmail: (user: IUser, email: string) => void;
}

const UserItem = ({
  user,
  updatedData,
  onUpdateEmail,
  onUpdateRole,
}: IUserItem) => {
  const roles = useUserRoles();
  const onChangeRole = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onUpdateRole(user, e.target.value);
    },
    [onUpdateRole, user]
  );
  const onChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onUpdateEmail(user, e.target.value);
    },
    [onUpdateEmail, user]
  );
  return (
    <TableRow>
      <TableCell className={styles.name} style={{ padding: "15px 0" }}>
        {user.username}
      </TableCell>
      <TableCell style={{ padding: "15px 100px 15px 0", width: 440 }}>
        <TextField
          select
          value={updatedData?.role || user.role}
          onChange={onChangeRole}
          SelectProps={{
            IconComponent: (props) => (
              <IconArrowSelect {...props} style={{ right: 13 }} />
            ),
            displayEmpty: true,
          }}
          fullWidth
        >
          <MenuItem value="" style={{ display: "none" }}>
            <span className={styles["placeholder"]}>Select Role</span>
          </MenuItem>
          {roles?.map((el) => (
            <MenuItem key={el.value} value={el.value}>
              {el.label}
            </MenuItem>
          ))}
        </TextField>
      </TableCell>
      <TableCell
        className={styles.name}
        style={{ padding: "15px 0", width: 340 }}
      >
        <TextField
          placeholder="Enter email"
          fullWidth
          value={updatedData?.email || user.email}
          onChange={onChangeEmail}
        />
      </TableCell>
    </TableRow>
  );
};

export default memo(UserItem);
