import { useMemo } from "react";
import useAppSelector from "./useAppSelector";

function useDashboardDistribution() {
  const distribution = useAppSelector(
    (state) => state.dashboard.data?.teamDistribution
  );
  return useMemo(() => distribution, [distribution]);
}

export default useDashboardDistribution;
