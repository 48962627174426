import React, { memo, useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss";
import useAppSelector from "hooks/useAppSelector";
import { ISortData } from "models/User";
import useAppDispatch from "hooks/useAppDispatch";
import { getUsers } from "reducers/MetadataReducers";
import UpdateUserTable from "components/UpdateUserTable";
import Spinner from "components/Spinner";
import { CircularProgress, Stack } from "@mui/material";
import api from "api";
import { useToast } from "providers/ToastProvider";

const UpdateUserRoles = () => {
  const dispatch = useAppDispatch();
  const toast = useToast();
  const [updating, setUpdating] = useState(false);
  const users = useAppSelector((state) => state.metadata.users);
  const loading = useAppSelector((state) => state.metadata.loading);
  const [sortData, setSortData] = useState<ISortData | null>(null);
  const [updateUsers, setUpdateUsers] = useState<
    { id: string; email: string; role: string }[]
  >([]);
  const onSortClick = useCallback((title: string) => {
    setSortData((current) => {
      return {
        title,
        type:
          current?.title !== title
            ? "ASC"
            : current.type === "ASC"
            ? "DESC"
            : "ASC",
      };
    });
  }, []);
  const onSave = useCallback(async () => {
    if (updateUsers.length === 0 || updating) return;
    setUpdating(true);
    const res = await api.user.updateUsers({ users: updateUsers });
    if (res.statusCode === 200) {
      toast.success({ message: res.message || "Updated" });
    } else {
      toast.error({ message: res.message || "Something wrong" });
    }
    setUpdating(false);
  }, [toast, updateUsers, updating]);
  useEffect(() => {
    dispatch(
      getUsers({
        page: 1,
        perPage: 100,
        sortName: sortData?.title,
        sortOrder: sortData?.type,
      })
    );
  }, [dispatch, sortData?.title, sortData?.type]);
  return (
    <div className={styles["update-user-container"]}>
      <h3 className={styles.title}>Change Permissions</h3>
      <Stack style={{ marginTop: 25, flex: 1, position: "relative" }}>
        {loading ? (
          <Spinner />
        ) : (
          <UpdateUserTable
            users={users}
            sortData={sortData}
            onSortClick={onSortClick}
            updateData={updateUsers}
            onUpdate={setUpdateUsers}
          />
        )}
      </Stack>
      <div className={styles["btn-save"]} onClick={onSave}>
        {updating ? (
          <CircularProgress color="inherit" size={15} />
        ) : (
          <span>Save Changes</span>
        )}
      </div>
    </div>
  );
};

export default memo(UpdateUserRoles);
