import {
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { memo, useCallback } from "react";
import KeyDatePersonItem from "components/KeyDatePersonItem";
import KeyDateHolidayItem from "components/KeyDateHolidayItem";
import styles from "./index.module.scss";
import { IKeyDate } from "models/Dashboard";
import IconHelp from "components/SVGs/IconHelp";

interface IDashboardKeyDates {
  keyDates: IKeyDate[];
  onUserClick: (keyDate: IKeyDate) => void;
}

const DashboardKeyDates = ({ keyDates, onUserClick }: IDashboardKeyDates) => {
  const renderKeyDateItem = useCallback((item: IKeyDate) => {
    if (item.nextStep && item.nextStepDate) {
      return (
        <KeyDatePersonItem
          key={item._id}
          keyDate={item}
          onUserClick={onUserClick}
        />
      );
    }
    if (item.date) {
      return <KeyDateHolidayItem key={item._id} keyDate={item} />;
    }
    return null;
  }, [onUserClick]);
  return (
    <Stack className="key-dates">
      <Stack direction="row" alignItems="center">
        <span className="session-label">Key Dates</span>
        <IconHelp style={{ marginLeft: 6 }} />
      </Stack>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={styles.label}>Occasion</TableCell>
            <TableCell className={styles.label}>Date</TableCell>
            <TableCell className={styles.label}>Event</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>{keyDates.map(renderKeyDateItem)}</TableBody>
      </Table>
    </Stack>
  );
};

export default memo(DashboardKeyDates);
