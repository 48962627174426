import React, { memo } from "react";

const IconArrowRight = (props: any) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="18"
        width="18"
        height="18"
        rx="9"
        transform="rotate(-90 0 18)"
        fill="#F3ECF9"
      />
      <path
        d="M11.2314 9.00039C11.2314 9.10039 11.2127 9.19714 11.1752 9.29064C11.1377 9.38464 11.0877 9.46289 11.0252 9.52539L7.57516 12.9754C7.43766 13.1129 7.26266 13.1816 7.05016 13.1816C6.83766 13.1816 6.66266 13.1129 6.52516 12.9754C6.38766 12.8379 6.31891 12.6629 6.31891 12.4504C6.31891 12.2379 6.38766 12.0629 6.52516 11.9254L9.45016 9.00039L6.52516 6.07539C6.38766 5.93789 6.31891 5.76289 6.31891 5.55039C6.31891 5.33789 6.38766 5.16289 6.52516 5.02539C6.66266 4.88789 6.83766 4.81914 7.05016 4.81914C7.26266 4.81914 7.43766 4.88789 7.57516 5.02539L11.0252 8.47539C11.1002 8.55039 11.1532 8.63164 11.1842 8.71914C11.2157 8.80664 11.2314 8.90039 11.2314 9.00039Z"
        fill="#6C3399"
      />
    </svg>
  );
};

export default memo(IconArrowRight);
