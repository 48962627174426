import React, { CSSProperties, memo } from "react";

interface IIconJob {
  style?: CSSProperties;
}

const IconJob = ({ style }: IIconJob) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.75 6.63104V5.46126C7.75 4.59501 8.38374 3.85912 9.2404 3.73062L10.4603 3.54763C11.8125 3.3448 13.1875 3.3448 14.5397 3.54763L15.7596 3.73062C16.6163 3.85912 17.25 4.59501 17.25 5.46126V6.63104L18.964 6.76939C20.2764 6.87533 21.347 7.86347 21.5574 9.16319C22.0226 12.0357 22.0226 14.9644 21.5574 17.8369C21.347 19.1367 20.2764 20.1248 18.964 20.2307L17.0921 20.3818C14.0357 20.6285 10.9643 20.6285 7.90787 20.3818L6.03595 20.2307C4.72357 20.1248 3.65303 19.1367 3.44255 17.8369C2.97736 14.9644 2.97736 12.0357 3.44255 9.16319C3.65303 7.86347 4.72357 6.87533 6.03596 6.76939L7.75 6.63104ZM10.6828 5.03104C11.8875 4.85033 13.1125 4.85033 14.3172 5.03104L15.5371 5.21402C15.6595 5.23238 15.75 5.33751 15.75 5.46126V6.52586C13.5851 6.40239 11.4149 6.40239 9.25 6.52586V5.46126C9.25 5.33751 9.34053 5.23238 9.46291 5.21402L10.6828 5.03104ZM8.02856 8.11343C11.0047 7.8732 13.9953 7.8732 16.9714 8.11343L18.8433 8.26453C19.4675 8.31491 19.9766 8.78485 20.0767 9.40298C20.1391 9.78791 20.1926 10.1739 20.2372 10.5607C15.3596 12.9625 9.64038 12.9625 4.76278 10.5607C4.80743 10.1739 4.86092 9.78791 4.92326 9.40298C5.02336 8.78485 5.53249 8.31491 6.15664 8.26453L8.02856 8.11343ZM4.62889 12.1567C9.62829 14.4304 15.3717 14.4304 20.3711 12.1567C20.4664 13.9729 20.3683 15.7967 20.0767 17.5971C19.9766 18.2153 19.4675 18.6852 18.8433 18.7356L16.9714 18.8867C13.9953 19.1269 11.0047 19.1269 8.02856 18.8867L6.15664 18.7356C5.53249 18.6852 5.02336 18.2153 4.92326 17.5971C4.63169 15.7967 4.53356 13.9729 4.62889 12.1567Z"
        fill="#6C3399"
      />
    </svg>
  );
};

export default memo(IconJob);
