import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import React, { memo, useCallback } from "react";
import styles from "./index.module.scss";
import { IITAsset } from "models/User";
import ITAssetItem from "./ITAssetItem";

interface IITAssetTable {
  data: IITAsset[];
}

const ITAssetTable = ({ data }: IITAssetTable) => {
  const renderRow = useCallback(
    (item: IITAsset, index: number) => (
      <ITAssetItem key={item.id} item={item} index={index} />
    ),
    []
  );
  if (!data || data.length === 0) return null;
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell className={styles.label}>No</TableCell>
          <TableCell className={styles.label}>Type</TableCell>
          <TableCell className={styles.label}>Date</TableCell>
          <TableCell className={styles.label}>Age (Yrs)</TableCell>
          <TableCell className={styles.label}>Brand</TableCell>
          <TableCell className={styles.label}>Model</TableCell>
          <TableCell className={styles.label}>Serial Number</TableCell>
          <TableCell className={styles.label}>Staff Allocated</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>{data.map(renderRow)}</TableBody>
    </Table>
  );
};

export default memo(ITAssetTable);
