import { ITimeSheet } from "models/TimeSheet";
import Caller from "./Caller";
import AppConfig from "common/AppConfig";

const TimeSheetAPI = {
  list: async (req: { page?: number }) => {
    return Caller.get<ITimeSheet[]>(
      `timesheets?${new URLSearchParams({
        page: `${req.page || 1}`,
        perPage: `${AppConfig.perPage}`,
      })}`
    );
  },
  find: async (req: { timesheetId: string }) =>
    Caller.get(
      `timesheets/findTimesheet?${new URLSearchParams({
        timesheetId: req.timesheetId,
        perPage: `${AppConfig.perPage}`,
      })}`
    ),
};

export default TimeSheetAPI;
