import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "./UserActions";
import api from "api";
import { IComment } from "models/Comment";

interface CommentState {
  commentData: {
    [key: string]: {
      data: IComment[];
      total: number;
      page: number;
      loading: boolean;
    };
  };
}

export const getListComment = createAsyncThunk(
  "comment/get-list",
  async (payload: { candidateId: string }) => {
    const res = await api.comment.list({ id: payload.candidateId });
    return res;
  }
);

const initialState: CommentState = {
  commentData: {},
};

const commentSlice = createSlice({
  name: "comment",
  initialState,
  reducers: {
    deleteComment: (
      state,
      action: PayloadAction<{ commentId: string; candidateId: string }>
    ) => {
      const { commentId, candidateId } = action.payload;
      if (candidateId && commentId && state.commentData?.[candidateId]) {
        state.commentData = {
          ...state.commentData,
          [candidateId]: {
            ...(state.commentData[candidateId] || {}),
            data: (state.commentData[candidateId]?.data || []).filter(
              (el) => el.id !== commentId
            ),
          },
        };
      }
    },
    addComment: (state, action: PayloadAction<IComment>) => {
      if (action.payload.candidate) {
        const candidateCommentData = {
          ...(state.commentData[action.payload.candidate.id] || {}),
          data: [
            action.payload,
            ...(state.commentData[action.payload.candidate.id]?.data || []),
          ],
        };
        state.commentData = {
          ...state.commentData,
          [action.payload.candidate.id]: candidateCommentData,
        };
      }
    },
    updateComment: (state, action: PayloadAction<IComment>) => {
      if (action.payload.candidate) {
        const candidateCommentData = {
          ...(state.commentData[action.payload.candidate.id] || {}),
          data: (
            state.commentData[action.payload.candidate.id]?.data || []
          ).map((el) => {
            if (el.id === action.payload.id) {
              return {
                ...el,
                content: action.payload.content,
                updatedAt: action.payload.updatedAt,
              };
            }
            return el;
          }),
        };
        state.commentData = {
          ...state.commentData,
          [action.payload.candidate.id]: candidateCommentData,
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutAction, (state) => {
        return initialState;
      })
      .addCase(getListComment.fulfilled, (state, action) => {
        if (action.payload.data) {
          state.commentData = {
            ...state.commentData,
            [action.meta.arg.candidateId]: {
              data: action.payload.data,
              loading: false,
              total: action.payload.total || 1,
              page: 1,
            },
          };
        }
      });
  },
});

export const COMMENT_ACTIONS = commentSlice.actions;

export default commentSlice.reducer;
