import React, { memo, useCallback, useState } from "react";
import styles from "./index.module.scss";
import {
  CircularProgress,
  Grid,
  MenuItem,
  Stack,
  TextField,
} from "@mui/material";
import useUserRoles from "hooks/useUserRoles";
import IconArrowSelect from "components/SVGs/IconArrowSelect";
import api from "api";
import { ICreateUserData } from "models/User";
import { useToast } from "providers/ToastProvider";
import useAppDispatch from "hooks/useAppDispatch";
import { getUsers } from "reducers/MetadataReducers";

const CreateNewUser = () => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const toast = useToast();
  const roles = useUserRoles();
  const [createUserData, setCreateUserData] = useState<ICreateUserData>({
    firstName: "",
    lastName: "",
    email: "",
    role: "",
  });
  const onChangeFirstName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCreateUserData((current) => ({
        ...current,
        firstName: e.target.value,
      }));
    },
    []
  );
  const onChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCreateUserData((current) => ({
        ...current,
        email: e.target.value,
      }));
    },
    []
  );
  const onChangeLastName = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setCreateUserData((current) => ({
        ...current,
        lastName: e.target.value,
      }));
    },
    []
  );
  const onChangeRole = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setCreateUserData((current) => ({
      ...current,
      role: e.target.value,
    }));
  }, []);
  const onCreateClick = useCallback(async () => {
    if (loading) return;
    setLoading(true);
    const res = await api.user.create(createUserData);
    if (res.statusCode === 201) {
      toast.success({ message: res.message || "Create user successful" });
    } else {
      toast.error({ message: res.message || "Something wrong" });
    }
    setCreateUserData({
      firstName: "",
      lastName: "",
      email: "",
      role: "",
    });
    dispatch(getUsers({}));
    setLoading(false);
  }, [createUserData, dispatch, loading, toast]);
  return (
    <div className={styles["create-user-container"]}>
      <h3 className={styles.title}>Create New User</h3>
      <Grid container marginTop="0px" spacing="35px">
        <Grid item md={12} lg={6}>
          <Stack direction="row" alignItems="center">
            <span className={styles["label"]}>First Name</span>
            <TextField
              placeholder="Enter first name"
              fullWidth
              value={createUserData.firstName}
              onChange={onChangeFirstName}
            />
          </Stack>
        </Grid>
        <Grid item md={12} lg={6}>
          <Stack direction="row" alignItems="center">
            <span className={styles["label"]}>User's Email</span>
            <TextField
              placeholder="Enter email"
              fullWidth
              value={createUserData.email}
              onChange={onChangeEmail}
            />
          </Stack>
        </Grid>
      </Grid>
      <div className={styles.line} />
      <Grid container marginTop="0px" spacing="35px">
        <Grid item md={12} lg={6}>
          <Stack direction="row" alignItems="center">
            <span className={styles["label"]}>Last Name</span>
            <TextField
              placeholder="Enter last name"
              fullWidth
              value={createUserData.lastName}
              onChange={onChangeLastName}
            />
          </Stack>
        </Grid>
        <Grid item md={12} lg={6}>
          <Stack direction="row" alignItems="center">
            <span className={styles["label"]}>User Role</span>
            <TextField
              select
              value={createUserData.role}
              onChange={onChangeRole}
              SelectProps={{
                IconComponent: (props) => (
                  <IconArrowSelect {...props} style={{ right: 13 }} />
                ),
                displayEmpty: true,
              }}
              fullWidth
            >
              <MenuItem value="" style={{ display: "none" }}>
                <span className={styles["placeholder"]}>Select Role</span>
              </MenuItem>
              {roles?.map((el) => (
                <MenuItem key={el.value} value={el.value}>
                  {el.label}
                </MenuItem>
              ))}
            </TextField>
          </Stack>
        </Grid>
      </Grid>
      <Grid container marginTop="0px" spacing="35px">
        <Grid item md={12} lg={6}></Grid>
        <Grid item md={12} lg={6}>
          <Stack direction="row" alignItems="center">
            <span className={styles["label"]}></span>
            <Stack className={styles["btn-create"]} onClick={onCreateClick}>
              {loading ? (
                <CircularProgress color="inherit" size={15} />
              ) : (
                <span>Create New User</span>
              )}
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(CreateNewUser);
