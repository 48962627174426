import { Stack } from "@mui/material";
import images from "common/images";
import MenuItem from "components/MenuItem";
import IconDashboard from "components/SVGs/IconDashboard";
import IconManage from "components/SVGs/IconManage";
import IconRecruit from "components/SVGs/IconRecruit";
import IconRequisition from "components/SVGs/IconRequisition";
import React, { memo, useCallback, useRef } from "react";
import styles from "./index.module.scss";
import useUser from "hooks/useUser";
import IconChevronDown from "components/SVGs/IconChevronDown";
import IconBilling from "components/SVGs/IconBilling";
import IconTimeSheet from "components/SVGs/IconTimeSheet";
import PopoverButton from "components/PopoverButton";
import { UserPermission } from "models/User";
import MenuUser from "./MenuUser";
import IconITAsset from "components/SVGs/IconITAsset";

const SideBar = () => {
  const user = useUser();
  const popupUserMenuRef = useRef<any>();
  const onCloseMenu = useCallback(() => {
    popupUserMenuRef.current.hide();
  }, []);
  return (
    <Stack minWidth={250} height="100vh" className={styles.container}>
      <img
        src={user.client?.logo?.src || images.imgEyeBall}
        alt="logo"
        className={styles.logo}
      />
      <Stack direction="row" className={styles["user__wrapper"]}>
        <img
          src={
            user.avatar?.src || user.client?.logo?.src || images.sampleAvatar
          }
          alt="user-avatar"
          className={styles.avatar}
        />
        <Stack marginLeft="10px">
          <span className="font-medium text-white">{user.username}</span>
          <PopoverButton
            ref={popupUserMenuRef}
            componentPopup={<MenuUser onClose={onCloseMenu} />}
            itemWidth={100}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            removeBackground
            componentButton={
              <Stack
                direction="row"
                alignItems="center"
                className="cursor-pointer"
              >
                <span className="text-sm text-white">{user.role}</span>
                <IconChevronDown fill="white" style={{ marginLeft: 5 }} />
              </Stack>
            }
          />
        </Stack>
      </Stack>
      <Stack marginTop="48px">
        <span className={styles["menu-label"]}>Main</span>
        {user.permissions.includes(UserPermission.SeeDashboard) && (
          <MenuItem
            to="/dashboard"
            name="Dashboard"
            icon={<IconDashboard fill="var(--color-menu-icon)" />}
          />
        )}
        {user.permissions.includes(UserPermission.Requisition) && (
          <MenuItem
            to="/requisition"
            name="Requisition"
            icon={<IconRequisition fill="var(--color-menu-icon)" />}
          />
        )}
        {user.permissions.includes(UserPermission.Recruit) && (
          <MenuItem
            to="/recruit"
            name="Recruit"
            icon={<IconRecruit fill="var(--color-menu-icon)" />}
          />
        )}
        {user.permissions.includes(UserPermission.ManageTeam) && (
          <MenuItem
            to="/manage"
            name="Your Team"
            icon={<IconManage fill="var(--color-menu-icon)" />}
          />
        )}
        {user.permissions.includes(UserPermission.AccessBilling) && (
          <MenuItem
            to="/billing"
            name="Billing"
            icon={<IconBilling fill="var(--color-menu-icon)" />}
          />
        )}
        {user.permissions.includes(UserPermission.Timesheets) && (
          <MenuItem
            to="/time-sheets"
            name="Time Sheets"
            icon={<IconTimeSheet fill="var(--color-menu-icon)" />}
          />
        )}
        <MenuItem
          to="/it-assets"
          name="IT Assets"
          icon={<IconITAsset fill="var(--color-menu-icon)" />}
        />
      </Stack>
    </Stack>
  );
};

export default memo(SideBar);
