import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {} from "models";
import { logoutAction } from "./UserActions";
import api from "api";
import {
  IEnglishLevel,
  IJobFamily,
  IJobLevel,
  ISkillTesting,
} from "models/Metadata";
import { IUser } from "models/User";

interface MetadataState {
  englishLevels?: IEnglishLevel[];
  jobLevels?: IJobLevel[];
  jobFamilies?: IJobFamily[];
  skillsTesting?: ISkillTesting[];
  users?: IUser[];
  loading?: boolean;
}

const initialState: MetadataState = {
  englishLevels: [],
  jobFamilies: [],
  jobLevels: [],
  skillsTesting: [],
  users: [],
  loading: false,
};

export const getMetadata = createAsyncThunk(
  "metadata/get",
  async (_, store: any) => {
    const clientId = store.getState()?.user?.data?.client?.id || "";
    const [resEnglishLevel, resJobLevel, resJobFamily, resSkillTesting] =
      await Promise.all([
        api.metadata.getEnglishLevel(),
        api.metadata.getJobLevel(clientId),
        api.metadata.getJobFamily(clientId),
        api.metadata.getSkillTesting(clientId),
      ]);
    return {
      resEnglishLevel,
      resJobLevel,
      resJobFamily,
      resSkillTesting,
    };
  }
);

export const getUsers = createAsyncThunk(
  "metadata/get-user",
  async (payload?: {
    page?: number;
    perPage?: number;
    sortName?: string;
    sortOrder?: string;
  }) => {
    const resUsers = await api.metadata.getUsers(payload);
    return resUsers;
  }
);

const metadataSlice = createSlice({
  name: "metadata",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logoutAction, () => {
        return initialState;
      })
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsers.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload.data || [];
      })
      .addCase(getMetadata.pending, (state: MetadataState) => {
        state.loading = true;
      })
      .addCase(getMetadata.rejected, (state: MetadataState) => {
        state.loading = false;
      })
      .addCase(getMetadata.fulfilled, (state: MetadataState, action) => {
        const { resEnglishLevel, resJobLevel, resJobFamily, resSkillTesting } =
          action.payload;
        state.englishLevels = resEnglishLevel.data || [];
        state.jobLevels = resJobLevel.data || [];
        state.jobFamilies = resJobFamily.data || [];
        state.skillsTesting = resSkillTesting.data || [];
        state.loading = false;
      });
  },
});

export const METADATA_ACTIONS = metadataSlice.actions;

export default metadataSlice.reducer;
