import { LoadingButton } from "@mui/lab";
import { Box, Stack, TextField } from "@mui/material";
import { useAuth } from "providers/AuthProvider";
import React, { memo, useCallback } from "react";
import { Link } from "react-router-dom";
import styles from "./index.module.scss";
import images from "common/images";

const Login = () => {
  const auth = useAuth();
  const handleSubmit = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const data = new FormData(event.currentTarget);
      const email = data.get("email")?.toString() || "";
      const password = data.get("password")?.toString() || "";
      if (email && password) {
        auth.login({ email, password });
      }
    },
    [auth]
  );
  return (
    <main className={styles.container}>
      <Stack className={styles.body}>
        <img src={images.logoAuthenticate} className={styles.logo} alt="logo" />
        <h1 className={styles.title}>Sign in</h1>
        <Box
          component="form"
          onSubmit={handleSubmit}
          noValidate
          sx={{ mt: "30px" }}
          style={{ display: "flex", flexDirection: "column" }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
          >
            <span className={styles.label}>Email</span>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              placeholder="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            marginTop="24px"
            justifyContent="space-between"
          >
            <span className={styles.label}>Password</span>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              placeholder="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
          </Stack>
          <Stack alignSelf="flex-end">
            <LoadingButton
              type="submit"
              variant="contained"
              loading={auth.loadingLogin}
              className={styles.button}
            >
              Sign In
            </LoadingButton>
            <Link to="/reset-password" className={styles.forgot}>
              Forgot password?
            </Link>
          </Stack>
        </Box>
      </Stack>
    </main>
  );
};

export default memo(Login);
