import Caller from "./Caller";
import {
  IComment,
  ICreateComment,
  IDeleteComment,
  IUpdateComment,
} from "models/Comment";

const CommentAPI = {
  create: async (body: ICreateComment) =>
    Caller.post<IComment>("comments", body),
  list: async (req: { id: string }) => {
    return Caller.get<IComment[]>(`comments?page=1&perPage=20&id=${req.id}`);
  },
  update: async (body: IUpdateComment) => {
    return Caller.put("comments", body);
  },
  delete: async (body: IDeleteComment) => {
    return Caller.delete("comments", body);
  },
};

export default CommentAPI;
