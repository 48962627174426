import Caller from "./Caller";
import {
  IRateApiData,
  IRequisition,
  RequisitionRequest,
} from "models/Requisition";

const RequisitionAPI = {
  list: async () =>
    Caller.get<IRequisition[]>("requisitions?page=1&perPage=20"),
  create: async (req: RequisitionRequest) => {
    const form = new FormData();
    form.append("client", req.client);
    form.append("jobTitle", req.jobTitle);
    form.append("jobFamily", JSON.stringify(req.jobFamily));
    form.append("jobLevel", JSON.stringify(req.jobLevel));
    form.append("skillTesting", JSON.stringify(req.skillTesting));
    form.append("english", JSON.stringify(req.english));
    form.append("openDate", req.openDate);
    form.append("hiringManager", req.hiringManager);
    form.append("adhocRateOffers", `${req.adhocRateOffers}`);
    form.append("minRate", `${req.minRate}`);
    form.append("maxRate", `${req.maxRate}`);
    if (req.file) {
      form.append("file", req.file);
    }
    return Caller.post("requisitions", form);
  },
  getRate: (req: {
    clientId: string;
    jobFamilies: string[];
    jobLevels: string[];
  }) => Caller.post<IRateApiData>("requisitions/getRateForRequisition", req),
};

export default RequisitionAPI;
