import { Stack, TableCell, TableRow } from "@mui/material";
import React, { memo, useCallback, useMemo } from "react";
import styles from "./index.module.scss";
import moment from "moment";
import IconDownload from "components/SVGs/IconDownload";
import { ITimeSheet } from "models/TimeSheet";
import IconFiles from "components/SVGs/IconFiles";
import { getFormattedFileSize } from "helpers/formatter";
import { useLocation } from "react-router-dom";

interface ITimeSheetItem {
  item: ITimeSheet;
}

const TimeSheetItem = ({ item }: ITimeSheetItem) => {
  const location = useLocation();
  const onDownload = useCallback(() => {
    if (item.downloadFile?.src) {
      window.open(item.downloadFile?.src, "_blank");
    }
  }, [item.downloadFile?.src]);
  const date = useMemo(
    () =>
      `${moment(item.fromDate).format("MM/DD/YYYY")} - ${moment(
        item.toDate
      ).format("MM/DD/YYYY")}`,
    [item.fromDate, item.toDate]
  );
  return (
    <TableRow
      className={`${location.hash === `#${item.id}` ? styles.active : ""}`}
    >
      <TableCell className={styles.name} style={{ padding: "15px 0" }}>
        <Stack direction="row" alignItems="center">
          <IconFiles fill="var(--color-accent)" style={{ marginRight: 10 }} />
          <span>{item.document}</span>
        </Stack>
      </TableCell>
      <TableCell className={styles.name} style={{ padding: "15px 0" }}>
        {date}
      </TableCell>
      <TableCell className={styles.name} style={{ padding: "15px 0" }}>
        {getFormattedFileSize(item.size)}
      </TableCell>
      <TableCell style={{ padding: "15px 0" }}>
        {item.downloadFile?.src && (
          <Stack className={styles["download-btn"]} onClick={onDownload}>
            <span>Download</span>
            <IconDownload style={{ marginLeft: 5 }} />
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
};

export default memo(TimeSheetItem);
