import { ICandidate } from "./Candidate";

export enum UserPermission {
  CreateEditUser = "Create / Edit User",
  AssignRoles = "Assign Roles",
  SeeDashboard = "See Dashboard",
  AccessBilling = "Access Billing",
  Timesheets = "Timesheets",
  Requisition = "Requisition",
  Recruit = "Recruit",
  ManageTeam = "Manage Team",
  SeeRates = "See Rates",
}

// export type UserPermission =
//   | "Create / Edit User"
//   | "Assign Roles"
//   | "See Dashboard"
//   | "Access Billing"
//   | "Timesheets"
//   | "Requisition"
//   | "Recruit"
//   | "Manage Team"
//   | "See Rates";

export interface IClient {
  id: string;
  name: string;
  _id: string;
  logo?: {
    src: string;
    title: string;
  };
}

export interface IUser {
  id: string;
  username: string;
  user_avatar?: string;
  role: string;
  email: string;
  active?: boolean;
  avatar?: {
    src?: string;
    title?: string;
  };
  client: IClient;
  permissions: UserPermission[];
}

export const tempUser: IUser = {
  id: "1",
  username: "Quynh Tranh",
  role: "Owner",
  email: "quynh.dev@gmail.com",
  client: {
    id: "",
    name: "",
    _id: "",
  },
  permissions: [],
};

export interface INotification {
  user: string;
  content: string;
  read: boolean;
  additionalInfo: string;
  candidate?: ICandidate;
  createdAt: string;
  updatedAt: string;
  id: string;
  type?: string;
}

export interface ISortData {
  title: string;
  type: "ASC" | "DESC";
}

export interface ICreateUserData {
  firstName: string;
  lastName: string;
  email: string;
  role: string;
}

export interface IITAsset {
  type: string;
  date: string;
  age: number;
  brand: string;
  model: string;
  serialNumber: string;
  client?: {
    name: string;
    id: string;
  };
  staff?: {
    name: string;
    id: string;
  };
  id: string;
}
