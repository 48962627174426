import React, { memo } from "react";

const IconArrowSelect = (props: any) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect width="18" height="18" rx="9" fill="#F3ECF9" />
      <path
        d="M8.99985 11.2313C8.89985 11.2313 8.8031 11.2126 8.7096 11.1751C8.6156 11.1376 8.53735 11.0876 8.47485 11.0251L5.02485 7.5751C4.88735 7.4376 4.8186 7.2626 4.8186 7.0501C4.8186 6.8376 4.88735 6.6626 5.02485 6.5251C5.16235 6.3876 5.33735 6.31885 5.54985 6.31885C5.76235 6.31885 5.93735 6.3876 6.07485 6.5251L8.99985 9.4501L11.9249 6.5251C12.0624 6.3876 12.2374 6.31885 12.4499 6.31885C12.6624 6.31885 12.8374 6.3876 12.9749 6.5251C13.1124 6.6626 13.1811 6.8376 13.1811 7.0501C13.1811 7.2626 13.1124 7.4376 12.9749 7.5751L9.52485 11.0251C9.44985 11.1001 9.3686 11.1531 9.2811 11.1841C9.1936 11.2156 9.09985 11.2313 8.99985 11.2313Z"
        fill="#6C3399"
      />
    </svg>
  );
};

export default memo(IconArrowSelect);
