import { TableCell, TableRow } from "@mui/material";
import React, { memo } from "react";
import styles from "./index.module.scss";
import moment from "moment";
import { IITAsset } from "models/User";

interface IBillingItem {
  item: IITAsset;
  index: number;
}

const BillingItem = ({ item, index }: IBillingItem) => {
  return (
    <TableRow>
      <TableCell className={styles.name} style={{ padding: "15px 0" }}>
        {index + 1}
      </TableCell>
      <TableCell className={styles.name} style={{ padding: "15px 0" }}>
        {item.type}
      </TableCell>
      <TableCell style={{ padding: "15px 0" }}>
        {moment(item.date).format("D MMM YYYY")}
      </TableCell>
      <TableCell style={{ padding: "15px 0" }}>{item.age}</TableCell>
      <TableCell style={{ padding: "15px 0" }}>{item.brand}</TableCell>
      <TableCell style={{ padding: "15px 0" }}>{item.model}</TableCell>
      <TableCell style={{ padding: "15px 0" }}>{item.serialNumber}</TableCell>
      <TableCell style={{ padding: "15px 0" }}>{item.staff?.name}</TableCell>
    </TableRow>
  );
};

export default memo(BillingItem);
