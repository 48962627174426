import React, { CSSProperties, memo } from "react";

interface IIconFiles {
  fill?: string;
  style?: CSSProperties;
}

const IconFiles = ({ fill = "var(--color-accent)", style }: IIconFiles) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        d="M21.0001 17.3333C21.0001 16.781 20.5524 16.3333 20.0001 16.3333H12.0001C11.4478 16.3333 11.0001 16.781 11.0001 17.3333C11.0001 17.8856 11.4478 18.3333 12.0001 18.3333H20.0001C20.5524 18.3333 21.0001 17.8856 21.0001 17.3333Z"
        fill={fill}
      />
      <path
        d="M21.0001 22.6667C21.0001 22.1144 20.5524 21.6667 20.0001 21.6667H12.0001C11.4478 21.6667 11.0001 22.1144 11.0001 22.6667C11.0001 23.219 11.4478 23.6667 12.0001 23.6667H20.0001C20.5524 23.6667 21.0001 23.219 21.0001 22.6667Z"
        fill={fill}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33341 3C7.30837 3 5.66675 4.64162 5.66675 6.66667V25.3333C5.66675 27.3584 7.30837 29 9.33341 29H22.6667C24.6918 29 26.3334 27.3584 26.3334 25.3333V10.6235C26.3334 10.1158 26.1678 9.62201 25.8618 9.21693L21.8647 3.92672C21.4237 3.34306 20.7346 3 20.003 3H9.33341ZM7.66675 6.66667C7.66675 5.74619 8.41294 5 9.33341 5H19.0001V10.8627C19.0001 11.415 19.4478 11.8627 20.0001 11.8627H24.3334V25.3333C24.3334 26.2538 23.5872 27 22.6667 27H9.33341C8.41294 27 7.66675 26.2538 7.66675 25.3333V6.66667Z"
        fill={fill}
      />
    </svg>
  );
};

export default memo(IconFiles);
