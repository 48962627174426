import React from "react";
import "./App.scss";
import "./mui.scss";
import Routers from "routers";

function App() {
  return <Routers />;
}

export default App;
