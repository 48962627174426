import {
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { ICandidate } from "models/Candidate";
import React, { memo, useCallback, useMemo } from "react";
import styles from "./index.module.scss";
import IconArrowSelect from "components/SVGs/IconArrowSelect";
import CandidateItem from "components/CandidateItem";
import { ISortData, UserPermission } from "models/User";
import useUser from "hooks/useUser";

interface ICandidateTable {
  candidates?: ICandidate[];
  onCandidateClick: (candidate: ICandidate) => void;
  onSortClick: (title: string) => void;
  sortData?: ISortData | null;
  onPageChange: (e: any, page: number) => void;
  totalPage: number;
  page: number;
}

const CandidateTable = ({
  candidates,
  onCandidateClick,
  onSortClick,
  sortData,
  onPageChange,
  totalPage,
  page,
}: ICandidateTable) => {
  const user = useUser();
  const canSeeRate = useMemo(
    () => user.permissions.includes(UserPermission.SeeRates),
    [user.permissions]
  );
  const renderRow = useCallback(
    (candidate: ICandidate) => (
      <CandidateItem
        key={candidate.id}
        candidate={candidate}
        onCandidateClick={onCandidateClick}
        canSeeRate={canSeeRate}
      />
    ),
    [canSeeRate, onCandidateClick]
  );
  const handleSortAvailableFrom = useCallback(() => {
    onSortClick("availableFrom");
  }, [onSortClick]);
  const handleSortEnglish = useCallback(() => {
    onSortClick("english");
  }, [onSortClick]);
  const handleSortRate = useCallback(() => {
    onSortClick("rate");
  }, [onSortClick]);
  const getRotateValue = useCallback(
    (title: string) => {
      return sortData?.title === title && sortData?.type === "ASC"
        ? "180deg"
        : "0deg";
    },
    [sortData?.title, sortData?.type]
  );
  if (!candidates || candidates.length === 0) return null;
  return (
    <Stack flex={1}>
      <Stack style={{ marginTop: 25, flex: 1 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={styles.label}>Staff</TableCell>
              <TableCell className={styles.label}>
                <Stack
                  direction="row"
                  alignItems="center"
                  className="cursor-pointer"
                  onClick={handleSortAvailableFrom}
                >
                  <span>Available from</span>
                  <IconArrowSelect
                    style={{
                      marginLeft: 10,
                      rotate: getRotateValue("availableFrom"),
                    }}
                  />
                </Stack>
              </TableCell>
              <TableCell className={styles.label}>
                <Stack
                  direction="row"
                  alignItems="center"
                  className="cursor-pointer"
                  onClick={handleSortEnglish}
                >
                  <span>English</span>
                  <IconArrowSelect
                    style={{
                      marginLeft: 10,
                      rotate: getRotateValue("english"),
                    }}
                  />
                </Stack>
              </TableCell>
              {canSeeRate && (
                <TableCell className={styles.label}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    className="cursor-pointer"
                    onClick={handleSortRate}
                    justifyContent="center"
                  >
                    <span>Rate</span>
                    <IconArrowSelect
                      style={{
                        marginLeft: 10,
                        rotate: getRotateValue("rate"),
                      }}
                    />
                  </Stack>
                </TableCell>
              )}
              <TableCell className={styles.label} style={{ padding: "0 10px" }}>
                Source
              </TableCell>
              <TableCell className={styles.label}>Current Stage</TableCell>
              <TableCell className={styles.label} align="center">
                Proceed?
              </TableCell>
              <TableCell className={styles.label}>Next Stage</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{candidates.map(renderRow)}</TableBody>
        </Table>
      </Stack>
      {totalPage > 1 && (
        <Pagination
          count={totalPage}
          shape="rounded"
          style={{ margin: "25px 0", alignSelf: "flex-end" }}
          onChange={onPageChange}
          page={page}
        />
      )}
    </Stack>
  );
};

export default memo(CandidateTable);
