import React, { memo, useMemo } from "react";
import { Stack } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import styles from "./index.module.scss";

interface IMenuItem {
  to: string;
  name: string;
  icon: React.ReactNode;
  prefixIcon?: React.ReactNode;
}

const MenuItem = ({ to, name, icon, prefixIcon }: IMenuItem) => {
  const location = useLocation();
  const isActive = useMemo(
    () => location.pathname === to,
    [location.pathname, to]
  );
  return (
    <Link
      to={to}
      style={{
        paddingLeft: 25,
        paddingRight: 35,
        marginTop: 20,
        textDecoration: "none",
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        height={38}
        padding="0 18px"
        borderRadius="14px"
        className={`${styles.container} ${isActive ? styles.active : ""}`}
      >
        {icon}
        <span
          className="ml-2.5 text-white text-sm flex-1"
          style={{ opacity: isActive ? 1 : 0.7 }}
        >
          {name}
        </span>
        {prefixIcon && prefixIcon}
      </Stack>
    </Link>
  );
};

export default memo(MenuItem);
