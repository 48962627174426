import React, { memo } from "react";
import { Stack } from "@mui/material";
import RecruitmentFunnelItem from "components/RecruitmentFunnelItem";
import IconChevronDown from "components/SVGs/IconChevronDown";
import { DashboardChartData } from "models";

interface IRecruitmentFunnel {
  recruitment?: DashboardChartData[];
  selectedRole: string;
  onRoleClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

const RecruitmentFunnel = ({
  recruitment,
  selectedRole,
  onRoleClick,
}: IRecruitmentFunnel) => {
  return (
    <>
      <Stack direction="row" alignItems="center">
        <Stack>
          <span className={"session-label"}>Recruitment Funnel</span>
        </Stack>
        <Stack
          className="cursor-pointer items-center justify-center bg-accent"
          direction="row"
          borderRadius="14px"
          marginLeft="40px"
          padding="0 18px"
          height={38}
          onClick={onRoleClick}
        >
          <span className="text-sm text-white">{selectedRole}</span>
          <IconChevronDown fill="white" style={{ marginLeft: 5 }} />
        </Stack>
      </Stack>
      <Stack marginTop="5px">
        {recruitment?.map((el, index) => (
          <RecruitmentFunnelItem
            key={el.name}
            funnel={el}
            color={`var(--color-chart-${index >= 5 ? 6 : index + 1})`}
          />
        ))}
      </Stack>
    </>
  );
};

export default memo(RecruitmentFunnel);
