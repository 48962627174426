import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "./UserActions";
import api from "api";
import { IBill } from "models/Bill";
import AppConfig from "common/AppConfig";

interface BillState {
  billings: IBill[];
  loading?: boolean;
  totalPage: number;
}

const initialState: BillState = {
  billings: [],
  loading: false,
  totalPage: 1,
};

export const getBillings = createAsyncThunk(
  "bill/list",
  async (payload: { page: number }) => {
    const res = await api.bill.list(payload);
    return res;
  }
);

const billSlice = createSlice({
  name: "bill",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logoutAction, (state) => {
        return initialState;
      })
      .addCase(getBillings.fulfilled, (state, action) => {
        state.billings = action.payload.data || [];
        state.loading = false;
        state.totalPage = Math.ceil(
          (action.payload?.total || 0) / AppConfig.perPage
        );
      })
      .addCase(getBillings.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(getBillings.rejected, (state, action) => {
        state.loading = false;
      });
  },
});

export const BILL_ACTIONS = billSlice.actions;

export default billSlice.reducer;
