import { CircularProgress, Stack, TableCell, TableRow } from "@mui/material";
import { ICandidate } from "models/Candidate";
import React, { memo, useCallback, useMemo, useState } from "react";
import styles from "./index.module.scss";
import images from "common/images";
import moment from "moment";
import IconChevronRight from "components/SVGs/IconChevronRight";
import api from "api";
import useUser from "hooks/useUser";
import { useLocation } from "react-router-dom";

interface IManageItem {
  candidate: ICandidate;
  onCandidateClick: (candidate: ICandidate) => void;
}

const ManageItem = ({ candidate, onCandidateClick }: IManageItem) => {
  const location = useLocation();
  const user = useUser();
  const jobTitle = useMemo(() => {
    return `${candidate.jobLevel?.abbr} ${candidate.jobFamily?.name}`;
  }, [candidate.jobFamily?.name, candidate.jobLevel?.abbr]);
  const [nextLoading, setNextLoading] = useState(false);
  const onGoToNextClick = useCallback(async () => {
    if (nextLoading) return;
    setNextLoading(true);
    await api.candidate.goToNextStep({
      candidateId: candidate.id,
      clientId: user.client.id,
    });
    setNextLoading(false);
  }, [candidate.id, nextLoading, user.client.id]);
  const handleCandidateClick = useCallback(() => {
    onCandidateClick(candidate);
  }, [candidate, onCandidateClick]);
  return (
    <TableRow
      className={`${location.hash === `#${candidate.id}` ? styles.active : ""}`}
    >
      <TableCell style={{ padding: "15px 0" }}>
        <Stack
          direction="row"
          alignItems="center"
          className="cursor-pointer"
          onClick={handleCandidateClick}
        >
          <img
            alt="avatar"
            src={
              candidate.avatar?.src ||
              user.client?.logo?.src ||
              images.sampleAvatar
            }
            className={styles.avatar}
          />
          <Stack marginLeft="10px">
            <span className={styles.name}>{candidate.name}</span>
            <span
              className={styles.description}
              style={{ fontSize: 13, lineHeight: "18px" }}
            >
              {jobTitle}
            </span>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell className={styles.name} style={{ padding: "15px 0" }}>
        {candidate.owner?.username}
      </TableCell>
      <TableCell style={{ padding: "15px 0" }} width={191}>
        <Stack direction="row" alignItems="center" className={styles.english}>
          <div className={styles.dot} />
          <span className={`${styles["english-text"]} truncate`}>
            {candidate.jobFamily?.name}
          </span>
        </Stack>
      </TableCell>
      <TableCell className={styles.name} style={{ padding: "15px 0" }}>
        {moment(candidate.joinDate).format("MM/DD/YYYY")}
      </TableCell>
      <TableCell className={styles.name} style={{ padding: "15px 0" }}>
        {candidate.nextStepDate
          ? moment(candidate.nextStepDate).format("MM/DD/YYYY")
          : ""}
      </TableCell>
      <TableCell style={{ padding: "15px 0" }}>
        {candidate.nextStep ? (
          <Stack
            className={`${styles["next-step"]} cursor-pointer`}
            onClick={onGoToNextClick}
          >
            <span style={{ flex: 1 }} className="truncate">
              {candidate.nextStep.actionButton}
            </span>
            {nextLoading ? (
              <CircularProgress size={20} color="inherit" />
            ) : (
              <IconChevronRight />
            )}
          </Stack>
        ) : (
          <span className={styles.name}>No action needed</span>
        )}
      </TableCell>
    </TableRow>
  );
};

export default memo(ManageItem);
