import { Stack } from "@mui/material";
import React, { memo } from "react";
import styles from "./index.module.scss";
import { DashboardChartData } from "models";
import numeral from "numeral";

interface IRecruitmentFunnelItem {
  funnel: DashboardChartData;
  color: string;
}

const RecruitmentFunnelItem = ({ funnel, color }: IRecruitmentFunnelItem) => {
  return (
    <Stack direction="row" alignItems="center" marginTop="20px">
      <Stack direction="row" alignItems="center" flex={1} minWidth={185}>
        <Stack
          direction="row"
          alignItems="center"
          flex={1}
          justifyContent="space-between"
          marginRight="5px"
        >
          <span className="text-sm text-main">{funnel.name}</span>
          <span
            className="font-semibold text-xl text-main"
            style={{ lineHeight: "18px" }}
          >
            {funnel.count}
          </span>
        </Stack>
        <Stack
          width={35}
          direction="row"
          justifyContent="flex-end"
          marginLeft="15px"
        >
          <span className={styles.percentage}>
            {numeral(funnel.percentage * 100).format("0")}%
          </span>
        </Stack>
      </Stack>
      <Stack flex={7} marginLeft="10px">
        <Stack
          height={18}
          width={`${funnel.percentage * 100}%`}
          style={{
            backgroundColor: color,
            borderRadius: 10,
          }}
        />
      </Stack>
    </Stack>
  );
};

export default memo(RecruitmentFunnelItem);
