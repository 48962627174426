import AppConfig from "common/AppConfig";
import Caller from "./Caller";
import { IAction, ICandidate } from "models/Candidate";

const CandidateAPI = {
  list: async (req: {
    selectedFilter: string;
    selectedJobTitle: string;
    sortName?: string;
    sortOrder?: "ASC" | "DESC";
    page?: number;
  }) => {
    return Caller.get<ICandidate[]>(
      `candidates?${new URLSearchParams({
        page: `${req.page || 1}`,
        perPage: `${AppConfig.perPage}`,
        lastStep: req.selectedFilter,
        requisition: req.selectedJobTitle === "All" ? "" : req.selectedJobTitle,
        sortName: req.sortName || "",
        sortOrder: req.sortOrder || "",
      })}`
    );
  },
  find: async (req: { candidateId: string }) =>
    Caller.get(
      `candidates/findCandidate?${new URLSearchParams({
        candidateId: req.candidateId,
        perPage: `${AppConfig.perPage}`,
      })}`
    ),
  listManage: async (req: {
    sortName?: string;
    sortOrder?: "ASC" | "DESC";
    page: number;
  }) => {
    return Caller.get<ICandidate[]>(
      `manages?${new URLSearchParams({
        page: `${req.page}`,
        perPage: `${AppConfig.perPage}`,
        sortName: req.sortName || "",
        sortOrder: req.sortOrder || "",
      })}`
    );
  },
  findManage: async (req: { candidateId: string }) =>
    Caller.get(
      `manages/findCandidate?${new URLSearchParams({
        candidateId: req.candidateId,
        perPage: `${AppConfig.perPage}`,
      })}`
    ),
  status: async (isAfterHired: boolean) => {
    return Caller.get<IAction[]>(`actions?isAfterHired=${isAfterHired}`);
  },
  goToNextStep: async (req: { candidateId: string; clientId: string }) =>
    Caller.post("candidates/candidateGoToNextStep", req),
  failedStep: async (req: { candidateId: string; clientId: string }) =>
    Caller.post("candidates/candidateFailStep", req),
  byId: async (id: string) => Caller.get<ICandidate>(`candidates/${id}`),
};

export default CandidateAPI;
