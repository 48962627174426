import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { logoutAction } from "./UserActions";
import { IDashboard, IHoliday } from "models/Dashboard";
import api from "api";

interface DashboardState {
  data?: IDashboard;
  loading?: boolean;
  holidays?: IHoliday[];
}

export const getHolidays = createAsyncThunk(
  "dashboard/get-holidays",
  async () => {
    const holidaysRes = await api.dashboard.getHolidays();
    return {
      holidaysRes,
    };
  }
);

export const getDashboardData = createAsyncThunk(
  "dashboard/get",
  async (payload: { jobFamily?: string }) => {
    const res = await api.dashboard.get(payload);
    return {
      res,
    };
  }
);

const initialState: DashboardState = {};

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logoutAction, (state: DashboardState) => {
        return initialState;
      })
      .addCase(getHolidays.fulfilled, (state, action) => {
        if (action.payload.holidaysRes?.data) {
          state.holidays = action.payload.holidaysRes?.data;
        }
      })
      .addCase(getDashboardData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardData.rejected, (state) => {
        state.loading = false;
      })
      .addCase(getDashboardData.fulfilled, (state, action) => {
        if (action.payload.res?.data) {
          state.data = action.payload.res?.data;
        }
        state.loading = false;
      });
  },
});

export const DASHBOARD_ACTIONS = dashboardSlice.actions;

export default dashboardSlice.reducer;
