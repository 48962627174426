import React, { memo, CSSProperties } from "react";

interface IIconCalendarPrevious {
  style?: CSSProperties;
}

const IconCalendarPrevious = ({ style }: IIconCalendarPrevious) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <rect
        width="34"
        height="34"
        rx="17"
        transform="matrix(-1 0 0 1 34 0)"
        fill="#EAF0FC"
      />
      <path
        d="M13 17C13 17.1674 13.0305 17.3294 13.0916 17.4859C13.1527 17.6433 13.2341 17.7743 13.3359 17.8789L18.9542 23.6547C19.1781 23.8849 19.4631 24 19.8092 24C20.1552 24 20.4402 23.8849 20.6641 23.6547C20.888 23.4245 21 23.1315 21 22.7758C21 22.42 20.888 22.1271 20.6641 21.8969L15.9008 17L20.6641 12.1031C20.888 11.8729 21 11.58 21 11.2242C21 10.8685 20.888 10.5755 20.6641 10.3453C20.4402 10.1151 20.1552 10 19.8092 10C19.4631 10 19.1781 10.1151 18.9542 10.3453L13.3359 16.1211C13.2137 16.2466 13.1274 16.3827 13.0769 16.5291C13.0256 16.6756 13 16.8326 13 17Z"
        fill="#6C3399"
      />
    </svg>
  );
};

export default memo(IconCalendarPrevious);
