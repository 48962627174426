import { Stack } from "@mui/material";
import React, { memo } from "react";
import styles from "./index.module.scss";
import useAppSelector from "hooks/useAppSelector";

const TeamSize = () => {
  const teamSize = useAppSelector((state) => state.dashboard.data?.teamSize);
  return (
    <Stack className="team-size">
      <span className="session-label">Team Size</span>
      <Stack direction="row" marginTop="26px">
        <Stack
          direction="row"
          alignItems="center"
          marginRight="35px"
          className={styles["team-item"]}
        >
          <span className={styles.count}>{teamSize?.active || 0}</span>
          <span className={styles.title}>Active</span>
        </Stack>
        <div className="bg-color-eb h-auto w-px" />
        <Stack
          direction="row"
          alignItems="center"
          marginLeft="35px"
          className={styles["team-item"]}
        >
          <span className={styles.count}>{teamSize?.openRoles || 0}</span>
          <span className={styles.title}>Open roles</span>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default memo(TeamSize);
