import useAppDispatch from "hooks/useAppDispatch";
import useAppSelector from "hooks/useAppSelector";
import React, { memo, useCallback, useEffect, useState } from "react";
import styles from "./index.module.scss";
import { getManages } from "reducers/ManageReducers";
import Spinner from "components/Spinner";
import ManageTable from "components/ManageTable";
import ModalCandidate from "components/ModalCandidate";
import { ICandidate } from "models/Candidate";
import { ISortData, UserPermission } from "models/User";
import { useLocation, useNavigate } from "react-router-dom";
import useUser from "hooks/useUser";
import api from "api";

const Manage = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.manage.loading);
  const candidates = useAppSelector((state) => state.manage.candidates);
  const totalPage = useAppSelector((state) => state.manage.totalPage);
  const [sortData, setSortData] = useState<ISortData | null>(null);
  const [selectedCandidate, setSelectedCandidate] = useState<ICandidate | null>(
    null
  );
  const [page, setPage] = useState<number | null>(null);
  const user = useUser();
  const navigate = useNavigate();
  const handleCloseModalCandidate = useCallback(
    () => setSelectedCandidate(null),
    []
  );
  const onCandidateClick = useCallback((candidate: ICandidate) => {
    setSelectedCandidate(candidate);
  }, []);
  const onSortClick = useCallback((title: string) => {
    setSortData((current) => {
      return {
        title,
        type:
          current?.title !== title
            ? "ASC"
            : current.type === "ASC"
            ? "DESC"
            : "ASC",
      };
    });
  }, []);
  useEffect(() => {
    if (!user.permissions.includes(UserPermission.ManageTeam)) {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.permissions]);
  useEffect(() => {
    if (location.hash) {
      const candidateId = location.hash.replace("#", "");
      if (!candidates?.find((el) => el.id === candidateId)) {
        api.candidate
          .findManage({ candidateId })
          .then((res) => {
            setPage(res?.foundPage || 1);
          })
          .catch((err) => {
            setPage(1);
          });
      }
    } else {
      setPage(1);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.hash]);
  useEffect(() => {
    if (page) {
      dispatch(
        getManages({
          sortName: sortData?.title,
          sortOrder: sortData?.type,
          page,
        })
      );
    }
  }, [dispatch, page, sortData?.title, sortData?.type]);
  const onPageChange = useCallback((e: any, page: number) => {
    setPage(page);
  }, []);
  return (
    <div className={`${styles.container} container`}>
      <h1 className="page-title">Your Team</h1>
      {loading && (
        <Spinner style={{ backgroundColor: "white", marginTop: 180 }} />
      )}
      <ManageTable
        candidates={candidates}
        onCandidateClick={onCandidateClick}
        onSortClick={onSortClick}
        sortData={sortData}
        onPageChange={onPageChange}
        totalPage={totalPage}
      />
      <ModalCandidate
        open={!!selectedCandidate}
        onClose={handleCloseModalCandidate}
        candidate={selectedCandidate}
      />
    </div>
  );
};

export default memo(Manage);
