import React, { CSSProperties, memo } from "react";

interface IIconNotification {
  style?: CSSProperties;
}

const IconNotification = ({ style }: IIconNotification) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={style}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21 11C21 10.4477 20.5523 10 20 10C19.4477 10 19 10.4477 19 11V11.75H18.4426C16.2175 11.75 14.3759 13.48 14.237 15.7007L14.016 19.2342C13.9318 20.5814 13.4795 21.8797 12.7084 22.9876C12.0117 23.9886 12.6319 25.3712 13.8429 25.5165L17.25 25.9254V27C17.25 28.5188 18.4812 29.75 20 29.75C21.5188 29.75 22.75 28.5188 22.75 27V25.9254L26.1571 25.5165C27.3681 25.3712 27.9883 23.9886 27.2916 22.9876C26.5205 21.8797 26.0682 20.5814 25.984 19.2342L25.763 15.7007C25.6241 13.48 23.7825 11.75 21.5574 11.75H21V11ZM18.4426 13.25C17.0096 13.25 15.8235 14.3642 15.7341 15.7944L15.5131 19.3278C15.4117 20.949 14.8674 22.5112 13.9396 23.8444C13.8892 23.9168 13.9341 24.0167 14.0216 24.0272L17.7592 24.4757C19.2477 24.6543 20.7523 24.6543 22.2407 24.4757L25.9784 24.0272C26.0659 24.0167 26.1108 23.9168 26.0604 23.8444C25.1326 22.5112 24.5883 20.949 24.4869 19.3278L24.2659 15.7944C24.1764 14.3642 22.9904 13.25 21.5574 13.25H18.4426ZM20 28.25C19.3096 28.25 18.75 27.6904 18.75 27V26.25H21.25V27C21.25 27.6904 20.6904 28.25 20 28.25Z"
        fill="black"
      />
      <circle cx="20" cy="20" r="19.5" stroke="#A9A9A9" />
    </svg>
  );
};

export default memo(IconNotification);
