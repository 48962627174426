import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import RequisitionKeyDates from "./RequisitionKeyDates";
import { RequisitionRequest } from "models/Requisition";
import RequisitionHiringProcess from "./RequisitionHiringProcess";
import RequisitionRateRange from "./RequisitionRateRange";
import styles from "./index.module.scss";
import useAppSelector from "hooks/useAppSelector";
import Spinner from "components/Spinner";
import api from "api";
import useUser from "hooks/useUser";
import { useToast } from "providers/ToastProvider";
import { useNavigate } from "react-router-dom";
import { UserPermission } from "models/User";
import useAppDispatch from "hooks/useAppDispatch";
import { getUsers } from "reducers/MetadataReducers";

const Requisition = () => {
  const dispatch = useAppDispatch();
  const loading = useAppSelector((state) => state.metadata.loading);
  const toast = useToast();
  const timeoutFetchRate = useRef<any>();
  const user = useUser();
  const [creating, setCreating] = useState(false);
  const [requisitionData, setRequisitionData] = useState<RequisitionRequest>({
    client: "",
    jobTitle: "",
    jobFamily: [],
    jobLevel: [],
    openDate: "",
    english: [],
    hiringManager: "",
    skillTesting: [],
    minRate: 0,
    maxRate: 0,
    adhocRateOffers: true,
    currency: null,
  });
  const disabled = useMemo(
    () =>
      !requisitionData.client ||
      !requisitionData.jobTitle ||
      requisitionData.jobFamily.length === 0 ||
      requisitionData.jobLevel.length === 0 ||
      !requisitionData.openDate ||
      requisitionData.english.length === 0 ||
      !requisitionData.hiringManager ||
      !requisitionData.minRate ||
      !requisitionData.maxRate ||
      requisitionData.skillTesting.length === 0,
    [
      requisitionData.client,
      requisitionData.english.length,
      requisitionData.hiringManager,
      requisitionData.jobFamily.length,
      requisitionData.jobLevel.length,
      requisitionData.jobTitle,
      requisitionData.maxRate,
      requisitionData.minRate,
      requisitionData.openDate,
      requisitionData.skillTesting.length,
    ]
  );
  const navigate = useNavigate();
  useEffect(() => {
    if (!user.permissions.includes(UserPermission.Requisition)) {
      navigate("/", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.permissions]);
  useEffect(() => {
    if (user.client.id) {
      setRequisitionData((current) => ({ ...current, client: user.client.id }));
    }
  }, [user.client.id]);
  const onCreate = useCallback(async () => {
    if (creating || disabled) return;
    setCreating(true);
    const res = await api.requisition.create(requisitionData);
    if (res.statusCode === 201) {
      toast.success({ message: "Create successfully!" });
    }
    setRequisitionData((current) => ({
      client: current.client,
      jobTitle: "",
      jobFamily: [],
      jobLevel: [],
      openDate: "",
      english: [],
      hiringManager: "",
      skillTesting: [],
      minRate: 0,
      maxRate: 0,
      adhocRateOffers: false,
      currency: null,
    }));
    setCreating(false);
  }, [creating, disabled, requisitionData, toast]);
  useEffect(() => {
    if (
      requisitionData.client &&
      requisitionData.jobFamily.length > 0 &&
      requisitionData.jobLevel.length > 0
    ) {
      if (timeoutFetchRate.current) {
        clearTimeout(timeoutFetchRate.current);
      }
      timeoutFetchRate.current = setTimeout(() => {
        api.requisition
          .getRate({
            clientId: requisitionData.client,
            jobFamilies: requisitionData.jobFamily,
            jobLevels: requisitionData.jobLevel,
          })
          .then((res) => {
            setRequisitionData((current) => ({
              ...current,
              minRate: res.data?.minRate || 0,
              maxRate: res.data?.maxRate || 0,
              currency: res.data?.currency,
            }));
          });
      }, 400);
    }
    return () => {
      if (timeoutFetchRate.current) {
        clearTimeout(timeoutFetchRate.current);
      }
    };
  }, [
    requisitionData.client,
    requisitionData.jobFamily,
    requisitionData.jobLevel,
  ]);
  useEffect(() => {
    dispatch(getUsers({}));
  }, [dispatch]);
  return (
    <div className={`${styles.container} container relative`}>
      <h1 className="page-title">Requisition</h1>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <RequisitionKeyDates
            requisitionData={requisitionData}
            setRequisitionData={setRequisitionData}
          />
          <RequisitionHiringProcess
            requisitionData={requisitionData}
            setRequisitionData={setRequisitionData}
          />
          <RequisitionRateRange
            requisitionData={requisitionData}
            setRequisitionData={setRequisitionData}
          />
          <div
            className={`${styles.cta} ${disabled ? styles.disabled : ""} ${
              creating ? styles.loading : ""
            }`}
            onClick={onCreate}
          >
            {creating ? (
              <Spinner size={20} color="white" />
            ) : (
              <span>Request Role</span>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default memo(Requisition);
